import React from "react";
import "./style.scss";
import teamImg1 from "../../../assets/images/Podpis.jpg";
import teamImg2 from "../../../assets/images/Podpis2.jpg";
import teamImg3 from "../../../assets/images/Podpis2.png";
import teamImg4 from "../../../assets/images/podpis.png";
import teamImg5 from "../../../assets/images/Podpis_small.png";

const TeamBox = ({ teamImg, tName, staff }) => {
  return (
    <div className="teamBox">
      <div className="img" style={{ backgroundImage: `url(${teamImg})` }}></div>
      <div
        className="img"
        style={{ backgroundImage: `url(${teamImg1})` }}
      ></div>
      <div
        className="img"
        style={{ backgroundImage: `url(${teamImg2})` }}
      ></div>
      <div
        className="img"
        style={{ backgroundImage: `url(${teamImg3})` }}
      ></div>
      <div
        className="img"
        style={{ backgroundImage: `url(${teamImg4})` }}
      ></div>
      <div
        className="img"
        style={{ backgroundImage: `url(${teamImg5})` }}
      ></div>
      <h3 className="staffName">{tName}</h3>
      <p className="staff">{staff}</p>
    </div>
  );
};

export default TeamBox;

import React from "react";
import back from "../../../assets/images/logoC.png";
import { TbCertificate } from "react-icons/tb";
import { FaUsersCog } from "react-icons/fa";
import { RiShieldCheckFill } from "react-icons/ri";
import { FaRegLifeRing } from "react-icons/fa";
import "./style.scss";

const AboutDesc = ({ selectedOption }) => {
  return (
    <div className="aboutWorkContainer">
      <div className="aboutBox">
        <section className="aboutDesc">
          <div className="aboutChooseContainer">
            <h2
              className="aboutTitleChoose"
              style={{ backgroundImage: `url(${back})` }}
            >
              {selectedOption === "en" && "Why Choose Us "}
              {selectedOption === "ru" && "Почему выбрать нас"}
              {selectedOption === "am" && "Ինչու ընտրել մեզ"}
            </h2>
            {selectedOption === "en" && (
              <p className="chooseTitle">
                Choosing our company for the implementation of new solutions
                will allow your company to take advantage of the latest
                technological innovations that can significantly increase work
                efficiency and improve business management: Our team of
                specialists is ready to offer the most optimal solutions for
                your company and implement them in the shortest possible time:
              </p>
            )}
            {selectedOption === "ru" && (
              <p className="chooseTitle">
                Выбор нашей компании для внедрения новых ИТ-решений позволит
                вашему предприятию получить доступ к последним технологическим
                инновациям, которые могут значительно повысить эффективность
                работы и улучшить управление бизнесом. Наша команда
                профессионалов готова предложить наиболее оптимальные решения
                для вашего предприятия и провести их внедрение в кратчайшие
                сроки.
              </p>
            )}
            {selectedOption === "am" && (
              <p className="chooseTitle">
                ՏՏ նոր լուծումների ներդրման համար մեր ընկերության ընտրությունը
                թույլ կտա ձեր ընկերությանը օգտվել վերջին տեխնոլոգիական
                նորամուծություններից, որոնք կարող են զգալիորեն բարձրացնել
                աշխատանքի արդյունավետությունը և բարելավել բիզնեսի կառավարումը:
                Մեր մասնագետների թիմը պատրաստ է առաջարկել ձեր ձեռնարկության
                համար ամենաօպտիմալ լուծումները և իրականացնել դրանք հնարավորինս
                սեղմ ժամկետներում:
              </p>
            )}
            <div className="chooseSpan"></div>

            {selectedOption === "en" && (
              <div className="workBoxChooseList">
                <div className="workBoxChoose" data-aos="flip-up">
                  <TbCertificate className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Expertise</h2>
                    <p className="chooseBoxDesc">
                      With years of experience in developing business automation
                      systems, Codewave has a deep understanding of the needs
                      and challenges faced by businesses in various industries.
                      Our team of experts is constantly keeping up with the
                      latest trends and technologies to ensure that we can
                      provide the best solutions to our clients.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <FaUsersCog className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Customization</h2>
                    <p className="chooseBoxDesc">
                      At Codewave, we understand that every business is unique,
                      and there is no one-size-fits-all solution when it comes
                      to automation. That's why we work closely with our clients
                      to understand their specific needs and develop customized
                      solutions that meet their requirements.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <RiShieldCheckFill className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Quality</h2>
                    <p className="chooseBoxDesc">
                      We are committed to delivering high-quality solutions that
                      not only meet but exceed our clients' expectations. From
                      the initial planning stages to final delivery, we
                      prioritize quality at every step of the development
                      process.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <FaRegLifeRing className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Support</h2>
                    <p className="chooseBoxDesc">
                      At Codewave, we believe that our relationship with our
                      clients doesn't end after the delivery of the solution. We
                      offer ongoing support and maintenance to ensure that our
                      clients' systems are running smoothly and efficiently, and
                      we are always available to address any issues or concerns
                      that may arise.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {selectedOption === "ru" && (
              <div className="workBoxChooseList">
                <div className="workBoxChoose" data-aos="flip-up">
                                  <TbCertificate className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Экспертиза</h2>
                    <p className="chooseBoxDesc">
                      Обладая многолетним опытом разработки систем автоматизации
                      бизнеса, Codewave глубоко понимает потребности и проблемы,
                      с которыми сталкиваются предприятия в различных отраслях.
                      Наша команда экспертов постоянно следит за последними
                      тенденциями и технологиями, чтобы гарантировать, что мы
                      можем предоставить лучшие решения для наших клиентов.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                                  <FaUsersCog className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Индивидуализация</h2>
                    <p className="chooseBoxDesc">
                      В Codewave мы понимаем, что каждый бизнес уникален, и не
                      существует единого решения, подходящего для всех, когда
                      речь идет об автоматизации. Вот почему мы тесно
                      сотрудничаем с нашими клиентами, чтобы понять их
                      конкретные потребности и разработать индивидуальные
                      решения, отвечающие их требованиям.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                                  <RiShieldCheckFill className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Качество</h2>
                    <p className="chooseBoxDesc">
                      Мы стремимся предоставлять высококачественные решения,
                      которые не только соответствуют ожиданиям наших клиентов,
                      но и превосходят их. От начальных этапов планирования до
                      получения конечного продукта мы уделяем первостепенное
                      внимание качеству на каждом этапе процесса разработки.
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                                  <FaRegLifeRing className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Поддержка</h2>
                    <p className="chooseBoxDesc">
                      Мы в Codewave считаем, что наши отношения с клиентами не
                      заканчиваются после сдачи проекта. Мы предлагаем
                      постоянную поддержку и техническое обслуживание, чтобы
                      гарантировать бесперебойную и эффективную работу систем
                      наших клиентов, и мы всегда готовы решить любые проблемы
                      или проблемы, которые могут возникнуть.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {selectedOption === "am" && (
              <div className="workBoxChooseList">
                <div className="workBoxChoose" data-aos="flip-up">
                  <TbCertificate className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Փորձառություն</h2>
                    <p className="chooseBoxDesc">
                      Բիզնեսի ավտոմատացման համակարգերի մշակման ոլորտում տարիների
                      փորձ ունենալով, մենք խորապես պատկերացնում ենք տարբեր
                      ոլորտներում ձեռնարկությունների առջև ծառացած խնդիրներն ու
                      մարտահրավերները: Մեր փորձագետների թիմը մշտապես հետևում է
                      վերջին թրենդերին և տեխնոլոգիաներին՝ մեր հաճախորդներին
                      լավագույն լուծումները տրամադրելու համար:
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <FaUsersCog className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Անհատական մոտեցում</h2>
                    <p className="chooseBoxDesc">
                      Codewave-ում մենք հասկանում ենք, որ յուրաքանչյուր բիզնես
                      եզակի է, և ավտոմատացման հարցում բոլորին հարմար լուծում
                      չկա: Այդ իսկ պատճառով մենք սերտորեն համագործակցում ենք մեր
                      հաճախորդների հետ՝ հասկանալու նրանց հատուկ կարիքները և
                      մշակելու հարմարեցված լուծումներ, որոնք համապատասխանում են
                      վերջիններիս պահանջներին:
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <RiShieldCheckFill className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Որակ</h2>
                    <p className="chooseBoxDesc">
                      Մենք հանձնառու ենք տրամադրել բարձրորակ լուծումներ, որոնք
                      ոչ միայն կբավարարեն, այլև կգերազանցեն մեր հաճախորդների
                      սպասելիքները: Նախնական պլանավորման փուլերից մինչև
                      վերջնական պրոդուկտի ստացում մենք առաջնահերթ ենք համարում
                      որակը՝ զարգացման գործընթացի յուրաքանչյուր քայլում:
                    </p>
                  </div>
                </div>
                <div className="workBoxChoose" data-aos="flip-up">
                  <FaRegLifeRing className="iconChoose" />
                  <div className="chooseInfoBox">
                    <h2 className="chooseBoxTitle">Աջակցություն</h2>
                    <p className="chooseBoxDesc">
                      Codewave-ում մենք հավատում ենք, որ հաճախորդների հետ մեր
                      հարաբերությունները չեն ավարտվում պրոեկտի հանձնումից հետո:
                      Մենք առաջարկում ենք շարունակական աջակցություն և
                      սպասարկում՝ ապահովելու համար, որ մեր հաճախորդների
                      համակարգերը աշխատեն սահուն և արդյունավետ, և մենք միշտ
                      պատրաստ ենք լուծելու ցանկացած խնդիր կամ մտահոգություն, որը
                      կարող է առաջանալ:
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutDesc;

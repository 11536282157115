import React, { useEffect, useRef } from 'react';
import "./style.scss"

const ContactForm = () => {

  
  const formContainer = useRef(null);
  const scriptAdded = useRef(false);
  
  useEffect(() => {
    if (formContainer.current && !scriptAdded.current) {
      const script = document.createElement('script');
      script.setAttribute('data-b24-form', 'inline/21/0j6z2n');
      script.setAttribute('data-skip-moving', 'true');
      script.src = 'https://cdn-ru.bitrix24.ru/b25706992/crm/form/loader_21.js';
      formContainer.current.appendChild(script);
      scriptAdded.current = true;
    }
  }, []);
  
  return <div className="contact-form" ref={formContainer} />;
};

export default ContactForm;




import React, { useEffect, useRef, useState } from "react";
import background from "../../../../assets/images/bitrix24/b24TitleImg.png";
import category1 from "../../../../assets/images/bitrix24/cat1.png";
import category2 from "../../../../assets/images/bitrix24/cat2.png";
import category3 from "../../../../assets/images/bitrix24/cat3.png";
import category4 from "../../../../assets/images/bitrix24/cat4.png";
import category5 from "../../../../assets/images/bitrix24/cat5.png";
import "./style.scss";
import Box from "../free";
import BoxPrices from "../bases";
import BoxStandart from "../standart";
import BoxProfessional from "../professional";
import BoxEnterPrice from "../enterprise";
import Bitrix24BoxVersion from "../boxVersion";
import IntegrationBox from "../integrationBox";
import { Helmet } from "react-helmet";

const Bitrix24Container = ({ selectedOption, handleClose }) => {
  const refCollaboration = useRef(null);
  const refCrm = useRef(null);
  const refTasks = useRef(null);
  const refWebsite = useRef(null);
  const refAutomation = useRef(null);

  const handleScrollCollaboration = () => {
    refCollaboration.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollCrm = () => {
    refCrm.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollTasks = () => {
    refTasks.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollWebsite = () => {
    refWebsite.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollAutomation = () => {
    refAutomation.current?.scrollIntoView({ behavior: "smooth" });
  };
  const refModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };

  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showOblako, setShowOblako] = useState(true);
  const [showKarobka, setShowKarobka] = useState(false);
  const [showIntegration, setShowIntegration] = useState(false);
  const [activeComponent, setActiveComponent] = useState("Cloudy");
  const handleClick = (component) => {
    if (component === "Cloudy") {
      setShowOblako(true);
      setShowKarobka(false);
      setShowIntegration(false);
    } else if (component === "Boxy") {
      setShowKarobka(true);
      setShowOblako(false);
      setShowIntegration(false);
    } else if (component === "Integration") {
      setShowIntegration(true);
      setShowKarobka(false);
      setShowOblako(false);
    }
    setActiveComponent(component);
  };
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Բիտրիքս24 ☎️ 091 09 09 07');
  }, []);
  return (
    <div ref={refModal}>
  
      {selectedOption === "ru" && (
        <div className="B24Cont">
          <Helmet>
            <title>Битрикс24 ☎️ 091 09 09 07</title>
          </Helmet>
          <>
            <h2 className="titleCont24">Что такое Битрикс24?</h2>
            <p className="desc24">
              Битрикс24 - это облачная платформа для управления бизнесом, которая
              включает в себя множество инструментов для управления проектами,
              задачами, контактами, продажами, маркетингом, коммуникацией и
              другими бизнес-процессами. Битрикс24 разработан для улучшения
              эффективности работы компаний любого размера, от малого бизнеса до
              крупных корпораций. Он предоставляет набор инструментов для
              автоматизации бизнес-процессов, повышения производительности и
              снижения затрат на операции. Среди основных возможностей Битрикс24
              можно выделить:
            </p>

            <div className="containerBiAll">
              <div
                className={`component ${
                  activeComponent === "Cloudy" ? "activeC" : ""
                }`}
                onClick={() => handleClick("Cloudy")}
              >
                Облачная
              </div>
              <div
                className={`component ${
                  activeComponent === "Boxy" ? "activeC" : ""
                }`}
                onClick={() => handleClick("Boxy")}
              >
                Коробочная
              </div>
              <div
                className={`component ${
                  activeComponent === "Integration" ? "activeC" : ""
                }`}
                onClick={() => handleClick("Integration")}
              >
                Интеграция
              </div>
            </div>
          </>
          {showOblako && (
            <div className="oblako">
              <div className="bCategoryesBtn">
                <button className="catBtn" onClick={handleScrollCollaboration}>
                  Совместная работа
                </button>
                <button className="catBtn" onClick={handleScrollCrm}>
                  CRM
                </button>
                <button className="catBtn" onClick={handleScrollTasks}>
                  Задачи и проекты
                </button>
                <button className="catBtn" onClick={handleScrollWebsite}>
                  Конструктор сайтов
                </button>
                <button className="catBtn" onClick={handleScrollAutomation}>
                  Автоматизация
                </button>
              </div>
              <div
                style={{ backgroundImage: `url(${background})` }}
                className="titleImg"
              ></div>
              <div className="categoryBox" ref={refCollaboration}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Совместная работа</h3>
                  <p className="catDesc">
                    Коммуникации, документы и рабочие процессы — всё для
                    организации полноценного онлайн-офиса
                  </p>
                  <h4 className="catTitleh4">Видеозвонки HD</h4>
                  <p className="catDesch4">
                    Коллеги, клиенты и подрядчики на связи — с высоким качеством
                    видео и аудио, стабильным соединением и поддержкой групповых
                    звонков до 48 человек. Звонок легко начать прямо из задачи,
                    события в календаре или поста в Новостях компании.
                  </p>
                  <h4 className="catTitleh4">Мессенджер</h4>
                  <p className="catDesch4">
                    Неограниченное количество рабочих групповых и индивидуальных
                    чатов у вас под рукой — а также Открытые линии, диалоги с
                    внешними пользователями, обмен файлами и уведомления.
                  </p>
                  <h4 className="catTitleh4">Рабочее время и отчеты</h4>
                  <p className="catDesch4">
                    Начинайте, прерывайте, заканчивайте рабочий день в один
                    клик. Ведите учет рабочего времени и составляйте отчеты на
                    лету.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category1})` }}
                ></div>
              </div>

              <div className="categoryBox" ref={refCrm}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category2})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">CRM</h3>
                  <p className="catDesc">
                    Телефония, почта, социальные сети, учет рекламы, склады,
                    оплата и доставка — в ней есть всё
                  </p>
                  <h4 className="catTitleh4">
                    Клиенты, сделки, лиды, коммерческие предложения
                  </h4>
                  <p className="catDesch4">
                    Все необходимые сущности CRM. Пополняйте и детализируйте
                    клиентскую базу, ведите продажи, сохраняйте переписку и
                    звонки с клиентами в CRM.
                  </p>
                  <h4 className="catTitleh4">Маркетинг</h4>
                  <p className="catDesch4">
                    Отправляйте email и sms-рассылки, создавайте рекламные
                    кампании, отслеживайте эффективность рекламных каналов в
                    Сквозной аналитике. Для вас – всё необходимое для
                    привлечения клиентов.
                  </p>
                  <h4 className="catTitleh4">Склады, оплата и доставка</h4>
                  <p className="catDesch4">
                    Всё необходимое для продаж уже есть в CRM. Ведите складской
                    учёт, реализуйте товары и контролируйте остатки. Выставляйте
                    счёт на оплату и оформляйте доставку прямо из карточки
                    сделки – клиент сможет оплатить прямо в диалоге с вашей
                    компанией.
                  </p>
                </div>
              </div>

              <div className="categoryBox" ref={refTasks}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Задачи и проекты</h3>
                  <p className="catDesc">
                    Наведите порядок в поручениях, работайте в удобном для вас
                    режиме
                  </p>
                  <h4 className="catTitleh4">
                    Эффективные методики работы над проектами
                  </h4>
                  <p className="catDesch4">
                    На ваш выбор — Канбан, Диаграмма Ганта или классические
                    списки. Ведите календарь задач, следите за дедлайнами или
                    создайте свой план выполнения. В управлении проектами вам
                    поможет Скрам.
                  </p>
                  <h4 className="catTitleh4">Доступ к задачам</h4>
                  <p className="catDesch4">
                    Распределите роли в задачах — назначьте постановщиков,
                    исполнителей, соисполнителей и наблюдателей. Настройте права
                    доступа к задачам и управлению ими: постановку, изменение
                    крайнего срока, делегирование и т.д.
                  </p>
                  <h4 className="catTitleh4">
                    Автоматизация в задачах и проектах
                  </h4>
                  <p className="catDesch4">
                    Ставьте типовые задачи в один клик из шаблонов. Используйте
                    шаблоны проектов, со всеми вложенными задачами и
                    ответственными, чтобы мгновенно разворачивать новые проекты.
                    Роботы для отправки писем, смены статуса и ответственного
                    помогут ускорить работу и успеть больше.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category3})` }}
                ></div>
              </div>

              <div className="categoryBox" ref={refWebsite}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category4})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">Конструктор сайтов</h3>
                  <p className="catDesc">
                    Свой собственный сайт за несколько минут без разработчика и
                    дизайнера, готовый к работе с клиентами
                  </p>
                  <h4 className="catTitleh4">Прямая связь с CRM</h4>
                  <p className="catDesch4">
                    Сайт создается уже внутри CRM. Все посетители учитываются,
                    все заявки мгновенно попадают в работу менеджерам.
                  </p>
                  <h4 className="catTitleh4">SEO-продвижение</h4>
                  <p className="catDesch4">
                    Сайты полностью готовы у продвижению в поисковых системах:
                    они оптимизированы, быстро загружаются, соответствуют
                    требованиям поисковых систем.
                  </p>
                  <h4 className="catTitleh4">Адаптивность для любых экранов</h4>
                  <p className="catDesch4">
                    Сайты готовы для работы на десктопе, планшете и смартфоне —
                    блоки адаптируются сами под различные экраны.
                  </p>
                </div>
              </div>
              <div className="categoryBox" ref={refAutomation}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Автоматизация</h3>
                  <p className="catDesc">
                    Минимум усилий, максимум эффективности — это справедливо и
                    для работы с инструментами автоматизации бизнеса, и для их
                    настройки
                  </p>
                  <h4 className="catTitleh4">Роботы и триггеры</h4>
                  <p className="catDesch4">
                    Постановить задачу, запланировать звонок или встречу,
                    сменить ответственного или стадию сделки, отправить письмо
                    или SMS, напомнить ответственному — это лишь часть того, что
                    вы можете настроить с помощью роботов и триггеров в CRM и
                    Задачах.
                  </p>
                  <h4 className="catTitleh4">Бизнес-процессы</h4>
                  <p className="catDesch4">
                    Инструмент упрощает процедуру согласования документов внутри
                    компании. Все заявки на отпуск и командировку, счета и
                    договоры автоматически проходят по цепочке необходимых
                    согласований в считанные минуты. А настроить её можно без
                    кода в удобном редакторе.
                  </p>
                  <h4 className="catTitleh4">RPA (Роботизация бизнеса)</h4>
                  <p className="catDesch4">
                    Встроенный редактор на основе канбана позволит создать
                    цепочку согласований буквально за несколько минут без кода.
                    Документ пройдет по всем ответственным, которые получат
                    уведомления и не пропустят очередь согласования.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category5})` }}
                ></div>
              </div>
              <div className="container">
                <span>
                  <p className="titlebi"> Актуальные тарифы Битрикс24 </p>
                </span>
                <div
                  style={{
                    display: "flex",
                    padding: "0 0 0 0",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box />
                  <BoxPrices />
                  <BoxStandart />
                  <BoxProfessional />
                  <BoxEnterPrice />
                </div>
              </div>
            </div>
          )}
          {showKarobka && (
            <div className="karobka">
              <Bitrix24BoxVersion selectedOption={selectedOption} />
            </div>
          )}
          {showIntegration && (
            <div className="karobka">
              <IntegrationBox selectedOption={selectedOption} />
            </div>
          )}
        </div>
      )}

      {selectedOption === "en" && (
        <div className="B24Cont">
          <Helmet>
            <title>Bitrix24 ☎️ 091 09 09 07</title>
          </Helmet>
          <h2 className="titleCont24">What is Bitrix24</h2>
          <p className="desc24">
            Bitrix24 is a cloud-based business management platform that includes
            many project management tools, tasks, contacts, sales, marketing,
            communication and other business processes. Bitrix24 is designed to
            improve efficiency of companies of any size, from small businesses
            to large corporations. It provides a set of tools for automating
            business processes, improving productivity and reducing transaction
            costs. Among the main features of Bitrix24 can be distinguished:
          </p>
          <div className="containerBiAll">
            <div
              className={`component ${
                activeComponent === "Cloudy" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Cloudy")}
            >
              Cloud
            </div>
            <div
              className={`component ${
                activeComponent === "Boxy" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Boxy")}
            >
              On-premise{" "}
            </div>
            <div
              className={`component ${
                activeComponent === "Integration" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Integration")}
            >
              Integration
            </div>
          </div>
          {showOblako && (
            <div className="oblako">
              <div className="bCategoryesBtn">
                <button className="catBtn" onClick={handleScrollCollaboration}>
                  Collaboration
                </button>
                <button className="catBtn" onClick={handleScrollCrm}>
                  CRM
                </button>
                <button className="catBtn" onClick={handleScrollTasks}>
                  Tasks and Projects
                </button>
                <button className="catBtn" onClick={handleScrollWebsite}>
                  Website Builder
                </button>
                <button className="catBtn" onClick={handleScrollAutomation}>
                  Automation
                </button>
              </div>
              <div
                style={{ backgroundImage: `url(${background})` }}
                className="titleImg"
              ></div>
              <div className="categoryBox" ref={refCollaboration}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Collaboration</h3>
                  <p className="catDesc">
                    Communications, documents and workflows - everything for
                    organizing a full-fledged online office
                  </p>
                  <h4 className="catTitleh4">HD video calls</h4>
                  <p className="catDesch4">
                    Colleagues, customers and contractors are connected with
                    high quality video and audio, stable connection and support
                    for group calls of up to 48 people. It's easy to start a
                    call right from a task, a calendar event, or a post in
                    Company News.
                  </p>
                  <h4 className="catTitleh4">Messenger </h4>
                  <p className="catDesch4">
                    Unlimited work group and individual chats at your fingertips
                    - as well as Open lines, conversations with external users,
                    file sharing and notifications.
                  </p>
                  <h4 className="catTitleh4">Working hours and reports</h4>
                  <p className="catDesch4">
                    Start, stop, and end your working day in one click. Keep
                    track of working hours and quickly create reports.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category1})` }}
                ></div>
              </div>

              <div className="categoryBox" ref={refCrm}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category2})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">CRM</h3>
                  <p className="catDesc">
                    Telephony, mail, social networks, advertising accounting,
                    warehouses, payment and delivery - it has everything{" "}
                  </p>
                  <h4 className="catTitleh4">
                    Clients, deals, leads, commercial offers
                  </h4>
                  <p className="catDesch4">
                    All necessary CRM entities. Replenish and refine your
                    customer base, conduct sales, save correspondence and calls
                    with customers in CRM.
                  </p>
                  <h4 className="catTitleh4">Marketing</h4>
                  <p className="catDesch4">
                    Send email and sms mailings, create advertising campaigns,
                    track the effectiveness of advertising channels in
                    End-to-End Analytics. For you - everything you need to
                    attract customers.
                  </p>
                  <h4 className="catTitleh4">
                    Inventory, payment and delivery
                  </h4>
                  <p className="catDesch4">
                    Everything you need for sales is already in CRM. Keep
                    inventory records, sell goods and control stocks. Issue an
                    invoice for payment and arrange delivery directly from the
                    transaction card - the client will be able to pay directly
                    in the dialogue with your company.
                  </p>
                </div>
              </div>
              <div className="categoryBox" ref={refTasks}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Tasks and Projects</h3>
                  <p className="catDesc">
                    Put things in order, work in a mode convenient for you
                  </p>
                  <h4 className="catTitleh4">
                    Effective project work practices
                  </h4>
                  <p className="catDesch4">
                    Choose from Kanban, Gantt Chart or classic lists. Keep a
                    task calendar, keep track of deadlines, or create your own
                    execution plan. Scrum helps you manage your projects.
                  </p>
                  <h4 className="catTitleh4">Access to tasks</h4>
                  <p className="catDesch4">
                    Distribute roles in tasks - appoint directors, executors,
                    co-executors and observers. Set up access rights to tasks
                    and manage them: setting, changing the deadline, delegation,
                    etc.
                  </p>
                  <h4 className="catTitleh4">
                    Automation in tasks and projects
                  </h4>
                  <p className="catDesch4">
                    Set standard tasks in one click from templates. Use project
                    templates, with all nested tasks and owners, to instantly
                    deploy new projects. Robots for sending letters, changing
                    the status and responsible will help speed up the work and
                    do more.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category3})` }}
                ></div>
              </div>
              <div className="categoryBox" ref={refWebsite}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category4})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">Website Builder</h3>
                  <p className="catDesc">
                    Your own website in a few minutes without a developer and
                    designer, ready to work with clients
                  </p>
                  <h4 className="catTitleh4">Direct connection with CRM</h4>
                  <p className="catDesch4">
                    The site is already created inside the CRM. All visitors are
                    taken into account, all applications instantly get into the
                    work of managers.
                  </p>
                  <h4 className="catTitleh4">SEO-promotion</h4>
                  <p className="catDesch4">
                    Sites are fully prepared for promotion in search engines:
                    they are optimized, load quickly, and meet the requirements
                    of search engines.
                  </p>
                  <h4 className="catTitleh4">Adaptability for any screens</h4>
                  <p className="catDesch4">
                    Sites are ready to work on a desktop, tablet and smartphone
                    - the blocks adapt themselves to different screens.
                  </p>
                </div>
              </div>
              <div className="categoryBox" ref={refAutomation}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Automation</h3>
                  <p className="catDesc">
                    Minimum effort, maximum efficiency - this is true both for
                    working with business automation tools and for setting them
                    up.
                  </p>
                  <h4 className="catTitleh4">Robots and triggers</h4>
                  <p className="catDesch4">
                    Set a task, schedule a call or a meeting, change the owner
                    or the stage of the transaction, send an email or SMS,
                    remind the person in charge - this is just a part of what
                    you can set up using robots and triggers in CRM and Tasks.
                  </p>
                  <h4 className="catTitleh4">Business processes</h4>
                  <p className="catDesch4">
                    The tool simplifies the process of coordinating documents
                    within the company. All vacation and business trip
                    applications, invoices and contracts automatically go
                    through the chain of necessary approvals in a matter of
                    minutes. And you can configure it without code in a
                    convenient editor.
                  </p>
                  <h4 className="catTitleh4">RPA (Business Robotization)</h4>
                  <p className="catDesch4">
                    The built-in kanban-based editor allows you to create an
                    approval chain in just a few minutes without code. The
                    document will go through all those responsible, who will
                    receive notifications and will not miss the approval queue.
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category5})` }}
                ></div>
              </div>
              <div className="container">
                <span>
                  <p className="titlebi">Current Bitrix24 tariffs</p>
                </span>
                <div
                  style={{
                    display: "flex",
                    padding: "0 0 0 0",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box />
                  <BoxPrices />
                  <BoxStandart />
                  <BoxProfessional />
                  <BoxEnterPrice />
                </div>
              </div>
            </div>
          )}
          {showKarobka && (
            <div className="karobka">
              <Bitrix24BoxVersion selectedOption={selectedOption} />
            </div>
          )}
          {showIntegration && (
            <div className="karobka">
              <IntegrationBox selectedOption={selectedOption} />
            </div>
          )}
        </div>
      )}
      {selectedOption === "am" && (
        <div className="B24Cont">
          <Helmet>
            <title>Բիտրիքս24 ☎️ 091 09 09 07</title>
          </Helmet>
          <h2 className="titleCont24">Ինչ է Bitrix24-ը</h2>
          <p className="desc24">
            Bitrix24-ը բիզնեսի կառավարման հարթակ է, որը ներառում է բազմաթիվ
            գործիքներ նախագծերի, առաջադրանքների, կոնտակտների, վաճառքի,
            շուկայավարման, հաղորդակցության և այլ բիզնես գործընթացների կառավարման
            համար: Bitrix24-ը նախատեսված է ցանկացած չափի ընկերությունների
            աշխատանքը բարելավելու համար՝ փոքր բիզնեսից մինչև խոշոր
            կորպորացիաներ: Այն ապահովում է մի շարք գործիքներ բիզնես
            գործընթացների ավտոմատացման, արտադրողականության բարձրացման և ծախսերի
            նվազեցման համար: Bitrix24-ի հիմնական բաժիններից են.
          </p>
          <div className="containerBiAll">
            <div
              className={`component ${
                activeComponent === "Cloudy" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Cloudy")}
            >
              Ամպային
            </div>
            <div
              className={`component ${
                activeComponent === "Boxy" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Boxy")}
            >
              Տուփային
            </div>
            <div
              className={`component ${
                activeComponent === "Integration" ? "activeC" : ""
              }`}
              onClick={() => handleClick("Integration")}
            >
              Ինտեգրացիա
            </div>
          </div>
          {showOblako && (
            <div className="oblako">
              <div className="bCategoryesBtn">
                <button className="catBtn" onClick={handleScrollCollaboration}>
                  Համագործակցություն
                </button>
                <button className="catBtn" onClick={handleScrollCrm}>
                  CRM
                </button>
                <button className="catBtn" onClick={handleScrollTasks}>
                  Առաջադրանքներ և նախագծեր
                </button>
                <button className="catBtn" onClick={handleScrollWebsite}>
                  Կայքի ստեղծում
                </button>
                <button className="catBtn" onClick={handleScrollAutomation}>
                  Ավտոմատացում
                </button>
              </div>
              <div
                style={{ backgroundImage: `url(${background})` }}
                className="titleImg"
              ></div>
              <div className="categoryBox" ref={refCollaboration}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Համագործակցություն</h3>
                  <p className="catDesc">
                    Հաղորդակցություններ, փաստաթղթեր և աշխատանքային հոսքեր՝ ամեն
                    ինչ լիարժեք առցանց գրասենյակ կազմակերպելու համար
                  </p>
                  <h4 className="catTitleh4">HD տեսազանգեր</h4>
                  <p className="catDesch4">
                    Գործընկերները, հաճախորդները և մատակարարները միացված են
                    բարձրորակ վիդեո և աուդիո, կայուն կապով և մինչև 48 հոգու
                    խմբային զանգերի աջակցությամբ: Հեշտ է զանգը կատարել հենց
                    առաջադրանքից, օրացուցային իրադարձությունից կամ Ընկերության
                    նորություններում հրապարակված գրառումից:
                  </p>
                  <h4 className="catTitleh4">Մեսենջեր </h4>
                  <p className="catDesch4">
                    Անսահմանափակ աշխատանքային խմբեր և անհատական ​​չաթեր ձեր
                    ձեռքի տակ, ինչպես նաև թեժ գծեր, չաթեր արտաքին օգտատերերի
                    հետ, ֆայլերի փոխանակում և ծանուցումներ:
                  </p>
                  <h4 className="catTitleh4">
                    Աշխատանքային ժամեր և հաշվետվություններ
                  </h4>
                  <p className="catDesch4">
                    Սկսեք, դադարեցրեք, ավարտեք ձեր աշխատանքային օրը մեկ
                    սեղմումով: Հետևեք աշխատանքային ժամերին և ստեղծեք
                    հաշվետվություններ:
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category1})` }}
                ></div>
              </div>

              <div className="categoryBox" ref={refCrm}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category2})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">CRM</h3>
                  <p className="catDesc">
                    Հեռախոսակապ, փոստ, սոցիալական ցանցեր, գովազդային հաշվառում,
                    պահեստներ, վճարում և առաքում. այն ունի ամեն ինչ
                  </p>
                  <h4 className="catTitleh4">
                    Հաճախորդներ, գործարքներ, լիդեր, կոմերցիոն առաջարկներ
                  </h4>
                  <p className="catDesch4">
                    Բոլոր անհրաժեշտ CRM սուբյեկտները: Ստեղծեք և լրացրեք ձեր
                    հաճախորդների բազան, կատարեք վաճառքներ, պահպանեք
                    նամակագրությունը և զանգերը հաճախորդների հետ CRM-ում:
                  </p>
                  <h4 className="catTitleh4">Մարքեթինգ</h4>
                  <p className="catDesch4">
                    Ուղարկեք email և sms նամակներ, ստեղծեք գովազդային արշավներ,
                    հետևեք գովազդային ալիքների արդյունավետությանը End-to-End
                    Analytics-ում: Ձեռք բերեք այն ամենը, ինչ Ձեզ անհրաժեշտ է
                    հաճախորդներին գրավելու համար:
                  </p>
                  <h4 className="catTitleh4">Պահեստներ, վճարում և առաքում</h4>
                  <p className="catDesch4">
                    Այն ամենը, ինչ ձեզ անհրաժեշտ է վաճառքի համար, արդեն կա
                    CRM-ում: Կատարեք պահեստային հաշվառում, վաճառեք ապրանքներ և
                    վերահսկեք պաշարները: Ստեղծեք վճարման հաշիվ-ապրանքագիր և
                    կազմակերպեք առաքում անմիջապես գործարքի քարտից. հաճախորդը
                    կկարողանա վճարել անմիջապես ձեր ընկերության հետ երկխոսության
                    ընթացքում:
                  </p>
                </div>
              </div>

              <div className="categoryBox" ref={refTasks}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Առաջադրանքներ և նախագծեր</h3>
                  <p className="catDesc">
                    Կարգավորեք հանձնարարությունները, աշխատեք ձեզ հարմար ռեժիմով
                  </p>
                  <h4 className="catTitleh4">
                    Պրոեկտի հետ աշխատելու էֆֆեկտիվ մեթոդներ
                  </h4>
                  <p className="catDesch4">
                    Ընտրեք՝ Kanban, Gantt Chart կամ դասական ցուցակներ: Պահպանեք
                    առաջադրանքների օրացույց, հետևեք վերջնաժամկետներին կամ
                    ստեղծեք ձեր սեփական կատարման պլանը: Նախագծերի կառավարման
                    գործում Ձեզ կօգնի Scrum - ը:
                  </p>
                  <h4 className="catTitleh4">Առաջադրանքների հասանելիություն</h4>
                  <p className="catDesch4">
                    Բաշխեք դերերը առաջադրանքներում՝ նշանակեք պատասխանատուներ,
                    կատարողներ, համակատարողներ և դիտորդներ: Ստեղծեք
                    առաջադրանքների մուտքի և կառավարման իրավունքներ՝ ստեղծման,
                    ժամկետների փոփոխության, փոխանցման և այլնի համար:
                  </p>
                  <h4 className="catTitleh4">
                    Առաջադրանքների և նախագծերի ավտոմատացում
                  </h4>
                  <p className="catDesch4">
                    Ստեղծեք տիպային առաջադրանքներ մեկ սեղմումով: Օգտագործեք
                    նախագծերի ձևանմուշները՝ բոլոր ներկառուցված առաջադրանքների և
                    պատասխանատուների հետ միասին՝ ակնթարթորեն նոր նախագծեր
                    ստեղծելու համար: Նամակներ ուղարկելու, կարգավիճակը
                    պատասխանատուին փոխելու ռոբոտները կօգնեն արագացնել աշխատանքը
                    և անել ավելին։
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category3})` }}
                ></div>
              </div>

              <div className="categoryBox" ref={refWebsite}>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category4})` }}
                ></div>
                <div className="categoryDesc">
                  <h3 className="catTitle">Կայքի կոնստրուկտոր</h3>
                  <p className="catDesc">
                    Ձեր սեփական կայքը մի քանի րոպեում առանց ծրագրավորողի և
                    դիզայների՝ պատրաստ աշխատելու հաճախորդների հետ
                  </p>
                  <h4 className="catTitleh4">Ուղիղ կապ CRM-ի հետ</h4>
                  <p className="catDesch4">
                    Կայքն արդեն ստեղծված է CRM-ի ներսում։ Բոլոր այցելուները
                    հաշվի են առնվում, բոլոր դիմումները անմիջապես ուղարկվում են
                    մենեջերին՝ հետագա աշխատանքը կազմակերպելու համար:
                  </p>
                  <h4 className="catTitleh4">SEO-ի խթանում</h4>
                  <p className="catDesch4">
                    Կայքերը լիովին պատրաստված են որոնման համակարգերում
                    առաջխաղացման համար. դրանք օպտիմիզացված են, արագ բեռնվում են
                    և համապատասխանում են որոնման համակարգերի պահանջներին:
                  </p>
                  <h4 className="catTitleh4">
                    Ադապտիվություն ցանկացած էկրանի համար
                  </h4>
                  <p className="catDesch4">
                    Կայքերը պատրաստ են աշխատել աշխատասեղանի համակարգչի, պլանշետի
                    և սմարթֆոնի վրա. բլոկները հարմարվում են տարբեր էկրաններին:
                  </p>
                </div>
              </div>
              <div className="categoryBox" ref={refAutomation}>
                <div className="categoryDesc">
                  <h3 className="catTitle">Ավտոմատացում</h3>
                  <p className="catDesc">
                    Նվազագույն ջանք, առավելագույն արդյունավետություն - սա ճիշտ է
                    ինչպես բիզնեսի ավտոմատացման գործիքների հետ աշխատելու, այնպես
                    էլ դրանք կարգավորելու համար:
                  </p>
                  <h4 className="catTitleh4">Ռոբոտներ և տրիգգերներ</h4>
                  <p className="catDesch4">
                    Սահմանեք առաջադրանք, պլանավորեք զանգ կամ հանդիպում, փոխեք
                    պատասխանատուին կամ գործարքի փուլը, ուղարկեք էլ. կամ sms
                    նամակներ՝ սա ֆունկցիոնալի միայն մի մասն է, որը դուք կարող եք
                    իրականացնել ռոբոտների և տրիգերների միջոցով:
                  </p>
                  <h4 className="catTitleh4">Բիզնես գործընթացներ </h4>
                  <p className="catDesch4">
                    Այս գործիքը պարզեցնում է ընկերության ներսում փաստաթղթերի
                    համակարգման գործընթացը: Արձակուրդի և գործուղման բոլոր
                    դիմումները, հաշիվ-ապրանքագրերը և պայմանագրերը ավտոմատ կերպով
                    անցնում են անհրաժեշտ հաստատումների շղթան հաշված րոպեների
                    ընթացքում: Եվ դուք կարող եք այն կարգավորել առանց կոդի՝
                    հարմար խմբագրիչում:
                  </p>
                  <h4 className="catTitleh4">RPA (Բիզնեսի ռոբոտացում)</h4>
                  <p className="catDesch4">
                    Ներկառուցված kanban-ի վրա հիմնված խմբագրիչը թույլ է տալիս
                    ստեղծել հաստատման շղթա ընդամենը մի քանի րոպեում առանց կոդի:
                    Փաստաթուղթը կանցնի բոլոր պատասխանատուների մոտով, ովքեր
                    կստանան ծանուցումներ և բաց չեն թողնի հաստատման հերթը։
                  </p>
                </div>
                <div
                  className="categoryImg"
                  style={{ backgroundImage: `url(${category5})` }}
                ></div>
              </div>
              <div className="container">
                <span>
                  <p className="titlebi">Գործող Bitrix24 սակագները</p>
                </span>
                <div
                  style={{
                    display: "flex",
                    padding: "0 0 0 0",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box />
                  <BoxPrices />
                  <BoxStandart />
                  <BoxProfessional />
                  <BoxEnterPrice />
                </div>
              </div>
            </div>
          )}
          {showKarobka && (
            <div className="karobka">
              <Bitrix24BoxVersion selectedOption={selectedOption} />
            </div>
          )}
          {showIntegration && (
            <div className="karobka">
              <IntegrationBox selectedOption={selectedOption} />
            </div>
          )}
        </div>
      )}
     
    </div>
  );
};

export default Bitrix24Container;

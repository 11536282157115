import React, { useEffect } from "react";
import video from "../../assets/images/video1.mp4";
import "./style.scss";
import About from "../about";
import Services from "../services";
import Team from "../team";
import Contacts from "../contacts";
import Partners from "../partners";
import { Helmet } from "react-helmet";
const Home = ({ selectedOption }) => {
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում ☎️ 091 09 09 07');
  }, []);
  return (
    <> <Helmet>
    <title> Business automation, CRM-, BI-, ERP-, WMS-systems
                   programming ☎️ 091 09 09 07:</title>
        <link rel="canonical" href={window.location.href} />
  </Helmet>
      <div className="homeContainer">
        <video loop autoPlay muted className="videoHome">
          <source src={video} type="video/mp4" />
        </video>
        <div className="homeContainer2">
          {selectedOption === "en" && (
            <>
              <Helmet>
                <title>
                Business automation, CRM-, BI-, ERP-, WMS-systems
                   programming ☎️ 091 09 09 07:
                </title>
              </Helmet>
              <h1 className="homeTitle">Welcome to CodeWave</h1>
            </>
          )}
          {selectedOption === "ru" && (
            <>
              <Helmet>
                <title>
                Автоматизация бизнеса, CRM-, BI-, ERP-, WMS-системы
                   программирование ☎️ 091 09 09 07:
                </title>
              </Helmet>
            <h1 className="homeTitle">Добро пожаловать CodeWave</h1>
            </>
          )}
          {selectedOption === "am" && (
            <>
              <Helmet>
                <title>
                  Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի
                  ծրագրավորում ☎️ 091 09 09 07
                </title>
              </Helmet>
            <h1 className="homeTitle">Բարի գալուստ CodeWave</h1>
            </>
          )}
          {selectedOption === "en" && (
            <p className="homeDesc">
              Riding the waves of innovation with CodeWave
            </p>
          )}
          {selectedOption === "ru" && (
            <p className="homeDesc">На волнах инноваций с CodeWave</p>
          )}
          {selectedOption === "am" && (
            <p className="homeDesc">Նորարարության ալիքներին CodeWave-ի հետ:</p>
          )}
        </div>
      </div>
      <About selectedOption={selectedOption} />
      <Services selectedOption={selectedOption} />
      <Team selectedOption={selectedOption} />
      <Partners selectedOption={selectedOption} />
      <Contacts selectedOption={selectedOption} />
    </>
  );
};
export default Home;

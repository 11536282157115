import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import back from "../../../assets/images/ERP/ERPBack.png";
// import icon1 from "../../../assets/images/ERP/icon1.png";
// import icon2 from "../../../assets/images/ERP/icon2.png";
// import icon3 from "../../../assets/images/ERP/icon3.png";
// import icon4 from "../../../assets/images/ERP/icon4.png";
// import icon5 from "../../../assets/images/ERP/icon5.png";
// import icon6 from "../../../assets/images/ERP/icon6.png";
import img1 from "../../../assets/images/ERP/img1.jpg";
import img2 from "../../../assets/images/ERP/img2.jpg";
import img3 from "../../../assets/images/ERP/img3.jpg";
import img4 from "../../../assets/images/ERP/img4.jpg";
import img5 from "../../../assets/images/ERP/img5.jpg";
import img6 from "../../../assets/images/ERP/img6.jpg";
import benefits from "../../../assets/images/ERP/benefitsIimg.png";
import right from "../../../assets/images/wms/right.png";
import { Helmet } from "react-helmet";

const ERPContainer = ({ selectedOption, handleClose, path }) => {
  const refModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };

  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const titlesSolution = [
    {
      ulTitle: "Enhanced Efficiency: ",
      title1:
        "Industry-specific modules streamline processes, reducing manual work and boosting productivity. ",
      title2:
        " Automated workflows optimize operations, minimizing errors and delays. ",
      title3:
        " Real-time data availability enables faster decision-making and response times.",
    },
    {
      ulTitle: "Improved Visibility:",
      title1:
        "Industry-focused analytics provide insights into key performance indicators (KPIs) and trends.",
      title2:
        "Managers can monitor operations at various levels, from individual departments to the entire organization.",
      title3:
        "Detailed reporting enables data-driven strategies and actionable insights.",
    },
    {
      ulTitle: "Compliance and Regulatory Support:",
      title1:
        "Industry-based ERP systems incorporate specific compliance requirements, reducing the risk of non-compliance.",
      title2:
        "Automated documentation and reporting simplify audits and regulatory submissions.",
      title3:
        "Built-in controls ensure adherence to industry standards and regulations.",
    },
    {
      ulTitle: "Scalability and Flexibility: ",
      title1:
        "Our industry-based ERP solutions grow alongside your business, accommodating expansion.",
      title2:
        " Customizable modules and workflows adapt to changing industry needs.",
      title3:
        " Integration capabilities enable seamless connectivity with other software systems. ",
    },
    {
      ulTitle: "Enhanced Collaboration: ",
      title1:
        "industry-based ERP solutions foster collaboration among teams and departments. ",
      title2:
        "Centralized data and communication channels enable seamless information sharing and cross-functional collaboration.",
      title3:
        "Collaborative features such as task assignments, document sharing, and real-time messaging facilitate teamwork and enhance productivity. ",
    },
    {
      ulTitle: "Customer-Centric Approach: ",
      title1:
        "Industry-based ERP systems enable businesses to better serve their customers. ",
      title2:
        "Integrated customer relationship management (CRM) functionalities provide a holistic view of customer interactions and preferences. ",
      title3:
        "By understanding customer needs and preferences, businesses can personalize their offerings, improve customer satisfaction, and foster long-term loyalty.",
    },
  ];
  const titlesSolutionRu = [
    {
      ulTitle: "Повышенная эффективность: ",
      title1:
        "Отраслевые модули оптимизируют процессы, сокращая ручную работу   повышая производительность.",
      title2:
        "Автоматизированные рабочие процессы оптимизируют операции, сводя к минимуму ошибки и задержки.",
      title3:
        "Доступность данных в режиме реального времени позволяет быстрее принимать решения и реагировать на них.",
    },
    {
      ulTitle: "Улучшенная видимость:",
      title1:
        "Отраслевая аналитика позволяет получить представление о ключевых показателях эффективности (KPI) и тенденциях.",
      title2:
        "Руководители могут контролировать операции на различных уровнях, от отдельных отделов до всей организации.",
      title3:
        "Подробная отчетность позволяет разрабатывать стратегии, основанные на данных, и получать практические выводы.",
    },
    {
      ulTitle: " Соответствие нормативным требованиям :",
      title1:
        "Отраслевые ERP-системы учитывают конкретные требования к соблюдению нормативных требований, снижая риск несоблюдения.",
      title2:
        "Автоматизированная документация и отчетность упрощают проведение аудитов и подачу нормативных документов.",
      title3:
        " Встроенные средства контроля обеспечивают соблюдение отраслевых стандартов и нормативных требований.",
    },
    {
      ulTitle: " Масштабируемость и гибкость:",
      title1:
        "Наши отраслевые ERP-решения развиваются вместе с вашим бизнесом, обеспечивая возможность расширения.",
      title2:
        "Настраиваемые модули и рабочие процессы адаптируются к изменяющимся потребностям отрасли.",
      title3:
        "Интеграционные возможности обеспечивают соединение с другими программными системами.",
    },
    {
      ulTitle: " Улучшенное взаимодействие:",
      title1:
        "Наши отраслевые ERP-решения способствуют сотрудничеству между командами и отделами.",
      title2:
        "Централизованные данные и каналы связи обеспечивают беспрепятственный обмен информацией и межфункциональное сотрудничество.",
      title3:
        "Функции совместной работы, такие как назначение задач, обмен документами и сообщениями в режиме реального времени, облегчают командную работу и повышают производительность.",
    },
    {
      ulTitle: " Клиентоориентированный подход:",
      title1:
        "Отраслевые ERP-системы позволяют предприятиям лучше обслуживать своих клиентов.",
      title2:
        "• Интегрированные функции управления взаимоотношениями с клиентами (CRM) обеспечивают целостное представление о взаимодействии и предпочтениях клиентов.",
      title3:
        "Понимая потребности и предпочтения клиентов, предприятия могут персонализировать свои предложения, повышать удовлетворенность клиентов и укреплять долгосрочную лояльность.",
    },
  ];
  const titlesSolutionAm = [
    {
      ulTitle: "Բարձր արտադրողականություն ",
      title1:
        "Ոլորտային մոդուլները օպտիմալացնում են գործընթացները՝ նվազեցնելով ձեռքի աշխատանքը և բարձրացնելով արտադրողականությունը:",
      title2:
        "Ավտոմատացված աշխատանքային պրոցեսները հեշտացնում են գործառույթները՝ նվազագույնի հասցնելով սխալներն ու ուշացումները:",
      title3:
        "Իրական ժամանակի տվյալների հասանելիությունը թույլ է տալիս ավելի արագ որոշումներ կայացնել և արձագանքել իրավիճակներին.",
    },
    {
      ulTitle: "Բարելավված տեսանելիություն.",
      title1:
        "Ոլորտային վերլուծությունը պատկերացում է տալիս հիմնական կատարողականի ցուցանիշների (KPIs) և տենդենցների մասին:",
      title2:
        "Ղեկավարները կարող են վերահսկել գործողությունները տարբեր մակարդակներում՝ առանձին բաժիններից մինչև ամբողջ կազմակերպությունը.",
      title3:
        "Դետալիզացված հաշվետվությունները հնարավորություն են տալիս տվյալների վրա հիմնված ռազմավարություններ կառուցել և գործնական պատկերացումներ կազմել.",
    },
    {
      ulTitle: " Կանոնակարգային համապատասխանություն:",
      title1:
        "Ոլորտային ERP համակարգերը հաշվի են առնում տվյալ ոլորտի նորմատիվ պահանջները՝ նվազեցնելով անհամապատասխանությունների ռիսկը:",
      title2:
        "Ավտոմատացված փաստաթղթաշրջանառությունը և հաշվետվությունները հեշտացնում են աուդիտների անցկացումը և կարգավորող փաստաթղթեր ներկայացումը:",
      title3:
        " Ներկառուցված վերահսկման համակարգը ապահովում է համապատասխանությունը արդյունաբերության ստանդարտներին և կանոնակարգերին",
    },
    {
      ulTitle: "Ընդարձակում և ճկունություն.",
      title1:
        "Մեր ոլորտին հատուկ ERP լուծումներն աճում են ձեր բիզնեսի հետ զուգահեռ՝ թույլ տալով ձեզ ընդլայնել:",
      title2:
        "Անհատականացվող մոդուլները և աշխատանքային պրոցեսները հարմարվում են ոլորտի փոփոխվող կարիքներին:",
      title3:
        "Ինտեգրացիոն հնարավորությունները ապահովում են կապը այլ ծրագրային համակարգերի հետ",
    },
    {
      ulTitle: " Բարելավված փոխգործակցություն:",
      title1:
        "Մեր ոլորտին հատուկ ERP լուծումները հեշտացնում են համագործակցությունը թիմերի և բաժինների միջև",
      title2:
        "Տվյալների կենտրոնացված պահպանումը և հաղորդակցման ուղիները հնարավորություն են տալիս անխափան տեղեկատվության փոխանակում և համագործակցություն",
      title3:
        "Միասնական աշխատանքի գործիքնեը ինչպիսիք են՝ առաջադրանքների առաջադրումը, փաստաթղթերի և հաղորդագրությունների փոխանակումը իրական ժամանակում, թիմային աշխատանքը դարձնում են ավելի հեշտ և արդյունավետ",
    },
    {
      ulTitle: " Հաճախորդակենտրոն մոտեցում.",
      title1:
        "Ոլորտային ERP համակարգերը բիզնեսին հնարավորություն են տալիս ավելի լավ սպասարկել իրենց հաճախորդներին, ",
      title2:
        "Հաճախորդների հետ հարաբերությունների կառավարման (CRM) ինտեգրված գործառույթները ապահովում են ամբողջական պատկերացում հաճախորդների փոխազդեցությունների և նախասիրությունների վերաբերյալ:",
      title3:
        "Հասկանալով հաճախորդների կարիքներն ու նախասիրությունները՝ ձեռնարկությունները կարող են անհատականացնել իրենց առաջարկները, բարելավել հաճախորդների գոհունակությունը և ապահովել երկարաժամկետ հավատարմություն:",
    },
  ];
  const Sectors = [
    {
      Img: img1,
      title: "Manufacturing",
      description:
        "Streamline production processes, inventory management, and supply chain operations.",
        duration:'1000'

    },
    {
      Img: img2,
      title: "Healthcare",
      description:
        "Manage patient data, appointment scheduling, and regulatory compliance.",
        duration:'2000'

    },
    {
      Img: img3,
      title: "Retail and E-commerce",
      description:
        " Optimize inventory, order management, and customer relationship management.",
        duration:'3000'

    },
    {
      Img: img4,
      title: "Construction",
      description:
        "Enhance project management, resource allocation, and equipment tracking.",
        duration:'4000'

    },
    {
      Img: img5,
      title: "Financial Services",
      description:
        " Streamline accounting, asset management, and compliance tracking.",
        duration:'5000'

    },
    {
      Img: img6,
      title: "Education",
      description:
        "Simplify student information management, course scheduling, and academic reporting.",
        duration:'6000'

    },
  ];
  const SectorsRu = [
    {
      Img: img1,
      title: "Производство",
      description:
        "Оптимизируйте производственные процессы, управление запасами и операции цепочки снабжения.",
        duration:'1000'

    },
    {
      Img: img2,
      title: "Здравоохранение ",
      description:
        "Управление данными пациентов, составление расписания приемов и соблюдение нормативных требований.",
        duration:'2000'

    },
    {
      Img: img3,
      title: "Tорговля",
      description:
        "Оптимизация инвентаризации, управления заказами и управления взаимоотношениями с клиентами.",
        duration:'3000'

    },
    {
      Img: img4,
      title: "Строительство",
      description:
        "Улучшение управления проектами, распределение ресурсов и отслеживание оборудования.",
        duration:'4000'

    },
    {
      Img: img5,
      title: "Финансовые службы",
      description:
        " Оптимизация бухгалтерского учета, управления активами и отслеживания соблюдения нормативных требований.",
        duration:'5000'

    },
    {
      Img: img6,
      title: "Образование",
      description:
        "Упрощение управления информацией о студентах, составления расписания курсов и академической отчетности.",
        duration:'6000'

    },
  ];
  const SectorsAm = [
    {
      Img: img1,
      title: "Արտադրություն",
      description:
        "Օպտիմիզացրեք արտադրական գործընթացները, գույքագրման կառավարումը և մատակարարման շղթայի գործառնությունները:",
        duration:'1000'
    },
    {
      Img: img2,
      title: "Առողջապահություն ",
      description:
        "Հիվանդների տվյալների կառավարում, այցերի ժամանակացույց և կանոնակարգային համապատասխանություն.",
        duration:'2000'

    },
    {
      Img: img3,
      title: "Առևտուր",
      description:
        "Օպտիմալացրեք գույքագրումը, պատվերի կառավարումը և հաճախորդների հետ հարաբերությունների կառավարումը:",
        duration:'3000'

    },
    {
      Img: img4,
      title: "Շինարարություն",
      description:
        "Ծրագրի բարելավված կառավարում, ռեսուրսների բաշխում և սարքավորումների հետևողականություն.",
        duration:'4000'

    },
    {
      Img: img5,
      title: "Ֆինանսական ծառայություններ",
      description:
        " Հեշտացրեք հաշվապահական հաշվառումը, ակտիվների կառավարումը և նորմատիվ համապատասխանությունների վերահսկումը",
        duration:'5000'

    },
    {
      Img: img6,
      title: "Կրթություն",
      description:
        "Պարզեցրեք ուսանողների տեղեկատվության կառավարումը, դասընթացների պլանավորումը և ակադեմիական հաշվետվությունները .",
        duration:'6000'

    },
  ];
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Ձեռնարկությունների ռեսուրսների պլանավորման համակարգ (ERP) ☎️ 091 09 09 07');
  }, []);

    return (
    <div ref={refModal}>
      <div className="top70"></div>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {selectedOption === "en" && (
        <div className="webCont">
          <Helmet>
            <title>
              Enterprise resource planning system (ERP) ☎️ 091 09 09 07
            </title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Industry baces ERP systems </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">What is ERP?</h2>
                <p className="biTitleP">
                  ERP is a powerful software system that integrates key aspects
                  of your business, including finance, human resources, supply
                  chain management, manufacturing, and more. It acts as a
                  centralized hub, facilitating seamless communication and
                  collaboration across departments. By implementing an ERP
                  system, you can enhance efficiency, reduce costs, and gain
                  real-time insights to make informed decisions.
                  <br />
                  <br />
                  While generic ERP systems offer broad functionality, they
                  often fall short in meeting industry-specific requirements.
                  Businesses struggle to adapt these systems to their unique
                  processes, resulting in inefficiencies and workarounds. Our
                  industry-based ERP solutions address these challenges by
                  providing tailored features, workflows, and functionalities.
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">
                Benefits of Industry-Based ERP Solutions
              </h2>
            </div>
            <div
              className="benefit"
              style={{ backgroundImage: `url(${benefits})` }}
            ></div>
            {titlesSolution.map((item, index) => (
              <div className="G-align-start listAndIcon" key={index}>
                <div
                  className="right rotate"
                  style={{ backgroundImage: `url(${right})` }}
                ></div>

                <ul className="solutionList" key={index}>
                  {item.ulTitle}
                  <li className="solutionProcess">{item.title1}</li>
                  <li className="solutionProcess">{item.title2}</li>
                  <li className="solutionProcess">{item.title3}</li>
                </ul>
              </div>
            ))}
            <h2 className="biTitleh2 center">
              Discover how our industry-based ERP solutions cater to various
              sectors
            </h2>
            <div className="G-flex workList ">
              {Sectors.map((item, index) => (
                <div
                  className="workBox width15"
                  key={index}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration={item.duration}
                >
                  <div
                    className="workImg"
                    style={{ backgroundImage: `url(${item.Img})` }}
                  ></div>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <p className="biTitleP centerText">
              At CodeWave, we specialize in developing industry-based ERP
              systems tailored to your unique requirements. Our collaborative
              approach ensures that the solution aligns perfectly with your
              business needs. From custom modules to workflow optimization and
              seamless integrations, our expertise guarantees maximum value for
              your organization. We offer ongoing support, training, and system
              enhancements to help you thrive
              <br />
              <br />
              Industry-based ERP solutions are revolutionizing businesses across
              various sectors, empowering organizations to achieve operational
              excellence and drive sustainable growth. At CodeWave, we are
              dedicated to delivering high-quality industry-based ERP solutions
              that are tailored to your unique requirements. Our collaborative
              approach ensures that the solution aligns perfectly with your
              business needs, from custom modules to workflow optimization and
              seamless integrations. With our expertise and ongoing support, we
              enable you to streamline operations, enhance efficiency, and make
              data-driven decisions.
              <br />
              <br />
              <b>
                Contact us today to learn more about how our industry-based ERP
                solutions can transform your organization and pave the way for
                success in the digital era.
              </b>
            </p>
          </div>
        </div>
      )}

      {selectedOption === "am" && (
        <div className="webCont">
          <Helmet>
            <title>
              Ձեռնարկությունների ռեսուրսների պլանավորման համակարգ (ERP) ☎️ 091
              09 09 07
            </title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2>ERP համակարգ</h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">Ի՞նչ է ERP-ն:</h2>
                <p className="biTitleP">
                  ERP-ն հզոր ծրագրային համակարգ է, որը միավորում է ձեր բիզնեսի
                  հիմնական ասպեկտները, ներառյալ ֆինանսները, մարդկային
                  ռեսուրսները, մատակարարման շղթայի կառավարումը, արտադրությունը և
                  այլն: Այն գործում է որպես կենտրոնական հանգույց՝ ապահովելով
                  անխափան հաղորդակցություն և համագործակցություն ձեռնարկության
                  բաժինների միջև: ERP համակարգի ներդրումը բարելավում է
                  արդյունավետությունը, նվազեցնում ծախսերը և տրամադրելով
                  տեղեկատվություն իրական ժամանակում՝ հնարավորություն է տալիս
                  հիմնավորված որոշումներ կայացնելու:
                  <br />
                  <br />
                  Թեև ընդհանուր ERP համակարգերն առաջարկում են լայն
                  ֆունկցիոնալություն, նրանք հաճախ չեն բավարարում ոլորտին բնորոշ
                  պահանջները: Ձեռնարկությունները պայքարում են այս համակարգերը
                  հարմարեցնելու իրենց եզակի գործընթացներին, որոնք հանգեցնում են
                  անարդյունավետության և լուծումների: Մեր Արդյունաբերության վրա
                  հիմնված ERP լուծումները լուծում են այս մարտահրավերները
                  ապահովելով հարմարեցված հատկանիշներ, աշխատանքային հոսքեր և
                  ֆունկցիոնալ հնարավորություններ:
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">
                Ոլորտային ERP լուծումների առավելությունները
              </h2>
            </div>
            <div
              className="benefit"
              style={{ backgroundImage: `url(${benefits})` }}
            ></div>
            {titlesSolutionAm.map((item, index) => (
              <div className="G-align-start listAndIcon" key={index}>
                <div
                  className="right rotate"
                  style={{ backgroundImage: `url(${right})` }}
                ></div>
                <ul className="solutionList" key={index}>
                  {item.ulTitle}
                  <li className="solutionProcess">{item.title1}</li>
                  <li className="solutionProcess">{item.title2}</li>
                  <li className="solutionProcess">{item.title3}</li>
                </ul>
              </div>
            ))}
            <h2 className="biTitleh2 center">
              Ահա թե ինչպես են մեր ոլորտային ERP լուծումները համապատասխանում
              տարբեր ոլորտներին
            </h2>
            <div className="G-flex workList ">
              {SectorsAm.map((item, index) => (
                <div
                  className="workBox width15"
                  key={index}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration={item.duration}
                >
                  <div
                    className="workImg"
                    style={{ backgroundImage: `url(${item.Img})` }}
                  ></div>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <p className="biTitleP centerText">
              CodeWave-ում մենք մասնագիտացած ենք արդյունաբերության վրա հիմնված
              ERP մշակման մեջ. համակարգեր, որոնք հարմարեցված են ձեր յուրահատուկ
              պահանջներին: Մեր համագործակցությունը մոտեցումը երաշխավորում է, որ
              լուծումը լիովին համընկնում է ձեր բիզնեսի կարիքներին. Պատվերով
              մոդուլներից մինչև աշխատանքային հոսքի օպտիմալացում և անխափան
              ինտեգրում, մեր փորձը երաշխավորում է առավելագույն արժեք ձեր
              կազմակերպությունը: Մենք առաջարկում ենք շարունակական աջակցություն,
              ուսուցում և համակարգ բարելավումներ, որոնք կօգնեն ձեզ բարգավաճել
              <br />
              <br />
              Արդյունաբերության վրա հիմնված ERP լուծումները հեղափոխություն են
              անում ամբողջ բիզնեսում տարբեր ոլորտներ՝ հնարավորություն տալով
              կազմակերպություններին հասնել գործառնական գերազանցություն։
              CodeWave-ում մենք նվիրված ենք առաքմանը արդյունաբերության վրա
              հիմնված բարձրորակ ERP լուծումներ, որոնք ապահովում են
              արդյունավետությունը, բարձրացնել արտադրողականությունը և օգնել ձեր
              բիզնեսին զարգանալ:
              <br />
              <br />
              <b>
                Կապվեք մեզ հետ այսօր ավելին իմանալու, թե ինչպես մեր
                արդյունաբերության վրա հիմնված ERP լուծումները կարող են վերափոխել
                ձեր կազմակերպությունը:
              </b>
            </p>
          </div>
        </div>
      )}

      {selectedOption === "ru" && (
        <div className="webCont">
          <Helmet>
            <title>
              Система планирования ресурсов предприятия (ERP) ☎️ 091 09 09 07
            </title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> ERP-системы</h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">Что такое ERP?</h2>
                <p className="biTitleP">
                  ERP - это мощная программная система, которая объединяет
                  ключевые аспекты вашего бизнеса, включая финансы, человеческие
                  ресурсы, управление цепочкой поставок, производство и многое
                  другое. Она действует как централизованный узел, обеспечивая
                  бесперебойную связь и сотрудничество между отделами. Внедрение
                  ERP-системы позволяет повысить эффективность, снизить затраты
                  и получать информацию в режиме реального времени для принятия
                  обоснованных решений.
                  <br />
                  <br />
                  Хотя типовые ERP-системы предлагают широкую функциональность,
                  они часто не отвечают требованиям конкретной отрасли.
                  Предприятиям трудно адаптировать эти системы к своим
                  уникальным процессам, что приводит к неэффективности и
                  обходным путям. Наши отраслевые ERP-решения решают эти
                  проблемы, предоставляя специализированные функции, рабочие
                  процессы и функциональные возможности.
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">Преимущества отраслевых ERP решений</h2>
            </div>
            <div
              className="benefit"
              style={{ backgroundImage: `url(${benefits})` }}
            ></div>
            {titlesSolutionRu.map((item, index) => (
              <div className="G-align-start listAndIcon" key={index}>
                <div
                  className="right rotate"
                  style={{ backgroundImage: `url(${right})` }}
                ></div>
                <ul className="solutionList" key={index}>
                  {item.ulTitle}
                  <li className="solutionProcess">{item.title1}</li>
                  <li className="solutionProcess">{item.title2}</li>
                  <li className="solutionProcess">{item.title3}</li>
                </ul>
              </div>
            ))}
            <h2 className="biTitleh2 center">
              Узнайте, как наши отраслевые ERP-решения подходят для различных
              секторов
            </h2>
            <div className="G-flex workList ">
              {SectorsRu.map((item, index) => (
                <div
                  className="workBox width15"
                  key={index}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration={item.duration}
                >
                  <div
                    className="workImg"
                    style={{ backgroundImage: `url(${item.Img})` }}
                  ></div>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <p className="biTitleP centerText">
              В CodeWave мы специализируемся на разработке отраслевых
              ERP-систем. системы, адаптированные к вашим уникальным
              требованиям. Наше сотрудничество подход гарантирует, что решение
              идеально согласуется с вашим потребности бизнеса. От
              пользовательских модулей до оптимизации рабочего процесса и
              бесшовные интеграции, наш опыт гарантирует максимальную ценность
              для ваша организация. Мы предлагаем постоянную поддержку, обучение
              и систему улучшения, которые помогут вам добиться успеха
              <br />
              <br />
              Отраслевые ERP-решения революционизируют бизнес в различных
              отраслях, позволяя организациям достичь операционного совершенства
              и обеспечить устойчивый рост. Мы в CodeWave занимаемся разработкой
              высококачественных отраслевых ERP-решений, которые соответствуют
              вашим уникальным требованиям. Наш комплексный подход гарантирует,
              что решение идеально соответствует потребностям вашего бизнеса,
              начиная с пользовательских модулей и заканчивая оптимизацией
              рабочих процессов и беспрепятственной интеграцией. Благодаря
              нашему опыту и постоянной поддержке мы поможем вам оптимизировать
              операции, повысить эффективность и принимать решения, основанные
              на данных.
              <br />
              <br />
              <b>
                Свяжитесь с нами сегодня, чтобы узнать больше о том, как наши
                отраслевые ERP-решения могут преобразовать вашу организацию и
                проложить путь к успеху в цифровую эпоху.
              </b>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ERPContainer;

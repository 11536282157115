import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { GrClose } from "react-icons/gr";
import back from "../../../assets/images/wms/wmsBack.png";
import icon1 from "../../../assets/images/wms/icon1.png";
import icon2 from "../../../assets/images/wms/icon2.png";
import icon3 from "../../../assets/images/wms/icon3.png";
import icon4 from "../../../assets/images/wms/icon4.png";
import right from "../../../assets/images/wms/right.png";
import wms1 from "../../../assets/images/wms/wms1.png";
import wms2 from "../../../assets/images/wms/wms2.png";
import { Helmet } from "react-helmet";

const WMSContainer = ({ selectedOption, handleClose }) => {
  const refModal = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };
  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [loadingProgress, setLoadingProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingProgress((prevProgress) => (prevProgress + 1) % 101);
    }, 10);

    if (loadingProgress === 100) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loadingProgress]);
  useEffect(() => {
    localStorage.setItem('pageTitle', 'WMS-համակարգերի ծրագրավորում ☎️ 091 09 09 07');
  }, []);
  return (
    <div ref={refModal}>
      <div className="top70"></div>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {selectedOption === "en" && (
        <div className="webCont">
          <Helmet>
            <title>WMS-systems programming ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Warehouse Management System </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">
                  What is Warehouse Management System?
                </h2>
                <p className="biTitleP">
                  A WMS, or warehouse management system, is software that helps
                  companies manage and control daily warehouse operations, from
                  the moment goods and materials enter a distribution or
                  fulfillment center until the moment they leave. WMS software
                  system is a key component of supply chain management and offer
                  real-time visibility into a company’s entire inventory, in
                  warehouses and in transit. <br />
                  <br />
                  In addition to inventory management, a WMS offers tools for
                  picking and packing processes, resource utilization,
                  analytics, and more. WMS include features such as inventory
                  tracking, order management, receiving and shipping management,
                  warehouse layout design, labor management, and reporting and
                  analytics. <br />
                  <br />
                  By using a WMS, businesses can improve their warehouse
                  efficiency, accuracy, and productivity, reduce labor costs,
                  and optimize space utilization. With real-time data and
                  analytics, a WMS can also help businesses make informed
                  decisions about inventory and resource allocation.
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">What the WMS do?</h2>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Order management </h2>
                  <p className="biTitleP">
                    A WMS can manage the entire order fulfillment process, from
                    receiving orders to picking, packing, and shipping. The
                    system track the status of each order, providing greater
                    visibility into the order fulfillment process and enabling
                    businesses to proactively manage exceptions or delays.
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Reports and Analytics </h2>
                  <p className="biTitleP">
                    A WMS can generate a wide range of reports, such as
                    inventory reports, order reports, shipping reports, and
                    labor reports. These reports can provide businesses with a
                    comprehensive overview of their warehouse operations,
                    enabling them to identify trends, patterns, and areas for
                    improvement.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Resource management </h2>
                  <p className="biTitleP">
                    The system can help businesses effectively manage their
                    resources by providing real-time alerts when inventory
                    levels approach the minimum threshold. Additionally, the
                    system can notify users when a particular product has
                    remained unsold for an extended period of time, indicating
                    the need for an evaluation of sales strategy or
                    consideration of a product discount.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Inventory management </h2>
                  <p className="biTitleP">
                    A WMS provides real-time visibility of inventory levels and
                    locations, enabling accurate and efficient inventory
                    management. Using barcoding, RFID tagging, sensors, or other
                    location tracking methods, a WMS system gives real-time
                    insight into inventory. With this visibility, you can create
                    more accurate demand forecasts, run a just-in-time inventory
                    strategy, and improve traceability.
                  </p>
                </div>
              </div>
            </div>

            <h2 className="biprocessh2">Benefits of WMS for business</h2>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Increased Efficiency:</b> A WMS helps businesses to optimize
                their warehouse operations by providing real-time information
                about inventory levels, location, and movement. This information
                enables businesses to make better decisions about inventory
                placement, order picking, and fulfillment, ultimately leading to
                increased efficiency and productivity.
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Enhanced Customer Service: </b>A WMS can help businesses to
                provide better customer service by enabling faster order
                fulfillment and more accurate order processing. With real-time
                inventory tracking and order status updates, businesses can
                provide customers with timely and accurate information about
                their orders, leading to higher customer satisfaction.
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Better Decision Making: </b>WMS software provides businesses
                with real-time data and analytics on warehouse operations,
                enabling better decision making. By analyzing data on inventory
                levels, order volumes, and operational performance, businesses
                can identify trends, make informed decisions, and optimize their
                warehouse operations to meet changing demand.
              </p>
            </div>
            <div className="wmsImges">
              <div
                className="wmsLeft"
                style={{ backgroundImage: `url(${wms1})` }}
              ></div>
              <div
                className="wmsRight"
                style={{ backgroundImage: `url(${wms2})` }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "am" && (
        <div className="webCont">
          <Helmet>
            <title>WMS-համակարգերի ծրագրավորում ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Պահեստների կառավարման համակարգ</h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">
                  Ի՞նչ է պահեստի կառավարման համակարգը:
                </h2>
                <p className="biTitleP">
                  WMS-ը կամ պահեստի կառավարման համակարգը ծրագրային ապահովում է,
                  որն օգնում է ընկերություններին կառավարել և վերահսկել պահեստի
                  ամենօրյա գործառնությունները՝ ապրանքների և նյութերի բաշխման
                  կենտրոն մտնելու պահից մինչև դուրս գալու պահը: WMS ծրագրային
                  համակարգը մատակարարման շղթայի կառավարման հիմնական բաղադրիչն է
                  և առաջարկում է իրական ժամանակի տեսանելիություն ընկերության
                  ռեսուրսներին՝ պահեստներում և շարժի մեջ:
                  <br />
                  <br />
                  WMS-ն առաջարկում է գործիքներ՝ հավաքման և փաթեթավորման
                  գործընթացների, ռեսուրսների օգտագործման, վերլուծության և այլնի
                  համար: WMS-ը ներառում է այնպիսի գործառույթներ, ինչպիսիք են
                  գույքագրման հետևումը, պատվերի կառավարումը, ստացման և առաքման
                  կառավարումը, պահեստի դասավորության ձևավորումը, աշխատուժի
                  կառավարումը և հաշվետվություններն ու վերլուծությունները: <br />
                  <br />
                  Օգտագործելով WMS՝ ձեռնարկությունները կարող են բարելավել իրենց
                  պահեստի արդյունավետությունը, ճշգրտությունը և
                  արտադրողականությունը, նվազեցնել աշխատուժի ծախսերը և
                  օպտիմալացնել տարածքի օգտագործումը: Իրական ժամանակի տվյալների և
                  վերլուծությունների միջոցով WMS-ը կարող է նաև օգնել բիզնեսին
                  տեղեկացված որոշումներ կայացնել գույքագրման և ռեսուրսների
                  բաշխման վերաբերյալ:
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">Ի՞նչ է անում WMS-ը:</h2>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Պատվերների կառավարում </h2>
                  <p className="biTitleP">
                    WMS-ը կարող է կառավարել պատվերի կատարման ողջ գործընթացը՝
                    պատվերներ ստանալուց մինչև հավաքում, փաթեթավորում և առաքում:
                    Համակարգը հետևում է յուրաքանչյուր պատվերի կարգավիճակին՝
                    ապահովելով պատվերի կատարման գործընթացի ավելի մեծ
                    տեսանելիություն և բիզնեսին հնարավորություն տալով ակտիվորեն
                    կառավարել խնդիրները կամ ուշացումները:
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">
                    Հաշվետվություններ և վերլուծություններ{" "}
                  </h2>
                  <p className="biTitleP">
                    WMS-ը կարող է ստեղծել հաշվետվությունների լայն շրջանակ,
                    ինչպիսիք են գույքագրման հաշվետվությունները, պատվերի և
                    առաքման հաշվետվությունները և այլն: Այս հաշվետվությունները
                    կարող են ձեռնարկություններին տրամադրել իրենց պահեստային
                    գործունեության համապարփակ տեղեկատվություն՝ հնարավորություն
                    տալով վերջիններիս բացահայտել միտումները, օրինաչափությունները
                    և բարելավման ոլորտները:
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Ռեսուրսների կառավարում </h2>
                  <p className="biTitleP">
                    Համակարգը կարող է օգնել բիզնեսներին արդյունավետորեն
                    կառավարել իրենց ռեսուրսները՝ տրամադրելով իրական ժամանակի
                    ծանուցումներ, երբ ապրանքի քանակը մոտենում է նվազագույն
                    շեմին: Բացի այդ, համակարգը կարող է ծանուցել օգտատերերին, երբ
                    որոշակի ապրանք երկար ժամանակ չի վաճառվում՝ նշելով վաճառքի
                    ռազմավարության գնահատման կամ ապրանքի վրա զեղչ դիտարկելու
                    անհրաժեշտությունը:
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Գույքագրման կառավարում </h2>
                  <p className="biTitleP">
                    WMS-ն ապահովում է գույքագրման մակարդակների և տեղանքների
                    իրական ժամանակի տեսանելիություն՝ հնարավորություն տալով
                    ճշգրիտ և արդյունավետ կառավարել գույքագրումը: Օգտագործելով
                    շտրիխ կոդերի, RFID տվիչների, սենսորներ կամ տեղորոշման
                    հետագծման այլ սարքերից ստացված տվյալները՝ WMS համակարգը
                    իրական ժամանակի պատկերացում է տալիս ձեռնարկության
                    ռեսուրսների վերաբերյալ: Այս տեսանելիության շնորհիվ դուք
                    կարող եք ստեղծել պահանջարկի ավելի ճշգրիտ կանխատեսումներ,
                    իրականացնել ճիշտ գույքագրման ռազմավարություն և բարելավել
                    վերահսկողությունը:
                  </p>
                </div>
              </div>
            </div>

            <h2 className="biprocessh2">
              WMS-ի առավելությունները բիզնեսի համար
            </h2>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b> Արդյունավետության բարձրացում. </b>WMS-ն օգնում է
                ձեռնարկություններին օպտիմիզացնել իրենց պահեստային
                գործունեությունը՝ իրական ժամանակում տեղեկատվություն տրամադրելով
                գույքագրման մակարդակների, գտնվելու վայրի և տեղաշարժի մասին: Այս
                տեղեկատվությունը բիզնեսին հնարավորություն է տալիս ավելի օպտիմալ
                որոշումներ կայացնել գույքագրման տեղաբաշխման, պատվերների
                ընտրության և կատարման վերաբերյալ՝ ի վերջո հանգեցնելով
                արդյունավետության և արտադրողականության բարձրացման:
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Հաճախորդների սպասարկման բարելավում. </b>WMS-ը կարող է օգնել
                ձեռնարկություններին ավելի լավ հաճախորդների սպասարկում ապահովել՝
                հնարավորություն տալով պատվերի ավելի արագ կատարման և պատվերի
                ավելի ճշգրիտ մշակման: Իրական ժամանակում պատվերի կարգավիճակի
                թարմացումների միջոցով ձեռնարկությունները կարող են հաճախորդներին
                տրամադրել ժամանակին և ճշգրիտ տեղեկատվություն իրենց պատվերների
                մասին՝ հանգեցնելով հաճախորդների ավելի բարձր գոհունակության:
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Որոշումների կայացման բարելավում.</b> WMS ծրագրակազմը բիզնեսին
                տրամադրում է իրական ժամանակի տվյալներ և պահեստային
                գործառնությունների վերլուծություն՝ հնարավորություն տալով ավելի
                լավ որոշումներ կայացնել: Վերլուծելով գույքագրման մակարդակի,
                պատվերի ծավալների և գործառնական կատարողականի տվյալները՝
                ձեռնարկությունները կարող են բացահայտել օրինաչափությունները,
                կայացնել տեղեկացված որոշումներ և օպտիմալացնել իրենց պահեստային
                գործառնությունները՝ բավարարելու փոփոխվող պահանջարկը:
              </p>
            </div>
            <div className="wmsImges">
              <div
                className="wmsLeft"
                style={{ backgroundImage: `url(${wms1})` }}
              ></div>
              <div
                className="wmsRight"
                style={{ backgroundImage: `url(${wms2})` }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "ru" && (
        <div className="webCont">
          <Helmet>
            <title>Программирование WMS-систем ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2>Система управления складом </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">
                  Что такое система управления складом?
                </h2>
                <p className="biTitleP">
                  WMS, или система управления складом, — это программное
                  обеспечение, которое помогает компаниям управлять ежедневными
                  складскими операциями и контролировать их с момента
                  поступления товаров и материалов в центр распределения до
                  момента их отправки. Программная система WMS является ключевым
                  компонентом управления цепочками поставок и обеспечивает обзор
                  всех запасов компании, на складах и в пути в режиме реального
                  времени. <br />
                  <br />
                  В дополнение к управлению запасами WMS предлагает инструменты
                  для процессов комплектации и упаковки, использования ресурсов,
                  аналитики и многого другого. WMS включает в себя такие
                  функции, как отслеживание запасов, управление заказами,
                  управление получением и отгрузкой, проектирование планировки
                  склада, управление трудовыми ресурсами, а также отчетность и
                  аналитику. <br />
                  <br />
                  Используя WMS, предприятия могут повысить эффективность,
                  точность и производительность своих складов, снизить
                  трудозатраты и оптимизировать использование пространства.
                  Благодаря данным и аналитике в режиме реального времени WMS
                  также может помочь предприятиям принимать обоснованные решения
                  о запасах и распределении ресурсов.
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">Что делает WMS?</h2>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Управление заказами </h2>
                  <p className="biTitleP">
                    Управление заказами WMS может управлять всем процессом
                    выполнения заказов, от получения заказов до комплектации,
                    упаковки и отгрузки. Система отслеживает статус каждого
                    заказа, обеспечивая большую прозрачность процесса выполнения
                    заказа позволяя компаниям заранее решать проблемы с
                    задержками.
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Отчеты и аналитика </h2>
                  <p className="biTitleP">
                    WMS может генерировать широкий спектр отчетов, таких как
                    отчеты о запасах, отчеты о заказах, отчеты о доставке и
                    трудозатратах. Эти отчеты могут предоставить предприятиям
                    всесторонний обзор их складских операций, позволяя им
                    выявлять тенденции, закономерности и области для улучшения.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Управление ресурсами </h2>
                  <p className="biTitleP">
                    Система может помочь предприятиям эффективно управлять
                    своими ресурсами, предоставляя оповещения в режиме реального
                    времени, когда уровень запасов приближается к минимальному
                    порогу. Кроме того, система может уведомлять пользователей,
                    когда конкретный продукт остается непроданным в течение
                    длительного периода времени, указывая на необходимость
                    оценки стратегии продаж или рассмотрения скидки на продукт.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Управление запасами </h2>
                  <p className="biTitleP">
                    WMS обеспечивает видимость уровней и местонахождения запасов
                    в режиме реального времени, обеспечивая точное и эффективное
                    управление запасами. Используя штрих-коды, метки RFID,
                    датчики или другие методы отслеживания местоположения,
                    система WMS дает представление о запасах в режиме реального
                    времени. Благодаря такой прозрачности вы можете создавать
                    более точные прогнозы спроса, реализовывать стратегию
                    своевременного инвентаризации и улучшать отслеживаемость.
                  </p>
                </div>
              </div>
            </div>

            <h2 className="biprocessh2">Преимущества WMS для бизнеса</h2>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Повышенная эффективность:</b> WMS помогает предприятиям
                оптимизировать свои складских операций, предоставляя в режиме
                реального времени информацию о уровни запасов, местоположение и
                перемещение. Эта информация позволяет предприятиям принимать
                более обоснованные решения относительно запасов размещение, сбор
                и выполнение заказов, что в конечном итоге приводит к повышение
                эффективности и производительности.
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Расширенное обслуживание клиентов:</b> WMS может помочь
                предприятиям предоставлять улучшение обслуживания клиентов за
                счет более быстрого выполнения заказов и более точная обработка
                заказа. С инвентаризацией в реальном времени отслеживание и
                обновление статуса заказа, предприятия могут предоставить
                клиентам своевременную и достоверную информацию об их заказы,
                что приводит к более высокой удовлетворенности клиентов.
              </p>
            </div>
            <div className="processing">
              <div
                className="right"
                style={{ backgroundImage: `url(${right})` }}
              ></div>
              <p className="rightP">
                <b>Лучшее принятие решений:</b> программное обеспечение WMS
                предоставляет предприятиям данные и аналитика складских операций
                в режиме реального времени, что позволяет лучшее принятие
                решений. Анализируя данные об уровне запасов, объемы заказов и
                операционные показатели, предприятия могут выявлять тенденции,
                принимать обоснованные решения и оптимизировать их складские
                операции для удовлетворения меняющегося спроса.
              </p>
            </div>
            <div className="wmsImges">
              <div
                className="wmsLeft"
                style={{ backgroundImage: `url(${wms1})` }}
              ></div>
              <div
                className="wmsRight"
                style={{ backgroundImage: `url(${wms2})` }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WMSContainer;

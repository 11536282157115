import React, { useEffect, useState } from "react";
import "./style.scss";
import img1 from "../../assets/images/about/slide3.jpg";
import img2 from "../../assets/images/about/slide2.jpg";
import back from "../../assets/images/logoC.png";
import logoFavicon from "../../assets/images/favicon.png";

import AboutDesc from "./aboutDesc";
import { Helmet } from "react-helmet";
const About = ({ selectedOption }) => {
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Մեր մասին ☎️ 091 09 09 07');
  }, []);
  return (
    <div className="aboutContainer">
  
      <div className="aboutBox">
        <section className="about">
          <h2
            className="aboutTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            {selectedOption === "en" && "About us"}
            {selectedOption === "ru" && "О нас"}
            {selectedOption === "am" && "Մեր մասին"}
          </h2>
            <Helmet>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
          {selectedOption === "en" ? (
            <div className="aboutText">
              <Helmet>
                <title>About Us ☎️ 091 09 09 07</title>
              </Helmet>
              <p className="aboutTexts"  data-aos="fade-up-right">
                Welcome to CodeWave, where we specialize in the development of
                innovative business automation systems. We understand that
                businesses today face increasing pressure to remain competitive
                in a rapidly changing marketplace. That's why our team of
                experts is committed to creating cutting-edge software solutions
                that help our clients streamline their processes, reduce costs,
                and improve efficiency. Our company was founded with the vision
                of becoming a leader in the field of business automation. We
                bring together a team of talented developers, designers, and
                project managers who work tirelessly to create software that
                meets the unique needs of each of our clients. At our core, we
                believe that technology should be a tool that helps people
                achieve their goals.
              </p>
              <img src={img1} alt="Image1"   data-aos="fade-up-left"/>
              <img src={img2} alt="Image2"   data-aos="fade-up-right"/>
              <p className="aboutTexts"  data-aos="fade-up-left">
                That's why we take a user-centered approach to software
                development, putting the needs of our clients and their
                end-users at the forefront of everything we do. We are dedicated
                to staying at the forefront of the latest technological
                advancements, and our team is constantly learning and growing to
                ensure that we remain ahead of the curve. We take pride in the
                quality of our work and are committed to delivering results that
                exceed our clients' expectations. Our goal is to help businesses
                achieve success by providing them with the tools they need to
                succeed. Thank you for considering our company for your business
                automation needs. We look forward to the opportunity to work
                with you and help your business thrive.
              </p>
            </div>
          ) : null}
          {selectedOption === "ru" ? (
            <div className="aboutText">
              <Helmet>
                <title>О нас ☎️ 091 09 09 07</title>
              </Helmet>
              <p className="aboutTexts"  data-aos="fade-up-right">
                Добро пожаловать в CodeWave, где мы специализируемся на
                разработке инновационных систем автоматизации бизнеса. Мы
                понимаем, что сегодня компании сталкиваются с растущим
                давлением, чтобы оставаться конкурентоспособными на быстро
                меняющемся рынке. Вот почему наша команда экспертов стремится
                создавать передовые программные решения, которые помогают нашим
                клиентам оптимизировать свои процессы, снижать затраты и
                повышать эффективность. Наша компания была основана с целью
                стать лидером в области автоматизации бизнеса. Мы объединяем
                команду талантливых разработчиков, дизайнеров и руководителей
                проектов, которые неустанно работают над созданием программного
                обеспечения, отвечающего уникальным потребностям каждого из
                наших клиентов. мы убеждены, что технологии должны быть
                инструментом, помогающим людям достигать своих целей.
              </p>
              <img src={img1} alt="Image1"   data-aos="fade-up-left"/>
              <img src={img2} alt="Image2"   data-aos="fade-up-right"/>
              <p className="aboutTexts"  data-aos="fade-up-left">
                Вот почему мы используем ориентированный на пользователя подход
                к разработке программного обеспечения, ставя потребности наших
                клиентов и их конечных пользователей на первое место во всем,
                что мы делаем. Мы стремимся оставаться в авангарде последних
                технологических достижений, и наша команда постоянно учится и
                растет, чтобы гарантировать, что мы остаемся на шаг впереди. Мы
                гордимся качеством нашей работы и стремимся к достижению
                результатов, превосходящих ожидания наших клиентов. Наша цель —
                помочь компаниям добиться успеха, предоставив им инструменты,
                необходимые для достижения успеха. Благодарим вас за то, что
                выбрали нашу компанию для автоматизации вашего бизнеса. Мы с
                нетерпением ждем возможности работать с вами и помочь вашему
                бизнесу процветать.
              </p>
            </div>
          ) : null}
          {selectedOption === "am" ? (
            <div className="aboutText">
              <Helmet>
                <title>Մեր մասին ☎️ 091 09 09 07</title>
              </Helmet>
              <p className="aboutTexts"  data-aos="fade-up-right">
                Բարի գալուստ CodeWave, որտեղ մենք մասնագիտացած ենք բիզնեսի
                ավտոմատացման նորարարական համակարգերի մշակման մեջ: Մենք հասկանում
                ենք, որ ընկերություններն այսօր բախվում են աճող ճնշման հետ՝ արագ
                փոփոխվող շուկայում մրցունակ մնալու համար: Ահա թե ինչու մեր
                փորձագետների թիմը ձգտում է ստեղծել առաջադեմ ծրագրային
                լուծումներ, որոնք օգնում են մեր հաճախորդներին պարզեցնել իրենց
                գործընթացները, նվազեցնել ծախսերը և բարձրացնել
                արդյունավետությունը: Մեր ընկերությունը հիմնադրվել է բիզնեսի
                ավտոմատացման ոլորտում առաջատար դառնալու նպատակով: Մենք
                համախմբում ենք տաղանդավոր ծրագրավորողների, դիզայներների և
                նախագծերի ղեկավարների, ովքեր աշխատում են՝ յուրաքանչյուր
                հաճախորդի եզակի կարիքները բավարարող ծրագրակազմ ստեղծելու համար:
                Մենք համոզված ենք, որ տեխնոլոգիան պետք է լինի գործիք, որը կօգնի
                մարդկանց հասնել իրենց նպատակներին:
              </p>
              <img src={img1} alt="Image1"   data-aos="fade-up-left"/>
              <img src={img2} alt="Image2"   data-aos="fade-up-right"/>
              <p className="aboutTexts"  data-aos="fade-up-left">
                Այդ իսկ պատճառով մենք ցուցաբերում ենք հաճախորդակենտրոն մոտեցում
                ծրագրային ապահովման մշակման հարցում՝ ամեն ինչում առաջին տեղում
                դնելով մեր հաճախորդների և նրանց վերջնական օգտագործողների
                կարիքները: Մենք ձգտում ենք մնալ տեխնոլոգիական առաջընթացների
                առաջնագծում, և մեր թիմը մշտապես սովորում և աճում է, որպեսզի մենք
                կարողանանք լինել միշտ մեկ քայլ առաջ: Մենք հպարտանում ենք մեր
                աշխատանքի որակով և ձգտում ենք հասնել մեր հաճախորդների
                սպասելիքները գերազանցող արդյունքների: Մեր նպատակն է օգնել
                ընկերություններին հաջողության հասնել՝ տալով նրանց անհրաժեշտ
                գործիքները հաջողելու համար: Շնորհակալ ենք, որ դիտարկում եք մեր
                ընկերությունը՝ ձեր բիզնեսը ավտոմատացնելու համար: Մենք անհամբեր
                սպասում ենք հնարավորությանը աշխատել ձեզ հետ և օգնել ձեզ՝ ձեր
                բիզնեսի բարգավաճման ճանապարհին:
              </p>
            </div>
          ) : null}
        </section>
      </div>
      <div className="G-flex aboutWorkContainer">
        <AboutDesc selectedOption={selectedOption} />
      </div>
    </div>
  );
};

export default About;

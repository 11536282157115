import React, { useCallback } from "react";
import "./style.scss";
import ServiceBox from "./servicesBox";
import erp from "../../assets/images/services/ERP.jpg";
import bitrix24 from "../../assets/images/bitrix24/bitrix24.png";
import web from "../../assets/images/services/web.jpg";
import automatic from "../../assets/images/services/automatic.jpg";
import wms from "../../assets/images/services/wms.jpg";
import back from "../../assets/images/logoC.png";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Services = ({ selectedOption }) => {
  const initialState = {
    showBi: false,
    showWMS: false,
    showBI: false,
    showWeb: false,
    showERP: false,
  };

  const [state, setState] = useState(initialState);

  const handleShow = useCallback((stateName) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: !prevState[stateName],
    }));
  }, []);
  const serviceDataEN = [
    {
      title: "ERP",
      description: "Enterprise resource planning system",
      infoMore:
        "Enterprise Resource Planning (ERP) systems are integrated software applications that help organizations manage their business processes and resources efficiently. These systems provide a centralized database and a suite of interconnected modules to streamline various aspects of a company's operations, including finance, human resources, manufacturing, supply chain management, customer relationship management, and more.",
      image: erp,
      handleShowMore: () => handleShow("showERP"),
      selectedOption: selectedOption,
      path: `/services/erp`,
      duration: "1000",
    },
    {
      title: "Bitrix24",
      description: "Customer relationship management system",
      infoMore:
        "Bitrix24 is a business management platform that offers a wide range of tools and features to help businesses manage all aspects of their operations from a single platform. Some of the key features of Bitrix24 include project management, customer relationship management (CRM), communication tools, HR management, and marketing and sales automation. One of the key advantages of Bitrix24 is its all-in-one approach to business management.",
      image: bitrix24,
      handleShowMore: () => handleShow("showBi"),
      selectedOption: selectedOption,
      path: `/services/bitrix24`,
      duration: "1500",
    },
    {
      title: "Web Sites",
      description: "Web design and development",
      infoMore:
        "Our website development and support services cover a wide range of needs, from basic business card sites to complex corporate portals. We work with our clients to understand their goals and requirements, and we use the latest web development technologies to create custom solutions that are tailored to their needs. Our team of experienced developers has the skills and expertise to build websites that are not only visually appealing but also highly functional and user-friendly.",
      image: web,
      handleShowMore: () => handleShow("showWeb"),
      selectedOption: selectedOption,
      path: `/services/web`,
      duration: "2000",
    },
    {
      title: "BI",
      description: "Reporting automation system",
      infoMore:
        "Our reporting and business intelligence (BI) automation solutions enable our clients to collect and analyze data in real-time, providing them with actionable insights that can inform decision-making and drive business growth. By automating the reporting process, we help our clients to reduce errors and improve data accuracy, while also increasing efficiency and reducing costs.",
      image: automatic,
      handleShowMore: () => handleShow("showBI"),
      selectedOption: selectedOption,
      path: `/services/bi`,
      duration: "2500",
    },
    {
      title: "WMS",
      description: "Warehouse management system",
      infoMore:
        "Our WMS solutions can help businesses automate inventory management, streamline order fulfillment, and improve accuracy and efficiency in warehouse operations. By optimizing warehouse management, our clients can reduce operational costs, improve customer satisfaction, and increase profitability.",
      image: wms,
      handleShowMore: () => handleShow("showWMS"),
      selectedOption: selectedOption,
      path: `/services/wms`,
      duration: "3000",
    },
  ];
  const serviceDataRu = [
    {
      title: "ERP",
      description: "Система планирования ресурсов предприятия",
      infoMore:
        "Системы планирования ресурсов предприятия (ERP) представляют собой интегрированные программные приложения, которые помогают организациям эффективно управлять своими бизнес-процессами и ресурсами. Эти системы предоставляют централизованную базу данных и набор взаимосвязанных модулей для оптимизации различных аспектов деятельности компании, включая финансы, человеческие ресурсы, производство, управление цепочками поставок, управление взаимоотношениями с клиентами и многое другое.",
      image: erp,
      handleShowMore: () => handleShow("showERP"),
      selectedOption: selectedOption,
      path: `/services/erp`,
      duration: "1000",
    },
    {
      title: "Битрикс24",
      description: "Система управления взаимоотношениями с клиентами",
      infoMore:
        "Битрикс24 — это платформа управления бизнесом, которая предлагает широкий спектр инструментов и функций, помогающих предприятиям управлять всеми аспектами своей деятельности с единой платформы. Некоторые из ключевых функций Битрикс24 включают управление проектами, управление взаимоотношениями с клиентами (CRM), инструменты коммуникации, управление персоналом, а также автоматизацию маркетинга и продаж. Одним из ключевых преимуществ Битрикс24 является комплексный подход к управлению бизнесом.",
      image: bitrix24,
      handleShowMore: () => handleShow("showBi"),
      selectedOption: selectedOption,
      duration: "1500",
      path: `/services/bitrix24`,
    },
    {
      title: "Веб-сайты",
      description: "Подготовка web сайтов",
      infoMore:
        "Наши услуги по разработке и поддержке веб-сайтов охватывают широкий спектр потребностей, от простых сайтов-визиток до сложных корпоративных порталов. Мы работаем с нашими клиентами, чтобы понять их цели и требования, и мы используем новейшие технологии веб-разработки для создания индивидуальных решений, адаптированных к их потребностям. Наша команда опытных разработчиков обладает навыками и опытом для создания веб-сайтов, которые не только визуально привлекательны, но также очень функциональны и удобны для пользователя.",
      image: web,
      handleShowMore: () => handleShow("showWeb"),
      selectedOption: selectedOption,
      duration: "2000",
      path: `/services/web`,
    },
    {
      title: "BI",
      description: "Система автоматизации отчетности, внедрение системы BI",
      infoMore:
        "Наши решения для автоматизации отчетности и бизнес-аналитики (BI) позволяют нашим клиентам собирать и анализировать данные в режиме реального времени, предоставляя им полезную информацию, которая может помочь в принятии решений и стимулировать рост бизнеса. Автоматизируя процесс отчетности, мы помогаем нашим клиентам уменьшить количество ошибок и повысить точность данных, а также повысить эффективность и сократить расходы.",
      image: automatic,
      handleShowMore: () => handleShow("showBI"),
      selectedOption: selectedOption,
      duration: "2500",
      path: `/services/bi`,
    },
    {
      title: "WMS",
      description: "Система управления складом, ввод WMS систем",
      infoMore:
        "Наши решения WMS могут помочь предприятиям автоматизировать управление запасами, упростить выполнение заказов и повысить точность и эффективность складских операций. Оптимизируя управление складом, наши клиенты могут снизить эксплуатационные расходы, повысить удовлетворенность клиентов и повысить прибыльность.",
      image: wms,
      handleShowMore: () => handleShow("showWMS"),
      selectedOption: selectedOption,
      duration: "3000",
      path: `/services/wms`,
    },
  ];

  const serviceDataAm = [
    {
      title: "ERP",
      description: "Ձեռնարկությունների ռեսուրսների պլանավորման համակարգ",
      infoMore:
        "ERP համակարգերը ինտեգրված ծրագրային հավելվածներ են,  որոնք օգնում են կազմակերպություններին արդյունավետ կառավարել իրենց բիզնես գործընթացները և ռեսուրսները:       Այս համակարգերը ապահովում են կենտրոնացված տվյալների բազա և փոխկապակցված մոդուլների փաթեթ՝ ընկերության գործունեության տարբեր ասպեկտները        պարզեցնելու համար, ներառյալ ֆինանսները,մարդկային ռեսուրսները, արտադրությունը, մատակարարման շղթայի կառավարումը հաճախորդների հետ հարաբերությունների կառավարումը և այլն:",
      image: erp,
      handleShowMore: () => handleShow("showERP"),
      path: `/services/erp`,
      duration: "1000",
      selectedOption: selectedOption,
    },
    {
      title: "Բիտրիքս24",
      description: " Հաճախորդների հետ հարաբերությունների կառավարման համակարգ",
      infoMore:
        "Bitrix24-ը բիզնեսի կառավարման հարթակ է, որն առաջարկում է գործիքների և հնարավորությունների լայն շրջանակ`օգնելու բիզնեսին կառավարել իրենց գործունեության բոլոր ասպեկտները մեկ հարթակից: Bitrix24-ի որոշ հիմնական առանձնահատկություններից են նախագծերի կառավարումը, հաճախորդների հետ հարաբերությունների կառավարումը (CRM), հաղորդակցման գործիքները, HR կառավարումը և մարքեթինգի և վաճառքի ավտոմատացումը: Bitrix24-ի հիմնական առավելություններից մեկը բիզնեսի կառավարման համընդհանուր մոտեցումն է:",
      image: bitrix24,
      handleShowMore: () => handleShow("showBi"),
      path: `/services/bitrix24`,
      duration: "1500",
      selectedOption: selectedOption,
    },
    {
      title: "Վեբ կայքեր",
      description: "Վեբ դիզայն և ծրագրավորում",
      infoMore:
        "Մեր կայքի մշակման և աջակցության ծառայությունները ներառում են կարիքների լայն շրջանակ՝ սկսած հիմնական    այցեքարտային կայքերից մինչև բարդ կորպորատիվ պորտալներ: Մենք աշխատում ենք մեր հաճախորդների հետ՝ հասկանալու նրանց     նպատակներն ու պահանջները, և օգտագործում ենք վեբ ծրագրավորման վերջին տեխնոլոգիաները՝ ստեղծելու անհատական      լուծումներ, որոնք հարմարեցված են վերջիններիս կարիքներին: Փորձառու ծրագրավորողների մեր թիմն ունի հմտություններ      և փորձ՝ ստեղծելու այնպիսի կայքեր, որոնք ոչ միայն տեսողականորեն գրավիչ են, այլև ֆունկցիոնալ են և հարմարավետ:",
      image: web,
      handleShowMore: () => handleShow("showWeb"),
      path: `/services/web`,
      duration: "2000",
      selectedOption: selectedOption,
    },
    {
      title: "BI",
      description: "Հաշվետվությունների ավտոմատացման համակարգ",
      infoMore:
        "Հաշվետվությունների ավտոմատացման և բիզնեսի անալիտիկայի(BI) համակարգերը մեր հաճախորդներին       հնարավորություն են տալիս հավաքել և վերլուծել իրական ժամանակի տվյալներ՝ նրանց տրամադրելով գործնական պատկերացումներ,       որոնք կարող են օգնել որոշումներ կայացնել և խթանել բիզնեսի աճը:       Ավտոմատացնելով հաշվետվությունների ստեղծման գործընթացը՝ մենք օգնում ենք մեր հաճախորդներին նվազեցնել սխալները և բարձրացնել        տվյալների ճշգրտությունը, ինչպես նաև բարելավել արդյունավետությունը և նվազեցնել ծախսերը:",
      image: automatic,
      handleShowMore: () => handleShow("showBI"),
      selectedOption: selectedOption,
      duration: "2500",
      path: `/services/bi`,
    },
    {
      title: "WMS",
      description: "Պահեստի կառավարման համակարգ",
      infoMore:
        "Մեր պահեստի կառավարման համակարգերը կարող են օգնել բիզնեսներին ավտոմատացնել գույքագրման կառավարումը,               պարզեցնել պատվերի կատարումը և բարձրացնել պահեստի գործառնությունների ճշգրտությունն ու արդյունավետությունը:                Պարզեցնելով պահեստի կառավարումը ՝ մեր հաճախորդները կարող են նվազեցնել գործառնական ծախսերը,                բարձրացնել հաճախորդների գոհունակությունը և բարձրացնել  շահութաբերությունը:",
      image: wms,
      handleShowMore: () => handleShow("showWMS"),
      selectedOption: selectedOption,
      duration: "3000",
      path: `/services/wms`,
    },
  ];
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Ծառայություններ ☎️ 091 09 09 07');
  }, []);
  return (
    
  
        <>
      {selectedOption === "en" && (
        <div className="ContService">
          <Helmet>
            <title>Services ☎️ 091 09 09 07</title>
          </Helmet>
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            Services
          </h2>
          <p
            className="ContServiceDesc"
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            We provide a wide range of services aimed at optimizing your
            company's business processes and improving customer interaction. Our
            team of professionals is ready to help you in the following areas:
          </p>
          <div className="servicesContainer">
            {serviceDataEN.map((item, index) => (
              <ServiceBox
                key={index}
                title={item.title}
                description={item.description}
                infoMore={item.infoMore}
                image={item.image}
                handleShowMore={item.handleShowMore}
                selectedOption={item.selectedOption}
                path={item.path}
                duration={item.duration}
              />
            ))}
          </div>
        </div>
      )}
      {selectedOption === "ru" && (
        <div className="ContService">
          <Helmet>
            <title>Услуги ☎️ 091 09 09 07</title>
          </Helmet>
          <h2
            className="ContServiceTitle "
            style={{ backgroundImage: `url(${back})` }}
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            Услуги
          </h2>
          <p
            className="ContServiceDesc"
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            Мы предоставляем широкий спектр услуг, направленных на оптимизацию
            бизнес-процессов вашей компании и улучшение взаимодействия с
            клиентами. Наша команда профессионалов готова помочь вам в следующих
            областях:
          </p>
          <div className="servicesContainer">
            {serviceDataRu.map((service, index) => (
              <ServiceBox
                key={index}
                title={service.title}
                description={service.description}
                infoMore={service.infoMore}
                image={service.image}
                handleShowMore={service.handleShowMore}
                selectedOption={service.selectedOption}
                path={service.path}
                duration={service.duration}
              />
            ))}
          </div>
        </div>
      )}
      {selectedOption === "am" && (
        <div className="ContService">
          <Helmet>
            <title>Ծառայություններ ☎️ 091 09 09 07</title>
          </Helmet>
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            Ծառայություններ
          </h2>
          <p
            className="ContServiceDesc"
            data-aos="fade-down"
            data-aos-anchor-placement="top-center"
          >
            Մենք տրամադրում ենք ծառայությունների լայն շրջանակ՝ ուղղված ձեր
            ընկերության բիզնես գործընթացների օպտիմալացմանը և հաճախորդների
            փոխգործակցության բարելավմանը: Մեր մասնագետների թիմը պատրաստ է օգնել
            ձեզ հետևյալ ոլորտներում.
          </p>
          <div className="servicesContainer">
            {serviceDataAm.map((service, index) => (
              <ServiceBox
                key={index}
                title={service.title}
                description={service.description}
                infoMore={service.infoMore}
                image={service.image}
                handleShowMore={service.handleShowMore}
                selectedOption={service.selectedOption}
                path={service.path}
                duration={service.duration}
              />
            ))}
          </div>
        </div>
      )}
   
    </>
  );
};

export default Services;

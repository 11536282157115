import React from "react";
import "./style.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from "../../assets/images/partners/p1.png";
import img2 from "../../assets/images/partners/p2.png";
import img3 from "../../assets/images/partners/p3.png";
import img4 from "../../assets/images/partners/p4.png";
import img5 from "../../assets/images/partners/p5.png";
import img6 from "../../assets/images/partners/p6.png";
import img7 from "../../assets/images/partners/p7.png";
import img8 from "../../assets/images/partners/p8.png";
import back from "../../assets/images/logoC.png";
import {Helmet} from "react-helmet";

const items = [
  {
    imageSrc: img1,
    textEn: "Yerevan Mall",
    textRu: "Ереван Молл",
    textAm: "Երևան Մոլլ",
    link:'https://www.yerevanmall.am/'
  },
  {
    imageSrc: img2,
    textEn: "Time",
    textRu: "Тайм",
    textAm: "Թայմ",
    link:'https://time.am/'
  },
  {
    imageSrc: img3,
    textEn: "Nork-Marash Medical Center",
    textRu: "Медицинский центр Норк-Мараш",
    textAm: "Նորք-Մարաշ բժշկական կենտրոն",
    link:'http://nmmc.am/'

  },
  {
    imageSrc: img4,
    textEn: " Vardanants Medical Center",
    textRu: " Медицинский центр Вардананц ",
    textAm: "Վարդանանց բժշկական կենտրոն",
    link:'https://vcim.am/'

  },
  {
    imageSrc: img5,
    textEn: "Garant Logistics",
    textRu: "Гарант Логистикс",
    textAm: "Գարանտ Լոգիստիքս",
    link:'https://garantlogistics.com/'

  },
  {
    imageSrc: img6,
    textEn: " Athenk",
    textRu: "Атенк",
    textAm: "Աթենք",
    link:'https://www.atenk.am/'

  },
  {
    imageSrc: img7,
    textEn: "Rosgosstrakh",
    textRu: "Росгосстрах",
    textAm: "Ռոսգոսստրախ",
    link:'https://www.ligainsurance.am/'

  },
  {
    imageSrc: img8,
    textEn: "Decora Group",
    textRu: "Декора Груп",
    textAm: "Դեկորա Գրուպ",
    link:'https://decoragroup.am/'

  },
];

const Partners = ({ selectedOption }) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="containerPartners"  data-aos="flip-down">
        {selectedOption === "am" && (
          
          <h2 style={{ backgroundImage: `url(${back})` }}>Մեր հաճախորդները</h2>
        )}
        {selectedOption === "ru" && (
          <h2 style={{ backgroundImage: `url(${back})` }}>Наши клиенты</h2>
        )}
        {selectedOption === "en" && (
          <h2 style={{ backgroundImage: `url(${back})` }}>Our customers</h2>
        )}
        <AliceCarousel
          items={items.map((item, i) => (
            <div className="containerPartners" key={i}>
              <img className="pImg" src={item.imageSrc} alt={item.text} />
              {selectedOption === "en" && (
                <a href={item.link} target="_blank" rel="noopener noreferrer"><p className="slide-text">{item.textEn}</p> </a>
              )}
              {selectedOption === "ru" && (
                <a href={item.link} target="_blank" rel="noopener noreferrer"><p className="slide-text">{item.textRu}</p></a>
              )}
              {selectedOption === "am" && (
                <a href={item.link} target="_blank" rel="noopener noreferrer"> <p className="slide-text">{item.textAm}</p> </a>
              )}
            </div>
          ))}
          responsive={{
            0: { items: 2 },
            400: { items: 2 },
            1024: { items: 3 },
            1100: { items: 4 },
            1500: { items: 5 },
            1900: { items: 8 },
          }}
          autoPlay
          autoPlayInterval={10}
          infinite
          animationDuration={2000}
          disableButtonsControls={true}
        />
      </div>
    </>
  );
};
export default Partners;

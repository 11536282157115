import React from 'react'
import MySvG2 from '../../../assets/images/загрузка2.svg'
import './style.scss'


const BoxStandart = () => {
  return (
    <div className="boxPrices" >
    <div className='titleBitrixPrices'>
    <div className='titleBitrix'>
<h1>Стандартный
</h1>
<h6>
Для совместной работы всей компании или рабочих групп</h6>
    </div>
     <div className='info' style={{paddingBottom:'20px'}}>
         <div style={{display:'flex', alignItems:'center', padding:'10px, 10px',fontSize: '24px',
    fontWeight: '600', minHeight:'30px'}}> 50 </div> 
         <div style={{display:'flex', alignItems:'center', fontSize:'14px'}}>пользователей</div> 
     </div>
     <div className='price'>
     <div className='discount'> 6 990 ₽/мес.</div>
     <div className='newPrice'>  5 590 ₽/мес.<div className='discN'>-20%</div></div>
         <button style={{background:'#21bdee'}}>КУПИТЬ</button>
     </div>
     <div className='mb'>100 Гб <div className='line'></div></div>
     <div className='listTarif'>
     Совместная работа  <img src={MySvG2} width="35px" height="15px" alt='img' />
     </div>
     <div className='listTarifDesc'>Чат</div>
     <div className='listTarifDesc'>Видеозвонки HD</div>
     <div className='listTarifDesc'>Календарь</div>
     <div className='listTarifDesc'>Соцсеть компании</div>
     <div className='listTarifDesc'>Новости</div>
     <div className='listTarifDesc'>База знаний</div>
     <div className='listTarif'>
     Задачи и Проекты <img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     CRM <img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     Диск<img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     Контакт-центр <img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     Сайты<img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'> Интернет-магазин<img src={MySvG2} width="35px" height="15px" alt='img'/></div>
     <div className='listTarif'> Маркетинг <img src={MySvG2} width="35px" height="15px" alt='img'/></div>
     <div className='listTarif'> Документы Онлайн <img src={MySvG2} width="35px" height="15px" alt='img'/></div>
     <div className='listTarif' style={{top:'190px'}}> Поддержка <img src={MySvG2} width="35px" height="15px" alt='img'/></div>
     <div className='listTarif' style={{top:'190px'}} > Администрирование <img src={MySvG2} width="35px" height="15px" alt='img'/></div>
     </div>






   
     
    </div>

  )
}

export default BoxStandart
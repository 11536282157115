import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { GrClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import back from "../../../assets/images/BI/BItitle.png";
import icon1 from "../../../assets/images/BI/image1.png";
import icon2 from "../../../assets/images/BI/image2.png";
import icon3 from "../../../assets/images/BI/image3.png";
import icon4 from "../../../assets/images/BI/image4.png";
import chart1 from "../../../assets/images/BI/chartRadius.jpg";
import chart2 from "../../../assets/images/BI/chart2.jpg";
import chart3 from "../../../assets/images/BI/chart3.jpg";
import { RiScales3Line } from "react-icons/ri";
import { FaCubes } from "react-icons/fa";
import { FiDatabase } from "react-icons/fi";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { IoMdArrowDropup } from "react-icons/io";
import { Helmet } from "react-helmet";

const BIContainer = ({ selectedOption, handleClose }) => {
  const refModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };

  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem('pageTitle', ' Հաշվետվությունների ավտոմատացման համակարգ (BI) ☎️ 091 09 09 07');
  }, []);
  return (
    <div ref={refModal} className="modalBoxShow">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="top70"></div>

      {selectedOption === "en" && (
        <div className="webCont">
          <Helmet>
            <title>Reporting automation system (BI) ☎️ 091 09 09 07</title>
          </Helmet>
        

          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>

                  <h2> MetaMetrica BI</h2>
                  <h2> First Armenian BI System </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">What is BI?</h2>
                <p className="biTitleP">
                  Business Intelligence (BI) system is a set of tools,
                  processes, and technologies that help businesses gather,
                  analyze, and interpret data to make informed decisions. The
                  primary goal of a BI system is to provide businesses with
                  actionable insights into their operations, customers, and
                  markets, enabling them to make data-driven decisions.
                </p>
                <br />
                <br />
                <p className="biTitleP">
                  BI systems integrate data from various sources, including
                  databases, spreadsheets, and other applications. They use data
                  visualization tools to present the data in a way that is easy
                  to understand and analyze.
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">
                Why Business Intelligence Is Important ?
              </h2>
              <p className="biTitleIconP">
                Business Intelligence (BI) refers to the tools, technologies,
                and strategies used by organizations to collect, analyze, and
                transform raw data into meaningful and useful information. BI
                provides a framework for businesses to make informed decisions
                and gain a competitive edge in their industry.
              </p>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Improved decision making</h2>
                  <p className="biTitleP">
                    Business Intelligence provides businesses with actionable
                    insights that help them make informed decisions. It helps
                    businesses identify areas where they need to improve and
                    take corrective action. BI also enables businesses to
                    forecast future trends and identify potential risks,
                    allowing them to mitigate these risks before they become a
                    problem.
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Increased Efficiency</h2>
                  <p className="biTitleP">
                    Business Intelligence can help businesses increase their
                    efficiency by automating routine tasks and reducing manual
                    labor. BI tools can automate data collection, cleaning, and
                    analysis, which saves time and reduces errors. This
                    efficiency translates into cost savings, as businesses can
                    focus on more strategic tasks.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Competitive Advantage </h2>
                  <p className="biTitleP">
                    In today's business environment, where competition is
                    fierce, businesses need to gain a competitive advantage to
                    succeed. Business Intelligence helps businesses gain a
                    competitive edge by providing them with insights into their
                    competitors' strengths and weaknesses. This information
                    allows businesses to make better decisions about pricing,
                    marketing, and product development, which can give them an
                    edge over their competitors.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Enhanced Data Analysis </h2>
                  <p className="biTitleP">
                    Analysis With Business Intelligence, businesses can analyze
                    large amounts of data from various sources, including social
                    media, customer transactions, website traffic, and more.
                    This analysis helps businesses understand their customers,
                    products, and markets better. With a better understanding of
                    the data, businesses can make informed decisions about
                    pricing, marketing, and product development.
                  </p>
                </div>
              </div>
            </div>
            <div className="processBox">
              <h2 className="biprocessh2">How the BI process works</h2>
              <div className="stepList">
                <div className="stepBox">
                  <FiDatabase
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Step 1</span>
                  <p className="biProcessP">
                    Data from source systems is integrated and loaded into a
                    data warehouse or other analytics repository
                  </p>
                </div>

                <div className="stepBox">
                  <FaCubes
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Step 2</span>
                  <p className="biProcessP">
                    Data sets are organized into analytics data models or OLAP
                    cubes to prepare them for analysis{" "}
                  </p>
                </div>

                <div className="stepBox">
                  <TbDeviceDesktopAnalytics
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Step 3</span>
                  <p className="biProcessP">
                    Data is built into data visualization, charts and reports{" "}
                  </p>
                </div>

                <div className="stepBox">
                  <RiScales3Line
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Step 4</span>
                  <p className="biProcessP">
                    Business executives and workers use the information for
                    decision-making and strategic planning
                  </p>
                </div>
              </div>
            </div>
            <h2 className="dashboardTitle">Meta Metrica</h2>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Dashboard</h2>
                <p className="biDashboardP">
                  The system consists of several sections, one of which is
                  Dashboard.
                </p>
                <br />
                <p className="biDashboardP">
                  Dashboard is a visual representation of an organization's key
                  performance indicators (KPIs) and metrics, designed to provide
                  a quick overview of the health of the business. The dashboard
                  is made up of a collection of charts, graphs, and other visual
                  elements that are designed to display data in an
                  easy-to-understand format.
                </p>
                <br />
                <p className="biDashboardP">
                  The purpose of a dashboard is to provide real-time insight
                  into business operations, allowing stakeholders to quickly
                  identify trends, patterns, and anomalies in the data. The
                  dashboard can be customized to display data from a variety of
                  sources, including databases, spreadsheets, and other data
                  repositories.
                </p>
                <br />
                <p className="biDashboardP">
                  Overall, a dashboard is a powerful tool for organizations that
                  want to monitor their performance and make data-driven
                  decisions. By providing a clear and concise view of important
                  metrics, a dashboard can help stakeholders quickly identify
                  areas that require attention and take action to improve
                  performance.
                </p>
              </div>

              <img src={chart1} alt="chart1" className="charts" />
            </div>
            <div className="biTitle">
              <img src={chart2} alt="chart2" className="charts" />
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Report Generator </h2>
                <p className="biDashboardP">
                  A report generator is a software tool used to create
                  customized reports using every single column of database. It
                  allows users to select data from various sources and generate
                  reports that can be used to analyze and visualize data in a
                  meaningful way.
                </p>
                <br />
                <p className="biDashboardP">
                  The data used for generating reports can come from various
                  sources such as databases, spreadsheets, and other
                  applications. Once the data is imported into the report
                  generator, users can manipulate the data and apply filters to
                  create custom reports.
                </p>
                <br />
                <h2 className="biDashboardh2">How it works </h2>
                <ol>
                  <li>
                    Filter your data with any parameters, it can be the
                    countries where the organization is represented, the
                    products/services offered, etc.
                  </li>
                  <li>
                    Choose any periods you want, you can also compare periods to
                    better understand the business status
                  </li>
                  <li>
                    Select necessary indicators. The system also allows to apply
                    mathematical functions to the indicators and generate the
                    obtained result in the report
                  </li>
                  <li>
                    Congrats, report is generated, despite the large amount of
                    data, this operation takes a few minutes at most .
                  </li>
                </ol>
              </div>
            </div>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Chart Generator </h2>
                <p className="biDashboardP">
                  A chart generator is a software tool used to create different
                  types of charts and graphs using every single column of
                  database. It allows users to visualize data in a meaningful
                  and easy-to-understand way, which is helpful for analyzing and
                  interpreting data trends and patterns. <br />A chart generator
                  offers a range of chart types, such as line charts, bar
                  charts, pie charts, etc. The software tool allows users to
                  input data, and then select the chart type that best
                  represents the data.
                </p>
                <br />
                <p className="biDashboardP">
                  Overall, a chart generator is an essential tool for businesses
                  that rely on data analysis to make informed decisions. It
                  simplifies the process of visualizing data, making it easier
                  to communicate insights to others and improve decision-making.
                </p>
                <br />
                <h2 className="biDashboardh2">How it works </h2>
                <ol>
                  <li>Choose the type of chart that you want to create.</li>
                  <li>Organize your data into charts</li>
                  <li>
                    Once you are satisfied with your chart, export it to a file
                    format that is suitable for your needs, such as PDF, Excel,
                    or a graphical image. Alternatively, save the report or
                    chart within the tool for future use.
                  </li>
                </ol>
              </div>
              <img src={chart3} alt="chart3" className="charts" />
            </div>
            <h2 className="finishText">
              In conclusion, Business Intelligence is crucial for businesses
              that want to succeed in today's competitive environment. It
              provides businesses with insights into their customers, products,
              and markets, enabling them to make informed decisions and gain a
              competitive edge. By leveraging Business Intelligence tools and
              strategies, businesses can improve their efficiency, customer
              satisfaction, and profitability.
            </h2>
          </div>
        </div>
      )}

      {selectedOption === "ru" && (
        <div className="webCont">
          <Helmet>
            <title>
            Система автоматизации отчетности, внедрение системы BI ☎️ 091 09 09 07
            </title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Мета Метрика BI</h2>
                  <h2> Первая армянская BI-система</h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">Что такое BI?</h2>
                <p className="biTitleP">
                  Система Business Intelligence (BI) представляет собой набор
                  инструментов, процессов и технологий, которые помогают
                  предприятиям собирать, анализировать и интерпретировать данные
                  для принятия обоснованных решений. Основная цель системы BI —
                  предоставить бизнесу действенное понимание своей деятельности,
                  клиентов и рынки, позволяя им принимать решения на основе
                  данных.
                </p>
                <br />
                <br />
                <p className="biTitleP">
                  BI-системы интегрируют данные из различных источников, в том
                  числе из баз данных, электронных таблицы и других приложений.
                  Они используют инструменты визуализации для удобного и
                  понятного представления данных для анализирования
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">
                Почему Business Intelligence (BI) важна?
              </h2>
              <p className="biTitleIconP">
                Business Intelligence (BI)относится к инструментам, технологиям,
                и стратегиям, используемым организациями для сбора, анализа и
                преобразования необработанных данные в значимую и полезную
                информацию. БИ позволяет бизнесу принимать обоснованные решения
                и получить конкурентное преимущество в своей отрасли.
              </p>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Улучшенное принятие решений</h2>
                  <p className="biTitleP">
                    BI система предоставляет компаниям полезную информацию,
                    которая помогает им принимать обоснованные решения. Это
                    помогает компаниям определить области, в которых они
                    нуждаются в улучшении, и предпринять корректирующие
                    действия. BI также позволяет компаниям прогнозировать
                    будущие тенденции и выявлять потенциальные риски, позволяя
                    им снижать эти риски до того, как они станут проблемой.
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Повышенная эффективность</h2>
                  <p className="biTitleP">
                    BI система может помочь предприятиям повысить свою
                    эффективность за счет автоматизации рутинных задач и
                    сокращения ручного труда. Инструменты BI могут
                    автоматизировать сбор и анализ данных, что экономит время и
                    снижает количество ошибок. Эта эффективность приводит к
                    экономии средств, поскольку предприятия могут
                    сосредоточиться на более стратегических задачах.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Конкурентное преимущество</h2>
                  <p className="biTitleP">
                    В современной бизнес-среде, где конкуренция является
                    жесткой, компаниям необходимо получить конкурентное
                    преимущество, чтобы добиться успеха. BI система помогает
                    предприятиям получить конкурентное преимущество,
                    предоставляя им информацию о сильных и слабых сторонах
                    конкурентов. Эта информация позволяет компаниям принимать
                    более обоснованные решения о ценообразовании, маркетинге и
                    разработке продуктов, что может дать им преимущество перед
                    конкурентами.
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Расширенный анализ данных </h2>
                  <p className="biTitleP">
                    BI позволяет компаниям выполнять обширный анализ огромных
                    объемов данных из различных источников, таких как социальные
                    сети, транзакции клиентов, трафик веб-сайтов и другие. Этот
                    аналитический процесс помогает компаниям получить более
                    глубокое представление о своих клиентах, продуктах и рынках.
                    Благодаря более эффективному пониманию данных предприятия
                    могут принимать обоснованные решения в отношении
                    ценообразования, маркетинговых стратегий и разработки
                    продуктов.
                  </p>
                </div>
              </div>
            </div>
            <div className="processBox">
              <h2 className="biprocessh2">Как работает процесс BI</h2>
              <div className="stepList">
                <div className="stepBox">
                  <FiDatabase
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Шаг 1</span>
                  <p className="biProcessP">
                    Данные из исходных систем интегрируются и загружаются в
                    хранилище данных или аналитический репозиторий.{" "}
                  </p>
                </div>

                <div className="stepBox">
                  <FaCubes
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Шаг 2</span>
                  <p className="biProcessP">
                    Наборы данных организововаются в аналитические модели данных
                    или OLAP кубы для подготовки их к анализу.
                  </p>
                </div>

                <div className="stepBox">
                  <TbDeviceDesktopAnalytics
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Шаг 3</span>
                  <p className="biProcessP">
                    Данные выстраиваются в визуализацию данных, диаграммы и
                    отчеты.
                  </p>
                </div>

                <div className="stepBox">
                  <RiScales3Line
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Шаг 4</span>
                  <p className="biProcessP">
                    Руководители предприятий и рабочие используют информацию для
                    принятия решений и стратегического планирования.{" "}
                  </p>
                </div>
              </div>
            </div>
            <h2 className="dashboardTitle"> Мета Метрика</h2>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Информационная панель (Dashboard)</h2>
                <p className="biDashboardP">
                  Система состоит из нескольких разделов, один из которых
                  Информационная панель(Dashboard)
                </p>
                <br />
                <p className="biDashboardP">
                  Информационная панель — это визуальное представление ключевых
                  показателей эффективности (KPI) и метрик организации,
                  предназначенное для быстрого обзора состояния бизнеса. Панель
                  мониторинга состоит из набора диаграмм, графиков и других
                  визуальных элементов, предназначенных для отображения данных в
                  удобном для понимания формате.
                </p>
                <br />
                <p className="biDashboardP">
                  Цель информационной панели — предоставить информацию о
                  бизнес-операциях в режиме реального времени, позволяя
                  заинтересованным сторонам быстро выявлять тенденции,
                  закономерности и аномалии в данных. Панель мониторинга можно
                  настроить для отображения данных из различных источников,
                  включая базы данных, электронные таблицы и другие хранилища
                  данных.
                </p>
                <br />
                <p className="biDashboardP">
                  В целом, информационная панель — это мощный инструмент для
                  организаций, которые хотят отслеживать свою производительность
                  и принимать решения на основе данных. Предоставляя четкое и
                  краткое представление важных показателей, панель мониторинга
                  может помочь заинтересованным сторонам быстро определить
                  области, требующие внимания, и принять меры для повышения
                  производительности.
                </p>
              </div>

              <img src={chart1} alt="chart1" className="charts" />
            </div>
            <div className="biTitle">
              <img src={chart2} alt="chart2" className="charts" />
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Генератор отчетов </h2>
                <p className="biDashboardP">
                  Генератор отчетов — это программный инструмент, используемый
                  для создания настраиваемых отчетов с использованием любых
                  данных из базы. Это позволяет пользователям выбирать данные из
                  различных источников и создавать отчеты, которые можно
                  использовать для анализа и визуализации данных осмысленным
                  образом.
                </p>
                <br />
                <p className="biDashboardP">
                  Данные, используемые для создания отчетов, могут поступать из
                  различных источников, таких как базы данных, электронные
                  таблицы и другие приложения. После импорта данных в генератор
                  отчетов пользователи могут манипулировать данными и применять
                  фильтры для создания настраиваемых отчетов.
                </p>
                <br />
                <h2 className="biDashboardh2">Как это работает </h2>
                <ol>
                  <li>
                    Фильтруйте свои данные по любым параметрам, это могут быть
                    страны, в которых представлена организация, предлагаемые
                    товары/услуги и т.д.
                  </li>
                  <li>
                    Выберите любые периоды, которые вы хотите, вы также можете
                    сравнить периоды, чтобы лучше понять бизнес-статус
                  </li>
                  <li>
                    Выберите необходимые индикаторы. Система также позволяет
                    применять математические функции к показателям и формировать
                    полученный результат в отчете.
                  </li>
                  <li>
                    Вот и все, отчет сформирован, несмотря на большой объем
                    данных, эта операция занимает максимум несколько минут.
                  </li>
                </ol>
              </div>
            </div>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Генератор диаграмм</h2>
                <p className="biDashboardP">
                  Генератор диаграмм — это программный инструмент, используемый
                  для создания различных типов диаграмм и графиков с
                  использованием любых данных из базы. Это позволяет
                  пользователям визуализировать данные осмысленным и простым для
                  понимания способом, что полезно для анализа и интерпретации
                  тенденций и закономерностей данных. <br />
                  Генератор диаграмм предлагает ряд типов диаграмм, таких как
                  линейные диаграммы, гистограммы, круговые диаграммы и т. д.
                  Программный инструмент позволяет пользователям вводить данные,
                  а затем выбирать тип диаграммы, который лучше всего
                  представляет данные.
                </p>
                <br />
                <p className="biDashboardP">
                  В целом, генератор диаграмм является важным инструментом для
                  предприятий, которые полагаются на анализ данных для принятия
                  обоснованных решений. Это упрощает процесс визуализации
                  данных, упрощая обмен информацией с другими и улучшая процесс
                  принятия решений.
                </p>
                <br />
                <h2 className="biDashboardh2">Как это работает </h2>
                <ol>
                  <li>Выберите тип диаграммы, которую вы хотите создать.</li>
                  <li>Организуйте свои данные в виде диаграмм</li>
                  <li>
                    Как только вы будете удовлетворены своей диаграммой,
                    экспортируйте ее в формат файла, который подходит для ваших
                    нужд, например PDF, Excel или графическое изображение. Либо
                    сохраните отчет или диаграмму в инструменте для
                    использования в будущем. диаграмма
                  </li>
                </ol>
              </div>
              <img src={chart3} alt="chart3" className="charts" />
            </div>
            <h2 className="finishText">
              В заключение, бизнес-аналитика имеет решающее значение для
              предприятий, которые хотят добиться успеха в современной
              конкурентной среде. Он предоставляет компаниям информацию о своих
              клиентах, продуктах и рынках, что позволяет им принимать
              обоснованные решения и получать конкурентные преимущества.
              Используя инструменты и стратегии Business Intelligence,
              предприятия могут повысить свою эффективность, удовлетворенность
              клиентов и прибыльность.
            </h2>
          </div>
        </div>
      )}

      {selectedOption === "am" && (
        <div className="webCont">
          <Helmet>
            <title>
            Հաշվետվությունների ավտոմատացման համակարգ (BI) ☎️ 091 09 09 07
            </title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> MetaMetrica BI</h2>
                  <h2> Առաջին հայկական BI համակարգը</h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">Ի՞նչ է BI-ը:</h2>
                <p className="biTitleP">
                  Business Intelligence (BI) համակարգը գործիքների գործընթացների
                  և տեխնոլոգիաների միասնություն է, որոնք օգնում են բիզնեսին
                  հավաքել և վերլուծել տվյալները՝ տեղեկացված որոշումներ
                  կայացնելու համար: BI համակարգի առաջնային նպատակը բիզնեսին
                  ապահովելն է գործնական պատկերացումներով՝ իրենց գործունեության,
                  հաճախորդների և շուկայի մասին՝ հնարավորություն տալով
                  վերջիններիս, կայացնել տվյալների վրա հիմնված որոշումներ:
                </p>
                <br />
                <br />
                <p className="biTitleP">
                  BI համակարգերը միավորում են տվյալները տարբեր աղբյուրներից, այդ
                  թվում տվյալների բազաներից, աղյուսակներից և այլ հավելվածներից:
                  Համակարգը օգտագործում է տվյալների վիզուալիզացիայի գործիքներ՝
                  տվյալները հասկանալի և վերլուծելի կերպով ներկայացնելու համար.
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2">
                BI համակարգի կարևորությունը բիզնեսի համար
              </h2>
              <p className="biTitleIconP">
                Business Intelligence (BI) դասվում է այն գործիքների,
                տեխնոլոգիաների, ռազմավարությունների դասին, որոնք օգտագործվում են
                կազմակերպությունների կողմից՝ հավաքելու, վերլուծելու և չմշակված
                տվյալները՝ իմաստալից և օգտակար տեղեկատվության վերածելու համար:
                BI-ը ապահովում է բիզնեսի համար տեղեկացված որոշումներ կայացնելու
                շրջանակ և թույլ է տալիս ձեռք բերել մրցակցային առավելություն
                իրենց ոլորտում:
              </p>
            </div>
            <div className="iconList">
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon1})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Որոշումների կայացման բարելավում</h2>
                  <p className="biTitleP">
                    BI համակարգը բիզնեսին տալիս է գործունակ պատկերացումներ,
                    որոնք օգնում են նրանց տեղեկացված որոշումներ կայացնել:
                    Տեխնոլոգիան օգնում է ձեռնարկություններին բացահայտել այն
                    ոլորտները, որտեղ անհրաժեշտ է բարելավումներ իրականացնել, BI-ը
                    նաև հնարավորություն է տալիս բիզնեսին կանխատեսել ապագա
                    տենդենցները և բացահայտել հնարավոր ռիսկերը՝ նախքան դրանք
                    կդառնան խնդիրներ.
                  </p>
                </div>
              </div>

              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon2})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Արդյունավետության բարձրացում</h2>
                  <p className="biTitleP">
                    BI համակարգը կարող է օգնել բիզնեսին բարձրացնել
                    արդյունավետությունը՝ ավտոմատացնելով կրկնվող առաջադրանքները և
                    նվազեցնելով մանուալ աշխատանքը: BI գործիքները կարող են
                    ավտոմատացնել տվյալների հավաքագրումը, մշակումը և
                    վերլուծությունը, ինչը խնայում է ժամանակը և նվազեցնում
                    սխալները: Այս արդյունավետությունը վերածվում է ծախսերի
                    խնայողության, քանի որ ձեռնարկությունները կարող են
                    կենտրոնանալ ավելի ռազմավարական խնդիրների վրա:
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon3})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">Մրցակցային առավելություն</h2>
                  <p className="biTitleP">
                    Այսօրվա բիզնես միջավայրում, որտեղ մրցակցությունը կատաղի է,
                    բիզնեսը պետք է մրցակցային առավելություն ունենա հաջողության
                    հասնելու համար: BI-ը օգնում է բիզնեսին ձեռք բերել մրցակցային
                    առավելություն՝ տրամադրելով վերջիններիս պատկերացումներ իրենց
                    մրցակիցների ուժեղ և թույլ կողմերի մասին: Այս
                    տեղեկատվությունը բիզնեսին թույլ է տալիս ավելի լավ որոշումներ
                    կայացնել գնագոյացման, շուկայավարման և արտադրանքի զարգացման
                    վերաբերյալ, ինչը կարող է առավելություն տալ իրենց
                    մրցակիցներից:
                  </p>
                </div>
              </div>
              <div className="biIcon">
                <div
                  className="biIconImg"
                  style={{ backgroundImage: `url(${icon4})` }}
                ></div>
                <div className="biIconText">
                  <h2 className="biTitleh2">
                    Ընդլայնված տվյալների վերլուծություն
                  </h2>
                  <p className="biTitleP">
                    Business Intelligence-ի միջոցով ձեռնարկությունները կարող են
                    վերլուծել մեծ քանակությամբ տվյալներ տարբեր աղբյուրներից,
                    ներառյալ սոցիալական ցանցերը, հաճախորդների գործարքները, վեբ
                    կայքի տրաֆիկը և այլն: Այս վերլուծությունն օգնում է
                    ձեռնարկություններին ավելի լավ հասկանալ իրենց հաճախորդներին,
                    ապրանքների և շուկայի տենդենցները: Տվյալների ավելի լավ
                    հասկանալու դեպքում ձեռնարկությունները կարող են տեղեկացված
                    որոշումներ կայացնել շուկայական ռազմավարության և արտադրանքի
                    զարգացման վերաբերյալ:
                  </p>
                </div>
              </div>
            </div>
            <div className="processBox">
              <h2 className="biprocessh2">Ինչպես է աշխատում BI գործընթացը</h2>
              <div className="stepList">
                <div className="stepBox">
                  <FiDatabase
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Քայլ 1</span>
                  <p className="biProcessP">
                    Համակարգի աղբյուրների տվյալները ինտեգրվում և բեռնվում են
                    տվյալների պահեստում կամ վերլուծական պահոցում
                  </p>
                </div>

                <div className="stepBox">
                  <FaCubes
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Քայլ 2</span>
                  <p className="biProcessP">
                    Տվյալների հավաքածուները կազմավորվում են վերլուծական
                    տվյալների մոդելների կամ OLAP խորանարդների մեջ՝ դրանք
                    վերլուծության պատրաստելու համար
                  </p>
                </div>

                <div className="stepBox">
                  <TbDeviceDesktopAnalytics
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Քայլ 3</span>
                  <p className="biProcessP">
                    Տվյալները ներկառուցվում են տվյալների արտացոլման գործիքների,
                    գծապատկերների և հաշվետվությունների մեջ
                  </p>
                </div>

                <div className="stepBox">
                  <RiScales3Line
                    style={{
                      width: "100%",
                      fontSize: "100px",
                      color: "#b2b2b2",
                    }}
                  />
                  <span>Քայլ 4</span>
                  <p className="biProcessP">
                    Բիզնեսի ղեկավարներն ու աշխատակիցները օգտագործում են
                    տեղեկատվությունը որոշումների կայացման և ռազմավարական
                    պլանավորման համար
                  </p>
                </div>
              </div>
            </div>
            <h2 className="dashboardTitle">Meta Metrica</h2>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Վահանակ</h2>
                <p className="biDashboardP">
                  Համակարգը բաղկացած է մի քանի բաժիններից, որոնցից մեկը
                  Dashboard-ն է:
                </p>
                <br />
                <p className="biDashboardP">
                  Dashboard-ը կազմակերպության արտադրողականության հիմանական
                  ցուցիչների (KPIs) և չափումների տեսողական ներկայացումն է, որը
                  նախատեսված է բիզնեսի կարգավիճակի արագ ակնարկ ապահովելու համար:
                  Վահանակը կազմված է գծապատկերներից, որոնք նախատեսված են
                  տվյալները հեշտ և հասկանալի ձևաչափով ցուցադրելու համար:
                </p>
                <br />
                <p className="biDashboardP">
                  Վահանակի նպատակն է իրական ժամանակում պատկերացում տրամադրել
                  բիզնեսի գործունեության վերաբերյալ՝ թույլ տալով շահագրգիռ
                  կողմերին արագ բացահայտել շուկայի տենդենցները,
                  օրինաչափությունները և տվյալների անոմալիաները: Վահանակը կարող է
                  հարմարեցվել՝ ցուցադրելու տվյալները տարբեր աղբյուրներից, այդ
                  թվում՝ տվյալների բազաներից, աղյուսակներից և տվյալների այլ
                  պահոցներից:
                </p>
                <br />
                <p className="biDashboardP">
                  Ընդհանուր առմամբ, վահանակը հզոր գործիք է այն
                  կազմակերպությունների համար, որոնք ցանկանում են վերահսկել իրենց
                  կատարողականը և կայացնել տվյալների վրա հիմնված որոշումներ:
                  Կարևոր չափումների վերաբերյալ հստակ և հակիրճ պատկերացում
                  տրամադրելով՝ վահանակը կարող է օգնել արագ բացահայտել այն
                  ոլորտները, որոնք ուշադրության կարիք ունեն և քայլեր ձեռնարկել
                  աշխատանքը բարելավելու համար:
                </p>
              </div>

              <img src={chart1} alt="chart1" className="charts" />
            </div>
            <div className="biTitle">
              <img src={chart2} alt="chart2" className="charts" />
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Հաշվետվությունների գեներատոր </h2>
                <p className="biDashboardP">
                  Հաշվետվությունների գեներատորը ծրագրային գործիք է, որն
                  օգտագործվում է անհատականացված հաշվետվություններ ստեղծելու
                  համար՝ օգտագործելով տվյալների բազայի ցանկացած տվյալ: Այն թույլ
                  է տալիս օգտվողներին ընտրել տվյալներ տարբեր աղբյուրներից և
                  ստեղծել հաշվետվություններ, որոնք կարող են օգտագործվել
                  տվյալների իմաստալից կերպով վերլուծելու և պատկերային դարձնելու
                  համար:
                </p>
                <br />
                <p className="biDashboardP">
                  Հաշվետվությունների ստեղծման համար օգտագործվող տվյալները կարող
                  են ստացվել տարբեր աղբյուրներից, ինչպիսիք են տվյալների
                  բազաները, աղյուսակները և այլ աղբյուրներ: Երբ տվյալները
                  ներմուծվում են հաշվետվության գեներատոր, օգտվողները կարող են
                  մշակել տվյալները և կիրառել զտիչներ՝ անհատական
                  հաշվետվություններ ստեղծելու համար:
                </p>
                <br />
                <h2 className="biDashboardh2">Ինչպես է այն աշխատում </h2>
                <ol>
                  <li>
                    Զտեք ձեր տվյալները ցանկացած պարամետրով, դա կարող է լինել այն
                    երկրները, որտեղ ներկայացված է կազմակերպությունը, առաջարկվող
                    ապրանքները/ծառայությունները և այլն։
                  </li>
                  <li>
                    Ընտրեք ցանկացած ժամանակաշրջան, որը ցանկանում եք, կարող եք
                    նաև համեմատել ժամանակահատվածները՝ բիզնեսի կարգավիճակն ավելի
                    լավ հասկանալու համար
                  </li>
                  <li>
                    Ընտրեք անհրաժեշտ ցուցանիշները: Համակարգը թույլ է տալիս նաև
                    մաթեմատիկական ֆունկցիաներ կիրառել ցուցիչների վրա և
                    գեներացնել ստացված արդյունքը հաշվետվության մեջ
                  </li>
                  <li>
                    Ահա և վերջ, հաշվետվությունը ստեղծված է, չնայած տվյալների մեծ
                    քանակին, այս գործողությունը տևում է առավելագույնը մի քանի
                    րոպե:
                  </li>
                </ol>
              </div>
            </div>
            <div className="biTitle">
              <div className="bidashboardText">
                <h2 className="biDashboardh2">Գծապատկերների գեներատոր </h2>
                <p className="biDashboardP">
                  Գծապատկերների գեներատորը ծրագրային գործիք է, որն օգտագործվում
                  է տարբեր տեսակի գծապատկերներ ստեղծելու համար՝ օգտագործելով
                  տվյալների բազայի յուրաքանչյուր վյալ: Այն թույլ է տալիս
                  օգտվողներին վիզուալիզացնել տվյալները իմաստալից և պարզ կերպով,
                  որն օգտակար է տվյալների օրինաչափությունների վերլուծության և
                  մեկնաբանման համար: Գծապատկերների գեներատորն առաջարկում է
                  գծապատկերների մի շարք տեսակներ, ինչպիսիք են գծային,
                  սյունակային, շրջանաձև դիագրամները և այլն: Ծրագրային գործիքը
                  թույլ է տալիս օգտվողներին մուտքագրել տվյալներ, այնուհետև
                  ընտրել գծապատկերների տեսակը, որը լավագույնս ներկայացնում է
                  տվյալները:
                </p>
                <br />
                <p className="biDashboardP">
                  Ընդհանուր առմամբ, գծապատկերների գեներատորը կարևոր գործիք է
                  ձեռնարկությունների համար, որոնք հիմնվում են տվյալների
                  վերլուծության վրա՝ տեղեկացված որոշումներ կայացնելու համար: Այն
                  հեշտացնում է տվյալների վիզուալիզացման գործընթացը՝ թույլ տալով
                  ավելի ճշգրիտ պատկերացում կազմել բիզնեսի կարգավիճակի մասին:
                </p>
                <br />
                <h2 className="biDashboardh2">Ինչպես է այն աշխատում </h2>
                <ol>
                  <li>Ընտրեք գծապատկերի տեսակը, որը ցանկանում եք ստեղծել:</li>
                  <li>Կարգավորեք ձեր տվյալները գծապատկերների մեջ:</li>
                  <li>
                    Երբ գծապետկերը ստեղծված է, արտահանեք ստացված արդյունքը PDF,
                    Excel ձևաչափերով կամ իբրև գրաֆիկական պատկեր: Որպես
                    այլընտրանք, պահպանեք զեկույցը կամ գծապատկերը ծրագրում՝ ապագա
                    օգտագործման համար:
                  </li>
                </ol>
              </div>
              <img src={chart3} alt="chart3" className="charts" />
            </div>
            <h2 className="finishText">
              Եզրափակելով, Business Intelligence-ը շատ կարևոր է այն բիզնեսների
              համար, որոնք ցանկանում են հաջողության հասնել այսօրվա մրցակցային
              միջավայրում: Այն բիզնեսներին տրամադրում է պատկերացումներ իրենց
              հաճախորդների, ապրանքների և շուկաների վերաբերյալ՝ հնարավորություն
              տալով վերջիններիս կայացնել տեղեկացված որոշումներ և ձեռք բերել
              մրցակցային առավելություն: Օգտագործելով BI գործիքներն ու
              ռազմավարությունները՝ ձեռնարկությունները կարող են բարելավել իրենց
              արդյունավետությունը, հաճախորդների գոհունակությունը և
              շահութաբերությունը:
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default BIContainer;

import React, { useEffect } from "react";
import "./style.scss";
import TeamBox from "./teamBox";
import men1 from "../../assets/images/team/men1.jpg";
import men2 from "../../assets/images/team/men2.jpg";
import women1 from "../../assets/images/team/women1.jpg";
import women2 from "../../assets/images/team/women2.jpg";
import back from "../../assets/images/logoC.png";
import background1 from "../../assets/images/working/work1.jpg";
import publish from "../../assets/images/working/work3.jpg";
import start from "../../assets/images/working/work2.jpg";
import work4 from "../../assets/images/working/work4.jpg";
import work5 from "../../assets/images/working/work5.jpg";
import { Fade } from "react-awesome-reveal";
import "../about/aboutDesc/style.scss";
import { Helmet } from "react-helmet";

const Team = ({ selectedOption }) => {
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Մեր թիմը ☎️ 091 09 09 07');
  }, []);
  return (
    <>
    
      {selectedOption === "en" && (
        <div className="teamContainer ">
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Team
          </h2>
          <p className="ContServiceDesc">
            Along with many years of experience, we got to know different
            professionals with whom we were able to work in a number of areas
            and expand the variety of areas of the company's activity. The key
            to our success is that we help companies keep pace with the times.
          </p>
          <div className="teamList">
            <Fade direction="left" duration={2000}>
              <TeamBox
                teamImg={men1}
                tName="John Smith"
                staff="Backend Developer"
              />
            </Fade>
            <Fade direction="left" duration={1000}>
              <TeamBox
                teamImg={women1}
                tName="Zara Smith"
                staff="Backend Developer"
              />
            </Fade>
            <Fade direction="right" duration={1000}>
              <TeamBox
                teamImg={men2}
                tName="John Walker"
                staff="Frontend Developer"
              />{" "}
            </Fade>
            <Fade direction="right" duration={2000}>
              <TeamBox
                teamImg={women2}
                tName="Larisa Lizanina"
                staff="Backend Developer"
              />{" "}
            </Fade>
          </div>
        </div>
      )}
      {selectedOption === "ru" && (
        <div className="teamContainer ">
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Команда
          </h2>
          <p className="ContServiceDesc">
            {" "}
            Наряду с многолетним опытом мы познакомились с разными
            профессионалами, с которыми мы смогли работать в ряде областей и
            расширить разнообразие областей деятельности компании. Ключом к
            нашему успеху является то, что мы помогаем предприятиям идти в ногу
            со временем.
          </p>
          <div className="teamList">
            <Fade direction="left" duration={1000}>
              <TeamBox
                teamImg={men1}
                tName="Джон Смит"
                staff="Backend програмист"
              />
            </Fade>
            <Fade direction="left" duration={1000}>
              <TeamBox
                teamImg={women1}
                tName="Zara Smith"
                staff="Backend Developer"
              />
            </Fade>
            <Fade direction="right" duration={1000}>
              <TeamBox
                teamImg={men2}
                tName="John Walker"
                staff="Frontend Developer"
              />{" "}
            </Fade>
            <Fade direction="right" duration={1000}>
              <TeamBox
                teamImg={women2}
                tName="Larisa Lizanina"
                staff="Backend Developer"
              />{" "}
            </Fade>
          </div>
        </div>
      )}
      {selectedOption === "am" && (
        <div className="teamContainer ">
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Մեր թիմը
          </h2>
          <p className="ContServiceDesc">
            {" "}
            Տարիների փորձի հետ մեկտեղ մենք հանդիպել ենք տարբեր պրոֆեսիոնալների,
            որոնց հետ աշխատելը հնարավորություն է տվել հանդես գալ մի շարք
            ոլորտներում և ընդլայնել ընկերության ոլորտների բազմազանությունը։ Մեր
            հաջողության գրավականն այն է, որ մենք աջակցում ենք բիզնեսին քայլել
            ժամանակին համընթաց։
          </p>
          <div className="teamList">
            <Fade direction="left" duration={1000}>
              <TeamBox
                teamImg={men1}
                tName="Ջոն Սմիթ"
                staff="Frontend ծրագրավորող"
              />
            </Fade>
            <Fade direction="left" duration={1000}>
              <TeamBox
                teamImg={women1}
                tName="Zara Smith"
                staff="Backend Developer"
              />
            </Fade>
            <Fade direction="right" duration={1000}>
              <TeamBox
                teamImg={men2}
                tName="John Walker"
                staff="Frontend Developer"
              />
            </Fade>
            <Fade direction="right" duration={1000}>
              <TeamBox
                teamImg={women2}
                tName="Larisa Lizanina"
                staff="Backend Developer"
              />
            </Fade>
          </div>
        </div>
      )}
      <div className="top70"></div>
      <h2 className="aboutTitle" style={{ backgroundImage: `url(${back})` }}>
        {selectedOption === "en" && "How We Work"}
        {selectedOption === "ru" && "Как мы работаем"}
        {selectedOption === "am" && "Ինչպես ենք մենք աշխատում"}
      </h2>
      {selectedOption === "en" && (
        <div className="G-flex workList">
        <Helmet>
        <title>Team ☎️ 091 09 09 07</title>
      </Helmet>
          <div className="workBox" data-aos="fade-up"
     data-aos-duration="1000">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${background1})` }}
            >
              <span className="workImgSpan1">01</span>
            </div>
            <h2>Requirements Analysis</h2>
            <p>
              At Codewave, we start every project by analyzing our clients'
              requirements in detail. This involves understanding their business
              processes, identifying pain points, and determining how automation
              can help them achieve their goals.
            </p>
          </div>
          <div className="workBox" data-aos-duration="1500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${start})` }}
            >
              <span className="workImgSpan2">02</span>
            </div>
            <h2>Planning</h2>
            <p>
              {" "}
              Once we have a clear understanding of our clients' requirements,
              we work with them to develop a plan that outlines the scope of the
              project, the timeline, and the resources needed. This helps us
              ensure that the project is completed on time and within budget.
            </p>
          </div>
          <div className="workBox" data-aos-duration="2000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${publish})` }}
            >
              <span className="workImgSpan3">03</span>
            </div>
            <h2>Development and Implementation</h2>
            <p>
              Our team of experts then begins the development and implementation
              phase, which involves coding, testing, and deploying the
              automation system. We use the latest technologies and best
              practices to ensure that the system is scalable, secure, and easy
              to use.
            </p>
          </div>
          <div className="workBox" data-aos-duration="2500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work4})` }}
            >
              <span className="workImgSpan2">04</span>
            </div>
            <h2>Data Migration</h2>
            <p>
              {" "}
              If our clients are migrating from an existing system to the new
              automation system, we handle the migration of data in a secure and
              efficient manner. This ensures that no data is lost or corrupted
              during the migration process.{" "}
            </p>
          </div>
          <div className="workBox" data-aos-duration="3000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work5})` }}
            >
              <span className="workImgSpan1">05</span>
            </div>
            <h2>Support</h2>
            <p>
              After the system is built and deployed, we provide ongoing support
              to ensure that it runs smoothly and efficiently. This includes
              monitoring the system for issues, providing updates and upgrades,
              and addressing any concerns or questions our clients may have.
            </p>
          </div>
        </div>
      )}
      {selectedOption === "ru" && (
        <div className="G-flex workList">
        <Helmet>
        <title>Команда ☎️ 091 09 09 07</title>
      </Helmet>
          <div className="workBox" data-aos-duration="1000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${background1})` }}
            >
              <span className="workImgSpan1">01</span>
            </div>
            <h2>Анализ требований</h2>
            <p>
              В Codewave мы начинаем каждый проект с подробного анализа
              требований наших клиентов. Это включает в себя понимание их
              бизнес-процессов, определение болевых точек и определение того,
              как автоматизация может помочь им в достижении их целей.
            </p>
          </div>
          <div className="workBox" data-aos-duration="1500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${start})` }}
            >
              <span className="workImgSpan2">02</span>
            </div>
            <h2>Планирование</h2>
            <p>
              Когда у нас есть четкое представление о требованиях наших
              клиентов, мы работаем с ними над разработкой плана, в котором
              указаны объем проекта, сроки и необходимые ресурсы. Это помогает
              нам гарантировать, что проект будет завершен вовремя и в рамках
              бюджета.
            </p>
          </div>
          <div className="workBox" data-aos-duration="2000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${publish})` }}
            >
              <span className="workImgSpan3">03</span>
            </div>
            <h2>Разработка и внедрение</h2>
            <p>
              Затем наша команда экспертов приступает к этапу разработки и
              внедрения, который включает кодирование, тестирование и
              развертывание системы автоматизации. Мы используем новейшие
              технологии и лучшие практики, чтобы обеспечить масштабируемость,
              безопасность и простоту использования системы.
            </p>
          </div>
          <div className="workBox" data-aos-duration="2500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work4})` }}
            >
              <span className="workImgSpan2">04</span>
            </div>
            <h2>Миграция данных</h2>
            <p>
              Если наши клиенты мигрируют с существующей системы на новую
              систему автоматизации, мы осуществляем миграцию данных безопасным
              и эффективным способом. Это гарантирует, что данные не будут
              потеряны или повреждены в процессе миграции.
            </p>
          </div>
          <div className="workBox" data-aos-duration="3000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work5})` }}
            >
              <span className="workImgSpan1">05</span>
            </div>
            <h2>Поддержка</h2>
            <p>
              После сборки и развертывания системы мы предоставляем постоянную
              поддержку, чтобы обеспечить ее бесперебойную и эффективную работу.
              Это включает в себя мониторинг системы на наличие проблем,
              предоставление обновлений , а также решение любых проблем или
              вопросов, которые могут возникнуть у наших клиентов.
            </p>
          </div>
        </div>
      )}
      {selectedOption === "am" && (
        <div className="G-flex workList">
        <Helmet>
        <title>Մեր թիմը ☎️ 091 09 09 07</title>
      </Helmet>
          <div className="workBox" data-aos-duration="1000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${background1})` }}
            >
              <span className="workImgSpan1">01</span>
            </div>
            <h2>Պահանջների վերլուծություն</h2>
            <p>
              Codewave-ում մենք սկսում ենք յուրաքանչյուր նախագիծ՝ մանրամասն
              վերլուծելով մեր հաճախորդների պահանջները: Սա ներառում է նրանց
              բիզնես գործընթացների ըմբռնումը, խնդրահարույց կետերի բացահայտումը և
              պատկերացումը թե ինչպես ավտոմատացումը կարող է օգնել բիզնեսին հասնել
              որոշված նպատակներին:
            </p>
          </div>
          <div className="workBox" data-aos-duration="1500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${start})` }}
            >
              <span className="workImgSpan2">02</span>
            </div>
            <h2>Պլանավորում</h2>
            <p>
              Երբ մենք հստակ պատկերացում ունենք մեր հաճախորդների պահանջների
              մասին, մենք աշխատում ենք նրանց հետ՝ մշակելու ծրագիր, որը
              կբացահայտի աշխատանքի ծավալը, ժամանակացույցը և անհրաժեշտ
              ռեսուրսները: Սա օգնում է մեզ ապահովել նախագծի ավարտը ժամանակին և
              բյուջեի սահմաններում:
            </p>
          </div>
          <div className="workBox" data-aos-duration="2000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${publish})` }}
            >
              <span className="workImgSpan3">03</span>
            </div>
            <h2>Ծրագրավորում և ներդնում</h2>
            <p>
              Այնուհետև մեր փորձագետների թիմը սկսում է ծրագրավորման և ներդրման
              փուլը, որը ներառում է ավտոմատացման համակարգի կոդավորում,
              փորձարկում և ներդնում: Մենք օգտագործում ենք վերջին տեխնոլոգիաները
              և լավագույն փորձը ապահովելու համակարգի անվտանգ և հեշտ
              օգտագործումը:
            </p>
          </div>
          <div className="workBox" data-aos-duration="2500"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work4})` }}
            >
              <span className="workImgSpan2">04</span>
            </div>
            <h2>Տվյալների միգրացիա</h2>
            <p>
              Եթե մեր հաճախորդները տեղափոխվում են գոյություն ունեցող համակարգից
              դեպի նոր ավտոմատացման համակարգ, մենք անվտանգ և արդյունավետ կերպով
              կատարում ենք տվյալների տեղափոխումը: Սա երաշխավորում է, որ
              միգրացիայի գործընթացում որևէ տվյալ չի կորչում կամ չի վնասվում
            </p>
          </div>
          <div className="workBox" data-aos-duration="3000"  data-aos="fade-up">
            <div
              className="workImg"
              style={{ backgroundImage: `url(${work5})` }}
            >
              <span className="workImgSpan1">05</span>
            </div>
            <h2>Աջակցություն</h2>
            <p>
              Համակարգի կառուցումից և գործարկումից հետո մենք տրամադրում ենք
              շարունակական աջակցություն՝ ապահովելու, որ այն աշխատում է սահուն և
              արդյունավետ: Սա ներառում է համակարգի մշտադիտարկում խնդիրների
              համար, տրամադրում ենք թարմացումներ և արդիականացումներ, ինչպես նաև
              լուծում ենք ցանկացած մտահոգություն, որը կարող է ունենալ մեր
              հաճախորդը:
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Team;

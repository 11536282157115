import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss"; // Import your SCSS file

const PageNotFound = ({ selectedOption }) => {
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Page Not Found');
  }, []);

  return (
    <div className="display-flex-center-404">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {selectedOption === "en" && (
        <div className="box-404">
          <Helmet>
            <title>404 - Page Not Found</title>
            <meta
              name="description"
              content="The page you are looking for does not exist."
            />
            <meta name="prerender-status-code" content="404" />
          </Helmet>
          <h2 className="title-404" data-aos="fade-up-right">
            Oops!
          </h2>
          <p className="description-404" data-aos="fade-up-right">
            The page you are looking for does not exist.
          </p>
        </div>
      )}
       {selectedOption === "am" && (
        <div className="box-404">
          <Helmet>
            <title>404 - Էջը չի գտնվել</title>
            <meta
              name="description"
              content="The page you are looking for does not exist."
            />
            <meta name="prerender-status-code" content="404" />
          </Helmet>
          <h2 className="title-404" data-aos="fade-up-right">
          Ու՜պս 
           <p className="description-404" data-aos="fade-up-right">
          Ձեր փնտրած էջը գոյություն չունի:
          </p>
          </h2>
        
        </div>
      )}
       {selectedOption === "ru" && (
        <div className="box-404">
          <Helmet>
            <title>404 Страница не найдена</title>
            <meta
              name="description"
              content="The page you are looking for does not exist."
            />
            <meta name="prerender-status-code" content="404" />
          </Helmet>
          <h2 className="title-404" data-aos="fade-up-right">
          Упс!
          </h2>
          <p className="description-404" data-aos="fade-up-right">
          Страница, которую вы ищете, не существует.
          </p>
        </div>
      )}
    </div>
  );
};

export default PageNotFound;

import React from "react";
import boxTitle from "../../../../assets/images/boxVersion/box-corp.png";

import img1 from "../../../../assets/images/integrationBox/img1.png";
import img2 from "../../../../assets/images/integrationBox/img2.png";
import img3 from "../../../../assets/images/integrationBox/img3.png";
import img4 from "../../../../assets/images/integrationBox/img4.png";
import img5 from "../../../../assets/images/integrationBox/img5.png";
import img6 from "../../../../assets/images/integrationBox/img6.png";
import img7 from "../../../../assets/images/integrationBox/img7.png";

import "./style.scss";

const IntegrationBox = ({ selectedOption }) => {
  return (
    <div>
      {selectedOption === "ru" && (
        <div className="B24Cont center">
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Интеграции</h3>
              <p className="catDesc">
                1С-Битрикс24 (коробка) интегрирован:
                <br />{" "}
              </p>
              <ul className="integrationList">
                <li>MS Office, OpenOffice и LibreOffice</li>
                <li>GoogleDocs (документы)</li>
                <li> MS Exchange Web Mail</li>
                <li>MS Outlook и Google (контакты, календари)</li>
                <li> продуктами MacOS, iOS, Android</li>
                <li>«1С:ЗУП» (данные по сотрудникам)</li>
                <li>
                  Active Directory/LDAP Интегратор + NTLM (корпоративная сеть)
                </li>
                <li>
                  Коннектор к MS SharePoint и MS Exchange Server 2007/2010
                </li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Интеграция с 1С:ЗУП</h3>
              <p className="catDesc">
                Интеграция обеспечивает наличие на портале актуальных данных по
                персоналу компании:
              </p>
              <br />
              <ul className="integrationList">
                <li>Организационная структура</li>
                <li>Данные по сотрудникам</li>
                <li>Кадровые перестановки</li>
                <li>Отсутствия сотрудников</li>
                <li>
                  {" "}
                  1C-Битрикс24 интегрирован и с другими продуктами продуктами
                  1С.
                </li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ МОДУЛИ
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">С продуктами Apple</h3>
              <p className="catDesc">
                Поддержка протоколов CalDAV и CardDAV для интеграции с
                мобильными устройствами даёт возможность выполнять
                двунаправленный обмен данными между вашими iPad, iPhone и
                1C-Битрикс24. <br />
                <br />
                <br />
                Интеграция с продуктами Apple позволяет также подключить
                календари к устройствам на Mac OS X, iPad, iPhone, а также
                загрузить контакты с 1C-Битрикс24 на macOS, iPad, iPhone.{" "}
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">С продуктами Microsoft</h3>
              <p className="catDesc">
                В 1C-Битрикс24 есть всё необходимое, чтобы быстро и легко
                вписаться в существующую инфраструктуру компании:
                <br />{" "}
              </p>
              <br />
              <ul className="integrationList">
                <li>Коннектор к MS SharePoint 2007/2010</li>
                <li>Коннектор к MS Exchange Server 2007/2010</li>
                <li>Интеграция с MS Exchange Web Mail</li>
                <li>Двунаправленная интеграция с Microsoft Outlook</li>
              </ul>{" "}
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Microsoft Outlook</h3>
              <p className="catDesc">
                1C-Битрикс24 и MS Outlook сами между собой договорятся и
                внесенные изменения в персональные календарях, контактах
                сотрудников и ваших задачах в одной программе автоматически
                отобразятся в другой.
                <br />
                <br />
                Рекомендуется версия Outlook — от 2007.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                MS Office, OpenOffice и LibreOffice
              </h3>
              <p className="catDesc">
                Редактируйте офисные документы прямо на портале — через браузеры
                MS Internet Explorer и Mozilla Firefox.
                <br />
                <br />
                При этом сам способ редактирования документов на портале в
                популярных браузерах работает не только под Windows, но и под
                Unix-подобными системами и Mac.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img7})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Active Directory/LDAP Интегратор + NTLM
              </h3>
              <p className="catDesc">
                Active Directory/LDAP Интегратор предназначен для интеграции
                вашего 1C-Битрикс24 в корпоративную сеть и централизации
                управления группами пользователей корпоративной информационной
                системы.
                <br />
                <br />
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedOption === "en" && (
        <div className="B24Cont center">
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Integrations</h3>
              <p className="catDesc">
                1C-Bitrix24 (on-premise) integrated:
                <br />{" "}
              </p>
              <ul className="integrationList">
                <li>MS Office, OpenOffice and LibreOffice</li>
                <li>GoogleDocs (documents)</li>
                <li> MS Exchange Web Mail</li>
                <li>MS Outlook and Google (contacts, calendars)</li>
                <li> MacOS, iOS, Android products</li>
                <li>"1C:ZUP (data on employees)</li>
                <li>
                  Active Directory/LDAP Интегратор + NTLM (корпоративная сеть)
                </li>
                <li>
                  Connector to MS SharePoint and MS Exchange Server 2007/2010
                </li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Integration with 1C:ZUP</h3>
              <p className="catDesc">
                The integration ensures the availability of up-to-date employee
                data on the portal:
              </p>
              <br />
              <ul className="integrationList">
                <li>Organizational structure</li>
                <li>Employee data</li>
                <li>Staff reshuffles</li>
                <li>Employee absence</li>
                <li>1C-Bitrix24 is also integrated with other 1C products.</li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD MODULES
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">With Apple products</h3>
              <p className="catDesc">
                Support for CalDAV and CardDAV protocols for integration with
                mobile devices makes it possible to perform bi-directional data
                exchange between your iPad, iPhone and 1C-Bitrix24.
                <br />
                <br />
                Integration with Apple products also allows you to connect
                calendars to your Mac OS X, iPad, iPhone devices and download
                contacts from 1C-Bitrix24 on macOS, iPad, iPhone.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">With Microsoft products</h3>
              <p className="catDesc">
                1C-Bitrix24 has everything you need to quickly and easily fit
                into your existing infrastructure:
                <br />{" "}
              </p>
              <br />
              <ul className="integrationList">
                <li>Connector to MS SharePoint 2007/2010</li>
                <li>Connector to MS Exchange Server 2007/2010</li>
                <li>Integration with MS Exchange Web Mail</li>
                <li>Bidirectional integration with Microsoft Outlook</li>
              </ul>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Microsoft Outlook</h3>
              <p className="catDesc">
                1C-Bitrix24 and MS Outlook connect to each other and all changes
                made in personal calendars, employees' contacts, and your tasks
                in one program will automatically appear in the other one.{" "}
                <br />
                <br />
                We recommend using Outlook 2007 or later.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                MS Office, OpenOffice and LibreOffice
              </h3>
              <p className="catDesc">
                Edit office documents right on the portal - through browsers MS
                Internet Explorer and Mozilla Firefox.
                <br />
                <br />
                And the method itself for editing documents on the portal in
                popular browsers works not only on Windows, but also on
                Unix-like systems and Mac.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img7})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Active Directory/LDAP Integrator + NTLM
              </h3>
              <p className="catDesc">
                Active Directory/LDAP Integrator is designed to integrate your
                1C-Bitrix24 into a corporate network and centralize management
                of user groups in a corporate information system.
                <br />
                <br />
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedOption === "am" && (
        <div className="B24Cont center">
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Ինտեգրումներ</h3>
              <p className="catDesc">
                1C-Bitrix24-ը (տուփային) ինտեգրված է.
                <br />{" "}
              </p>
              <ul className="integrationList">
                <li>MS Office, OpenOffice և LibreOffice</li>
                <li>GoogleDocs (փաստաթղթեր)</li>
                <li>MS Exchange վեբ փոստ</li>
                <li>MS Outlook և Google (կոնտակտներ, օրացույցներ)</li>
                <li> MacOS, iOS, Android պրոդուկտների հետ</li>
                <li>«1C: ZUP» (աշխատակիցների տվյալներ)Active </li>
                <li>Directory/LDAP Integrator + NTLM (կորպորատիվ ցանց)</li>
                <li>
                  Միակցիչ MS SharePoint-ին և MS Exchange Server-ին 2007/2010
                </li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Ինտեգրում 1C:ZUP-ի հետ</h3>
              <p className="catDesc">
                Ինտեգրումն ապահովում է, անձնակազմի վերաբերյալ արդի տվյալների
                պահպանումը պորտալում.
              </p>
              <br />
              <ul className="integrationList">
                <li>Կազմակերպչական կառուցվածք</li>
                <li>Աշխատակիցների տվյալներ</li>
                <li>Կադրային փոփոխություններ</li>
                <li>Աշխատակիցների բացակայություններ</li>
                <li>
                  1C-Bitrix24-ը նաև ինտեգրված է 1C-ի այլ արտադրանքների հետ:
                </li>
              </ul>

              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  ՆԵՐԲԵՌՆԵԼ ՄՈԴՈՒԼՆԵՐ
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Apple-ի արտադրանքի հետ</h3>
              <p className="catDesc">
                Բջջային սարքերի հետ ինտեգրվելու համար CalDAV և CardDAV
                արձանագրությունների աջակցությունը հնարավորություն է տալիս
                կատարել երկկողմանի տվյալների փոխանակում ձեր iPad-ի, iPhone-ի և
                1C-Bitrix24-ի միջև:
                <br />
                <br />
                <br />
                Apple-ի արտադրանքների հետ ինտեգրումը նաև թույլ է տալիս միացնել
                օրացույցները Mac OS X-ի, iPad-ի, iPhone-ի սարքերին, ինչպես նաև
                ներբեռնել կոնտակտներ 1C-Bitrix24-ից macOS, iPad, iPhone:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Microsoft-ի արտադրանքի հետ
              </h3>
              <p className="catDesc">
                1C-Bitrix24-ն ունի այն ամենը, ինչ անհրաժեշտ է ընկերության առկա
                ենթակառուցվածքում արագ և հեշտությամբ ինտեգրվելու համար.
                <br />{" "}
              </p>
              <br />
              <ul className="integrationList">
                <li>Միակցիչ MS SharePoint 2007/2010-ին</li>
                <li>Միակցիչ MS Exchange Server 2007/2010-ին</li>
                <li>Ինտեգրում MS Exchange Web Mail-ի հետ</li>
                <li>Երկկողմանի ինտեգրում Microsoft Outlook-ի հետ</li>
              </ul>{" "}
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Microsoft Outlook</h3>
              <p className="catDesc">
                1C-Bitrix24-ը և MS Outlook-ը կսինխրոնիզացվեն այնպես, որ՝
                անհատական ​​օրացույցներում, աշխատակիցների կոնտակտներում և
                առաջադրանքներում ձեր կատարված փոփոխությունները մի ծրագրում՝
                ավտոմատ կերպով կցուցադրվեն մյուսում:
                <br />
                <br />
                Նախընտրելի է Outlook-ի 2007 կամ ավելի ուշ տարբերակը:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                MS Office, OpenOffice և LibreOffice
              </h3>
              <p className="catDesc">
                Խմբագրեք փաստաթղթերը անմիջապես պորտալում՝ օգտագործելով MS
                Internet Explorer և Mozilla Firefox բրաուզերները:
                <br />
                <br />
                Ինչպես նաև, փաստաթղթերը խմբագրելու մեթոդը հենց հանրաճանաչ
                բրաուզերներում, պորտալում աշխատում է ոչ միայն Windows-ի, այլ նաև
                Unix-ի նման համակարգերի և Mac-ի վրա:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img7})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Active Directory/LDAP ինտեգրատոր + NTLM
              </h3>
              <p className="catDesc">
                Active Directory/LDAP Integrator-ը նախատեսված է ձեր
                1C-Bitrix24-ը կորպորատիվ ցանցին ինտեգրելու և կորպորատիվ
                տեղեկատվական համակարգի օգտատերերի խմբերի կառավարումը
                կենտրոնացնելու համար:
                <br />
                <br />
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationBox;

import React from 'react'
import MySvG from '../../../assets/images/bitrix24/FireIcon.svg.png'
import MySvG2 from '../../../assets/images/загрузка.svg'
import './style.scss'
import { Link } from 'react-router-dom'


const Box = () => {
  return (
    <div className="box" >
    <div className='titleBitrix' style={{backgroundColor:'rgba(207, 247, 50, 0.2)'}}>
<h1>Бесплатный</h1>
<h6>Начните работать онлайн и продавать больше с CRM</h6>
    </div>
     <div className='info'>
         <div style={{display:'flex', alignItems:'center', padding:'0, 10px'}}>  <img src={MySvG} width="20px" height="30px" alt='img'/>неограниченно</div> 
         <div style={{display:'flex', alignItems:'center', fontSize:'14px'}}>пользователей</div> 
     </div>
     <div className='price'> Бесплатно
     <button><Link to='https://auth2.bitrix24.net/create/?user_lang=ru&client_id=site.53889571c72cf8.19427820&referer=8724759&ga=GA1.2.1537823868.1681215157&_ga=2.24345981.693116834.1681971089-1537823868.1681215157&bx_fbp=fb.1.1681830459063.527115814&bx_ym_uid=1681215159814273133&bx_user_agent=Mozilla%2F5.0+%28Windows+NT+10.0%3B+Win64%3B+x64%29+AppleWebKit%2F537.36+%28KHTML%2C+like+Gecko%29+Chrome%2F112.0.0.0+Safari%2F537.36+Edg%2F112.0.1722.48'>СОЗДАТЬ</Link></button>
     </div>
     <div className='mb'>5 Гб <div className='line' style={{background:'rgba(169, 212, 0, 0.23)'}}></div></div>
     <div className='listTarif'>
     Совместная работа  <img src={MySvG2} width="35px" height="15px" alt='img' />
     </div>
     <div className='listTarifDesc'>Чат</div>
     <div className='listTarifDesc'>Видеозвонки HD</div>
     <div className='listTarifDesc'>Календарь</div>
     <div className='listTarifDesc'>Соцсеть компании</div>
     <div className='listTarifDesc'>Новости</div>
     <div className='listTarifDesc'>База знаний</div>
     <div className='listTarif'>
     Задачи и Проекты <img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     CRM <img src={MySvG2} width="35px" height="15px" alt='img' />
     </div>
     <div className='listTarif'>
     Диск<img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
     <div className='listTarif'>
     Контакт-центр <img src={MySvG2} width="35px" height="15px" alt='img' />
     </div>
     <div className='listTarif'>
     Сайты<img src={MySvG2} width="35px" height="15px" alt='img'/>
     </div>
        </div>

  )
}

export default Box
import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { GrClose } from "react-icons/gr";
import back from "../../../assets/images/web/webBack.png";
import w1 from "../../../assets/images/web/w1.png";
import w2 from "../../../assets/images/web/w2.png";
import w3 from "../../../assets/images/web/w3.png";
import w4 from "../../../assets/images/web/w4.png";
import w5 from "../../../assets/images/web/w5.png";
import w6 from "../../../assets/images/web/w6.png";
import w7 from "../../../assets/images/web/w7.png";
import w8 from "../../../assets/images/web/w8.png";
import Aos from "aos";
import { Helmet } from "react-helmet";

const dataEn = [
  {
    id: 1,
    title: " Business analysis",
    description:
      "Our business analysts specialize in understanding the requirements of your target audience, conducting thorough requirements engineering, and defining the scope of the web development solution. They play a pivotal role in facilitating effective communication between business stakeholders and the IT team, ensuring alignment throughout the project.",
    imageUrl: w1,
  },
  {
    id: 2,
    title: "UX and UI design",
    description:
      "When designing a web application, we begin by analyzing the target audience and carefully planning user journeys that prioritize convenience, speed, and seamless experiences. Throughout this process, our UI designers collaborate to create an aesthetically appealing interface that enhances the overall user experience.",
    imageUrl: w2,
  },
  {
    id: 3,
    title: "Architecture",
    description:
      "Our solution architects take charge of planning the functional components of your web solution and carefully select the most suitable technologies to ensure the feasibility and completeness of the implemented business logic. They also map out all API connections both within the web solution and with external systems, ensuring seamless integration.",
    imageUrl: w3,
  },
  {
    id: 4,
    title: "Front-end development",
    description:
      "Our front-end developers are skilled in transforming design concepts into functional reality, ensuring that all interface elements work seamlessly. They are proficient in using the most effective technologies to bring your ideas to life and deliver a high-quality user experience.",
    imageUrl: w4,
  },
  {
    id: 5,
    title: "Back-end development",
    description:
      "Our experienced back-end developers meticulously translate the business logic of your web application into code on the server-side. We utilize reliable frameworks and prioritize efficient and high-quality coding to ensure optimal performance and functionality.",
    imageUrl: w5,
  },
  {
    id: 6,
    title: "Integration",
    description:
      "We specialize in setting up APIs to facilitate seamless integration of your web application with corporate systems or third-party services. Through app integration, we ensure real-time data synchronization across multiple systems, enabling smooth communication and efficient data exchange.",
    imageUrl: w6,
  },
  {
    id: 7,
    title: "Testing and QA",
    description:
      "We are committed to delivering a flawless, high-performing, user-friendly, and secure web solution. Our team of experienced testing engineers ensures that every aspect of your web application is thoroughly tested and validated, giving you the assurance that your solution meets the highest standards of quality and functionality.",
    imageUrl: w7,
  },
  {
    id: 8,
    title: "Continuous support and evolution",
    description:
      "Business analysis in web development involves understanding the client's business and website requirements to ensure that the website meets the business objectives. The goal of this process is to identify the client's needs and ensure that the website is designed and developed in a way that meets those needs.",
    imageUrl: w8,
  },
];
const dataRu = [
  {
    id: 1,
    title: "Бизнес-анализ",
    description:
      "Наши бизнес-аналитики специализируются на понимании требований вашей целевой аудитории, проведении тщательной разработки требований и определении объема решения для веб-разработки. Они играют ключевую роль в содействии эффективному общению между заинтересованными сторонами бизнеса и ИТ-командой, обеспечивая согласованность на протяжении всего проекта.",
    imageUrl: w1,
  },
  {
    id: 2,
    title: "UX и UI дизайн",
    description:
      "При проектировании веб-приложения мы начинаем с анализа целевой аудитории и тщательного планирования пользовательских путей, которые приоритезируют удобство, скорость и безупречный пользовательский опыт. На протяжении этого процесса наши дизайнеры пользовательского интерфейса сотрудничают для создания эстетически привлекательного интерфейса, улучшающего общий пользовательский опыт.",
    imageUrl: w2,
  },
  {
    id: 3,
    title: "Архитектура",
    description:
      "Наши архитекторы решений берут на себя планирование функциональных компонентов вашего веб-решения и тщательно выбирают наиболее подходящие технологии, чтобы обеспечить выполнимость и полноту реализованной бизнес-логики. Они также разрабатывают карту всех API-подключений как внутри веб-решения, так и с внешними системами, обеспечивая безпроблемную интеграцию.",
    imageUrl: w3,
  },
  {
    id: 4,
    title: "Фронтенд-разработка",
    description:
      "Наши фронтенд-разработчики умеют превращать концепции дизайна в функциональную реальность, обеспечивая безупречную работу всех элементов интерфейса. Они владеют эффективными технологиями, чтобы воплотить ваши идеи в жизнь и обеспечить высококачественный пользовательский опыт.",
    imageUrl: w4,
  },

  {
    id: 5,
    title: "Бэкенд-разработка",
    description:
      "Наши опытные бэкенд разработчики тщательно переводят бизнес-логику вашего веб-приложения в код на стороне сервера. Мы используем надежные фреймворки и придерживаемся эффективного и высококачественного кодирования, чтобы обеспечить оптимальную производительность и функциональность.",
    imageUrl: w5,
  },
  {
    id: 6,
    title: "Интеграция",
    description:
      "Мы специализируемся на настройке API для обеспечения безпроблемной интеграции вашего веб-приложения с корпоративными системами или сторонними сервисами. Через интеграцию приложений мы обеспечиваем синхронизацию данных в реальном времени между несколькими системами, обеспечивая плавное взаимодействие и эффективный обмен данными.",
    imageUrl: w6,
  },
  {
    id: 7,
    title: "Тестирование",
    description:
      "Мы стремимся предоставить безупречное, высокопроизводительное, удобное для пользователя и безопасное веб-решение. Наша команда опытных инженеров по тестированию гарантирует тщательное тестирование и проверку каждого аспекта вашего веб-приложения, давая вам уверенность в том, что ваше решение соответствует высочайшим стандартам качества и функциональности.",
    imageUrl: w7,
  },
  {
    id: 8,
    title: "Поддержка",
    description:
      "Предлагая услуги поддержки, мы обеспечиваем быстрое решение и предотвращение любых проблем, связанных с использованием, технологиями или кодом, которые могут возникнуть с вашим веб-приложением. Наша цель — помочь вам добиться высокой доступности и безупречной функциональности вашего веб-приложения, гарантируя бесперебойную работу пользователей.",
    imageUrl: w8,
  },
];
const dataAm = [
  {
    id: 1,
    title: "Բիզնեսի վերլուծություն",
    description:
      "Մեր բիզնես վերլուծաբանները մասնագիտացած են ձեր թիրախային լսարանի պահանջները հասկանալու, պահանջների վերլուծության և վեբ ծրագրավորման լուծումների մշակման մեջ։ Նրանք առանցքային դեր են խաղում բիզնես շահագրգիռ կողմերի և ՏՏ թիմի միջև արդյունավետ հաղորդակցության հեշտացման գործում՝ ապահովելով հետևողականություն ծրագրի ողջ ընթացքում։ ",
    imageUrl: w1,
  },
  {
    id: 2,
    title: "UX և UI դիզայն",
    description:
      "Վեբ հավելված նախագծելիս մենք սկսում ենք վերլուծել թիրախային լսարանը և պլանավորել օգտատերերի ուղիները, որոնցում առաջնահերթություն են տալիս հարմարավետությանը, արագությանը և օգտատիրոջ անխափան  օգտագործմանը: Այս գործընթացի ընթացքում մեր ինտերֆեյսի դիզայներները համագործակցում են ստեղծելու էսթետիկորեն հաճելի ինտերֆեյս, որը բարելավում է օգտվողի հետ համագործակցությունը",
    imageUrl: w2,
  },
  {
    id: 3,
    title: "Կառուցվածքային լուծումներ",
    description:
      "Մեր վերլուծաբանները ստանձնում են ձեր վեբ լուծման ֆունկցիոնալ բաղադրիչների պլանավորումը և ընտրում են ամենահարմար տեխնոլոգիաները՝ ներդրված բիզնես տրամաբանության իրագործելիությունն ու ամբողջականությունն ապահովելու համար: Նրանք նաև նախագծում են բոլոր API կապերը արտաքին համակարգերի հետ՝ ապահովելով անխափան ինտեգրում:    ",
    imageUrl: w3,
  },
  {
    id: 4,
    title: "Front-End ծրագրավորում",
    description:
      "Մեր Front-End ծրագրավորողները գիտեն, թե ինչպես դիզայնի գաղափարները վերածել ֆունկցիոնալ իրականության՝ ապահովելով՝ ինտերֆեյսի բոլոր տարրերի անթերի աշխատանքը։ Նրանք տիրապետում են անհրաժեշտ տեխնոլոգիաներին՝ ձեր գաղափարները կյանքի կոչելու և օգտատերերի բարձրորակ փորձառություն ապահովելու համար",
    imageUrl: w4,
  },

  {
    id: 5,
    title: "Back-End ծրագրավորում",
    description:
      "Մեր փորձառու Back-End ծրագրավորողները մանրակրկիտ թարգմանում են ձեր վեբ հավելվածի բիզնես տրամաբանությունը սերվերի կողմի կոդի: Մենք օգտագործում ենք հուսալի framework-ներ և հավատարիմ ենք մնում արդյունավետ, բարձրորակ կոդավորմանը՝ օպտիմալ կատարողականություն և ֆունկցիոնալություն ապահովելու համար",
    imageUrl: w5,
  },
  {
    id: 6,
    title: "Ինտեգրում",
    description:
      "Մենք մասնագիտացած ենք API-ների կարգավորումների մեջ՝ ապահովելու ձեր վեբ հավելվածի անխափան ինտեգրումը ձեռնարկության ներքին համակարգերի կամ երրորդ կողմի ծառայությունների հետ: Հավելվածի ինտեգրման միջոցով մենք ապահովում ենք տվյալների սինխրոնիզացիա իրական ժամանակում  համակարգերի միջև՝ ապահովելով հարթ փոխազդեցություն և արդյունավետ տվյալների փոխանակում",
    imageUrl: w6,
  },
  {
    id: 7,
    title: "Թեստավորում",
    description:
      "Մենք հանձնառու ենք մատուցել անթերի, բարձր կատարողականություն, օգտագործողի համար հարմար և անվտանգ վեբ լուծում: Մեր փորձառու թեստավորողների թիմը երաշխավորում է, որ ձեր վեբ հավելվածի բոլոր ասպեկտները մանրակրկիտ փորձարկված և ստուգված են՝ վստահություն տալով ձեզ, որ ձեր լուծումը համապատասխանում է պահանջներին, ամենաբարձր որակի չափանիշներին և ֆունկցիոնալությանը",
    imageUrl: w7,
  },
  {
    id: 8,
    title: "Աջակցություն",
    description:
      "Աջակցման ծառայություններ առաջարկելով՝ մենք ապահովում ենք ձեր վեբ հավելվածի հետ կապված օգտագործման, տեխնոլոգիայի կամ կոդի հետ կապված ցանկացած խնդիրների արագ լուծում և կանխարգելում: Մեր նպատակն է օգնել ձեզ հասնելու ձեր վեբ հավելվածի բարձր հասանելիության և անթերի ֆունկցիոնալությանը՝ երաշխավորելով օգտատերերի անխափան փորձառություն:",
    imageUrl: w8,
  },
];
// useEffect(() => {
//   Aos.init({
//     duration: 1000,
//   });
// }, []);
const WEBContainer = ({ selectedOption, handleClose }) => {
  const refModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };

  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      Aos.init({
        duration: 1000,
      });
    };
  }, []);
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Վեբ դիզայն և ծրագրավորում ☎️ 091 09 09 07');
  }, []);
  return (
    <div ref={refModal}>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="top70"></div>

      {selectedOption === "en" && (
        <div className="webCont">
        <Helmet>
            <title>Web design and development ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div className="backTitle">
                  <h2> Web design & Development </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">
                  At CodeWave, our web development services are designed to help
                  businesses achieve their online goals with high-quality,
                  custom websites that are built to impress and perform
                </h2>
                <p className="biTitleP">
                  We take a collaborative approach to web development, working
                  closely with our clients to understand their unique needs and
                  goals. From there, we use the latest tools and technologies to
                  design and develop custom websites that are tailored to their
                  specific requirements
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2" style={{ paddingBottom: "60px" }}>
                Full-Scale Web Development by CodeWave
              </h2>
            </div>
            <div className="iconList" data-aos="fade-up" >
              {dataEn.map((item) => (
                <div className="boxWeb" key={item.id}>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="wimgen"
                    
                  />
                  <h2 data-aos="fade-up">{item.title}</h2>
                  <p className="boxPWeb">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedOption === "am" && (
        <div className="webCont">
        <Helmet>
            <title>Վեբ դիզայն և ծրագրավորում ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Վեբ դիզայն և կայքերի պատրաստում </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2">
                  Վեբ կայքերի մշակման մեր ծառայությունները օգնում են
                  ընկերություններին հասնել իրենց նպատակներին՝ օգտագործելով
                  բարձրորակ, հատուկ նախագծված վեբկայքեր, որոնք տպավորում են և
                  ապահովում բարձր արդյունավետություն:
                </h2>
                <p className="biTitleP">
                  Մենք համատեղ մոտեցում ենք ցուցաբերում վեբ կայքի մշակման
                  հարցում՝ սերտորեն համագործակցելով մեր հաճախորդների հետ՝
                  հասկանալու նրանց յուրահատուկ կարիքներն ու նպատակները:
                  Այնուհետև օգտագործելով նորագույն գործիքներ և տեխնոլոգիաներ՝
                  մոնք նախագծում և ստեղծում ենք կայքեր, որոնք համապատասխանում են
                  մեր հաճախորդների հատուկ պահանջներին:
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2" style={{ paddingBottom: "60px" }}>
                Ամբողջամասշտաբ վեբ ծրագրավորում CodeWave-ից
              </h2>
            </div>
            <div className="iconList" data-aos="fade-up">
              {dataAm.map((item) => (
                <div className="boxWeb" key={item.id}>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="wimgam"
                  />
                  <h2 data-aos="fade-up">{item.title}</h2>
                  <p className="boxPWeb">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedOption === "ru" && (
        <div className="webCont">
        <Helmet>
            <title>Подготовка web сайтов ☎️ 091 09 09 07</title>
          </Helmet>
          <div className="title">
            <div className="titleBox">
              <div
                className="backTitle"
                style={{ backgroundImage: `url(${back})` }}
              >
                <div>
                  <h2> Веб-дизайн и разработка </h2>
                </div>
              </div>
            </div>
            <div className="biTitle">
              <div className="biTitleText">
                <h2 className="biTitleh2 webh2">
                  В CodeWave наши услуги по разработке веб-сайтов предназначены
                  для того, чтобы помочь компаниям достичь своих целей с помощью
                  высококачественных, созданных по индивидуальному заказу
                  веб-сайтов, которые производят впечатление и обеспечивают
                  высокую производительность.
                </h2>
                <p className="biTitleP">
                  Мы используем совместный подход к разработке веб-сайтов, тесно
                  сотрудничая с нашими клиентами, чтобы понять их уникальные
                  потребности и цели. После этого мы используем новейшие
                  инструменты и технологии для проектирования и разработки
                  веб-сайтов, отвечающих их специфическим требованиям.
                  <br />
                </p>
              </div>
            </div>
            <div className="titleBox">
              <h2 className="biTitleh2" style={{ paddingBottom: "60px" }}>
                Полномасштабная веб-разработка от CodeWave
              </h2>
            </div>
            <div className="iconList" data-aos="fade-up">
              {dataRu.map((item) => (
                <div className="boxWeb" key={item.id}>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="wimgru"
                  />
                  <h2 data-aos="fade-up">{item.title}</h2>
                  <p className="boxPWeb">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WEBContainer;

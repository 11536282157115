import React, { useState } from 'react'
import MySvG4 from '../../../assets/images/ent.svg'
import './style.scss'


const BoxEnterPrice = () => {
  const priceInfoArray = [
    { value: '250', oldPrice: 33990, newPrice: Math.floor(33990 * 0.7) },
    { value: '500', oldPrice: 59990, newPrice: Math.floor(59990 * 0.7) },
    { value: '1000', oldPrice: 99990, newPrice: Math.floor(99990 * 0.7) },
    { value: '2000', oldPrice: 199990, newPrice: Math.floor(199990 * 0.7) },
    { value: '3000', oldPrice: 299990, newPrice: Math.floor(299990 * 0.7) },
    { value: '4000', oldPrice: 399990, newPrice: Math.floor(399990 * 0.7) },
    { value: '5000', oldPrice: 499990, newPrice: Math.floor(499990 * 0.7) },
    { value: '6000', oldPrice: 599990, newPrice: Math.floor(599990 * 0.7) },
    { value: '7000', oldPrice: 699990, newPrice: Math.floor(699990 * 0.7) },
    { value: '8000', oldPrice: 799990, newPrice: Math.floor(799990 * 0.7) },
    { value: '9000', oldPrice: 899990, newPrice: Math.floor(899990 * 0.7) },
    { value: '10000', oldPrice: 999990, newPrice: Math.floor(999990 * 0.7) }
  ];
  
  const [selectedCount, setSelectedCount] = useState('250');
  const [priceInfo, setPriceInfo] = useState(priceInfoArray[0]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCount(selectedValue);
    const newPriceInfo = priceInfoArray.find((price) => price.value === selectedValue);
    setPriceInfo(newPriceInfo);
  };

  return (
    <div className="boxPrices" >
      <div className='titleBitrixPrices'>
        <div className='titleBitrix' style={{ background: '#A4F3F3' }}>
          <h1>Энтерпрайз
          </h1>
          <h6>
            Для цифровизации крупного и среднего бизнеса на максимальных скоростях</h6>
        </div>
        <div className='info' style={{ paddingBottom: '20px' }}>
          <label >
            <select id="user-count-select" value={selectedCount} onChange={handleSelectChange} style={{
              background: 'transparent',
              width: '180px',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '12px', border: '1px solid #8080803b',
              fontWeight: '600', minHeight: '30px'
            }}>
              {priceInfoArray.map((price) => (
                <option key={price.value} value={price.value} className="user-count-option" >{price.value} пользователей</option>
              ))}
            </select>
          </label>
        </div>
        <div className='price'>
          <div className='discount'>  {priceInfo.oldPrice} ₽/мес. </div>
          <div className='newPrice'>   {priceInfo.newPrice} ₽/мес. <div className='discN'>-30%</div></div>
          <button style={{ background: '#21bdee' }}>КУПИТЬ</button>
        </div>
        <div className='mb'>3 Тб <div className='line'></div></div>
        <div className='listTarif'>Совместная работа  <img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarifDesc'>Чат</div>
        <div className='listTarifDesc'>Видеозвонки HD</div>
        <div className='listTarifDesc'>Календарь</div>
        <div className='listTarifDesc'>Соцсеть компании</div>
        <div className='listTarifDesc'>Новости</div>
        <div className='listTarifDesc'>База знаний</div>
        <div className='listTarif'>Задачи и Проекты <img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'>CRM <img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'>Диск<img src={MySvG4} width="35px" height="15px" alt='img' /> </div>
        <div className='listTarif'> Контакт-центр <img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'>Сайты<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Интернет-магазин<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Маркетинг <img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Документы Онлайн<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'>  Сквозная аналитика<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Автоматизация<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> HR<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Филиалы<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Энтерпрайз-кластер<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Энтерпрайз-пакет<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Поддержка<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Администрирование<img src={MySvG4} width="35px" height="15px" alt='img' /></div>
      </div>
    </div>

  )
}

export default BoxEnterPrice
import React from "react";
import "./style.scss";
import "../footer/style.scss";
import {
  BsFacebook,
  BsInstagram,
  BsTelegram,
} from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
// import { Link } from 'react-router-dom';
import { useState } from "react";
import logo from "../../assets/images/logoOnly.png";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Footer = ({
  selectedOption,
   handleClick
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputValue);
  };

  return (
    <div >
      <Helmet>
    <title>
      Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում ☎️ 091
      09 09 07
    </title>
    </Helmet>
      {selectedOption === "en" && (
        <footer className="footerContainer"  data-aos="fade-up">
          <div className="footerBox" data-aos="fade-up">
            <div className="footerAddressBox">
              <NavLink to="/">
                {" "}
                <h3 className="footerTitle">
                  <img src={logo} alt="logo" onClick={handleClick} />
                </h3>
              </NavLink>
              <p className="footerDescAds">
                2/2 Sasna Tsrer Street, Yerevan 0054{" "}
              </p>
              <p className="footerDesc">
                <b>Phone: </b> <a href="tel:+37491090907" className="iconContactDesc footer-contact">+37491 09 09 07</a>
              </p>
              <p className="footerDesc">
                <b>Email: </b> <a href="mailto:info@codewave.am" className="iconContactDesc footer-contact" >info@codewave.am</a>
              </p>
              <div className="footerMedia">
              <Link to="https://t.me/CodeWave_Support" target="_blank" className="telegram">
                  <BsTelegram />
                </Link>
                <Link className="facebook"
                  to="https://www.facebook.com/people/CodeWave/100092537718711/?is_tour_dismissed=true"
                  target="_blank"
                >
                  <BsFacebook />
                </Link>
                <Link className="instagram"
                  to="https://instagram.com/codewave_armenia?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                >
                  <BsInstagram />
                </Link>
              </div>
            </div>
            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Useful Links
                <NavLink to="/" onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Home
                  </li>
                </NavLink>
                <NavLink to={`/about`}  onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> About us
                  </li>
                </NavLink>
                <NavLink to={`/services`}  onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Services
                  </li>
                </NavLink>
                <NavLink to={`/contacts`}  onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Contacts
                  </li>
                </NavLink>
                <NavLink to={`/team`}  onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Team
                  </li>
                </NavLink>
              </ul>
            </div>

            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Our Services
                <NavLink to={`/services/erp`}>
                  {" "}
                  <li>
                    <AiOutlineRight /> ERP
                  </li>{" "}
                </NavLink>
                <NavLink to={`/services/bitrix24`}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Bitrix24
                  </li>{" "}
                </NavLink>
                <NavLink to={`/services/web`}>
                  {" "}
                  <li>
                    {" "}
                    <AiOutlineRight /> Web Development
                  </li>{" "}
                </NavLink>
                <NavLink to={`/services/bi`}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Automatic (BI)
                  </li>{" "}
                </NavLink>
                <NavLink to={`/services/wms`}>
                  {" "}
                  <li>
                    <AiOutlineRight /> WMS
                  </li>{" "}
                </NavLink>
              </ul>
            </div>
            {/* <div className='footerNavMsg'>
          <p className='footerNavTitle'> Our Newsletter</p>
          <p className='footerNavdesc' > Subscribe to our news, stay informed online </p>
          <form onSubmit={handleSubmit} className='footerForm'>
            <input
              type="text"
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              placeholder='Enter your e-mail '
            />
            <button type="submit">Subscribe</button>
          </form>
        </div> */}
          </div>
          <div className="footerData">
            © CodeWave, 2023. All rights reserved
          </div>
        </footer>
      )}
      {selectedOption === "ru" && (
        <footer className="footerContainer"  data-aos="fade-up">
          <div className="footerBox" data-aos="fade-up">
            <div className="footerAddressBox">
              <NavLink to="/">
                {" "}
                <h3 className="footerTitle">
                  <img src={logo} alt="logo" onClick={handleClick} />{" "}
                </h3>
              </NavLink>
              <p className="footerDescAds">ул. Сасна Црер 2/2, Ереван 0054</p>
              <p className="footerDesc">
                <b>Телефон: </b> <a href="tel:+37491090907" className="iconContactDesc footer-contact">+37491 09 09 07</a>
              </p>
              <p className="footerDesc">
                <b>Эл. почта: </b> <a href="mailto:info@codewave.am" className="iconContactDesc footer-contact" >info@codewave.am</a>
              </p>
              <div className="footerMedia">
              <Link to="https://t.me/CodeWave_Support" target="_blank" className="telegram">
                  <BsTelegram />
                </Link>
                <Link className="facebook"
                  to="https://www.facebook.com/people/CodeWave/100092537718711/?is_tour_dismissed=true"
                  target="_blank"
                >
                  <BsFacebook />
                </Link>
                <Link className="instagram"
                  to="https://instagram.com/codewave_armenia?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                >
                  <BsInstagram />
                </Link>
              </div>
            </div>
            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Полезные ссылки
                <NavLink to="/" onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Главная
                  </li>
                </NavLink>
                <NavLink to={`/about`} onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> О нас
                  </li>
                </NavLink>
                <NavLink to={`/services`} onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Услуги
                  </li>
                </NavLink>
                <NavLink to={`/contacts`} onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Контакты
                  </li>
                </NavLink>
                <NavLink to={`/team`} onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Команда
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Наши услуги
                <NavLink to={`/services/erp`}>
                  <li>
                    <AiOutlineRight /> ERP
                  </li>
                </NavLink>
                <NavLink to={`/services/bitrix24`}>
                  <li>
                    <AiOutlineRight /> Битрикс24
                  </li>
                </NavLink>
                <NavLink to={`/services/web`}>
                  <li>
                    {" "}
                    <AiOutlineRight /> Веб-сайты
                  </li>
                </NavLink>
                <NavLink to={`/services/bi`}>
                  <li>
                    <AiOutlineRight /> Автоматизация BI
                  </li>
                </NavLink>
                <NavLink to={`/services/wms`}>
                  <li>
                    <AiOutlineRight /> Склад
                  </li>
                </NavLink>
              </ul>
            </div>
            {/* <div className='footerNavMsg'>
          <p className='footerNavTitle'>Наш информационный блок</p>
          <p className='footerNavdesc' > Подпишитесь на наши новости, будьте в курсе онлайн</p>
          <form onSubmit={handleSubmit} className='footerForm'>
            <input
              type="text"
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              placeholder='Введите адрес электронной почты'
            />
            <button type="submit">Подписаться</button>
          </form>
        </div> */}
          </div>
          <div className="footerData">
            © CodeWave  2023. Все права защищены
          </div>
        </footer>
      )}
      {selectedOption === "am" && (
        <footer className="footerContainer"  data-aos="fade-up">
          <div className="footerBox">
            <div className="footerAddressBox">
              <NavLink to="/">
                {" "}
                <h3 className="footerTitle">
                  <img src={logo} alt="logo" onClick={handleClick} />{" "}
                </h3>
              </NavLink>
              <p className="footerDescAds">Երևան 0054, Սասնա Ծռեր 2/2</p>
              <p className="footerDesc">
                <b>Հեռախոս: </b> <a href="tel:+37491090907" className="iconContactDesc footer-contact">+37491 09 09 07</a>{" "}
              </p>
              <p className="footerDesc">
                <b>Էլ․ փոստ: </b> <a href="mailto:info@codewave.am" className="iconContactDesc footer-contact" >info@codewave.am</a>
              </p>
              <div className="footerMedia">
              <Link to="https://t.me/CodeWave_Support" target="_blank" className="telegram">
                  <BsTelegram />
                </Link>
                <Link className="facebook"
                  to="https://www.facebook.com/people/CodeWave/100092537718711/?is_tour_dismissed=true"
                  target="_blank"
                >
                  <BsFacebook />
                </Link>
                <Link className="instagram"
                  to="https://instagram.com/codewave_armenia?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                >
                  <BsInstagram />
                </Link>
              </div>
            </div>
            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Օգտակար հղումներ
                <NavLink to="/" onClick={handleClick}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Գլխավոր
                  </li>
                </NavLink>
                <NavLink
                  to={`/about`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> Մեր մասին
                  </li>
                </NavLink>
                <NavLink
                  to={`/services`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> Ծառայություններ
                  </li>
                </NavLink>
                <NavLink
                  to={`/contacts`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> Կոնտակտներ
                  </li>
                </NavLink>
                <NavLink
                  to={`/team`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> Մեր թիմը
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="footerNav">
              <ul className="footerNavTitle">
                {" "}
                Ծառայություններ
                <NavLink
                  to={`/services/erp`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> ERP
                  </li>
                </NavLink>
                <NavLink to={`/services/bitrix24`}>
                  {" "}
                  <li>
                    <AiOutlineRight /> Բիտրիքս24
                  </li>
                </NavLink>
                <NavLink
                  to={`/services/web`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    {" "}
                    <AiOutlineRight /> Web ծրագրավորում
                  </li>
                </NavLink>
                <NavLink
                  to={`/services/bi`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> BI համակարգ
                  </li>
                </NavLink>
                <NavLink
                  to={`/services/wms`}
                  onClick={handleClick}
                >
                  {" "}
                  <li>
                    <AiOutlineRight /> Պահեստ
                  </li>
                </NavLink>
              </ul>
            </div>
            {/* <div className='footerNavMsg'>
          <p className='footerNavTitle'> Մեր տեղեկագիրը</p>
          <p className='footerNavdesc' > Բաժաներդագրվեք մեր նորություններին, տեղեկացեք առցանց </p>
          <form onSubmit={handleSubmit} className='footerForm'>
            <input
              type="text"
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              placeholder='Մուտքագրեք ձեր էլ հասցեն'
            />
            <button type="submit">Հետևել</button>
          </form>
        </div> */}
          </div>
          <div className="footerData">
            © CodeWave  2023. Բոլոր իրավունքները պաշտպանված են
          </div>
        </footer>
      )}
    </div>
  );
};

export default Footer;

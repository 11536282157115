import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import back from "../../assets/images/logoC.png";
import "./style.scss";
import ContactForm from "./formComponent";
import { Helmet } from "react-helmet";
const Contacts = ({ selectedOption }) => {
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում ☎️ 091 09 09 07');
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {selectedOption === "en" && (
        <div className="contactContainer" data-aos="fade-up">
          <Helmet>
            <title>
              {" "}
              Business automation, CRM-, BI-, ERP-, WMS-systems programming ☎️
              091 09 09 07:
            </title>
          </Helmet>
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Contact
          </h2>
          <div className="contInfo">
            <div className="address">
              <p className="iconContact">
                {" "}
                <CiLocationOn />
              </p>
              <h3 className="iconContactTitle">Our Address</h3>
              <a href="https://goo.gl/maps/5AoDq1U89opbNTnQA" className="iconContactDesc" target="_blank" rel="noopener noreferrer">
                2/2 Sasna Tsrer Street, Yerevan 0054{" "}
              </a>
            </div>
            <div className="emailBox">
              <p className="iconContact">
                {" "}
                <AiOutlineMail />
              </p>
              <h3 className="iconContactTitle">Email Us</h3>
              <a href="mailto:info@codewave.am" className="iconContactDesc" >info@codewave.am</a>
            </div>
            <div className="callUsBox">
              <p className="iconContact">
                {" "}
                <FiPhoneCall />
              </p>
              <h3 className="iconContactTitle">Call Us</h3>
              <a href="tel:+37491090907" className="iconContactDesc">+37491 09 09 07</a>
            </div>
            <div className="quartes">
              <div className="iframe-container">
                <iframe
                  src="https://maps.google.com/maps?q=yerevan CodeWave&t=&z=18&ie=UTF8&iwloc=&output=embed"
                  title="Map"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='formEmailing'>
          <ContactForm selectedOption={selectedOption} />
        </div>
          </div>
        </div>
      )}
      {selectedOption === "ru" && (
        <div className="contactContainer" data-aos="fade-up">
          <Helmet>
            <title>
              Автоматизация бизнеса, CRM-, BI-, ERP-, WMS-системы
              программирование ☎️ 091 09 09 07:
            </title>
          </Helmet>
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Контакты
          </h2>
          <div className="contInfo">
            <div className="address">
              <p className="iconContact">
                {" "}
                <CiLocationOn />
              </p>
              <h3 className="iconContactTitle">Наш адрес</h3>
              <a href="https://goo.gl/maps/5AoDq1U89opbNTnQA" className="iconContactDesc" target="_blank" rel="noopener noreferrer">ул. Сасна Црер 2/2, Ереван 0054</a>
            </div>
            <div className="emailBox">
              <p className="iconContact">
                {" "}
                <AiOutlineMail />
              </p>
              <h3 className="iconContactTitle">Электронная почта</h3>
              <a href="mailto:info@codewave.am" className="iconContactDesc" >info@codewave.am</a>
            </div>
            <div className="callUsBox">
              <p className="iconContact">
                {" "}
                <FiPhoneCall />
              </p>
              <h3 className="iconContactTitle">Позвоните нам</h3>
              <a href="tel:+37491090907" className="iconContactDesc" >+37491 09 09 07</a>
            </div>
            <div className="quartes">
              <div className="iframe-container">
                <iframe
                  src="https://maps.google.com/maps?q=yerevan CodeWave&t=&z=18&ie=UTF8&iwloc=&output=embed"
                  title="Map"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className='formEmailing'>
          <ContactForm/>
        </div>
          </div>
        </div>
      )}
      {selectedOption === "am" && (
        <div className="contactContainer" data-aos="fade-up">
          <Helmet>
            <title>
              Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում
              ☎️ 091 09 09 07
            </title>
          </Helmet>
          <h2
            className="ContServiceTitle"
            style={{ backgroundImage: `url(${back})` }}
          >
            Կոնտակտներ
          </h2>
          <div className="contInfo">
            <div className="address">
              <p className="iconContact">
                {" "}
                <CiLocationOn />
              </p>
              <h3 className="iconContactTitle">Հասցե</h3>
              <a href="https://goo.gl/maps/5AoDq1U89opbNTnQA" className="iconContactDesc" target="_blank" rel="noopener noreferrer">Երևան 0054, Սասնա Ծռեր 2/2</a>
            </div>
            <div className="emailBox">
              <p className="iconContact">
                {" "}
                <AiOutlineMail />
              </p>
              <h3 className="iconContactTitle">Էլ․ փոստ</h3>
              <a href="mailto:info@codewave.am" className="iconContactDesc" >info@codewave.am</a>
            </div>
            <div className="callUsBox">
              <p className="iconContact">
                {" "}
                <FiPhoneCall />
              </p>
              <h3 className="iconContactTitle">Հեռախոսահամար</h3>
              <a href="tel:+37491090907" className="iconContactDesc">+37491 09 09 07</a>

            </div>
            <div className="quartes">
              <div className="iframe-container">
                <iframe
                  src="https://maps.google.com/maps?q=yerevan CodeWave&t=&z=18&ie=UTF8&iwloc=&output=embed"
                  title="Map"
                  allowFullScreen="none"
                ></iframe>
              </div>
            </div>
            <div className='formEmailing'>
          <ContactForm selectedOption={selectedOption} />
        </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contacts;

import React from 'react'
import MySvG from '../../../assets/images/bitrix24/FireIcon.svg.png'
import MySvG2 from '../../../assets/images/загрузка2.svg'
import MySvG3 from '../../../assets/images/prof.svg'
import './style.scss'


const BoxProfessional = () => {
  return (
    <div className="boxPrices" style={{outline:'2px solid #1EC6FA', outlineOffset:'6px'}} >
      <div className='titleBitrixPrices'>
        <div className='titleBitrix'>
          <h1>Профессиональный</h1>
          <h6>Для максимальной автоматизации всех процессов в компании</h6>
        </div>
        <div className='info' style={{ paddingBottom: '20px' }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px, 10px',
            fontSize: '24px',
            fontWeight: '600',
            minHeight: '30px'
          }}>  <img src={MySvG} width="20px" height="30px" alt='img' />100</div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>пользователей</div>
        </div>
        <div className='price'>

          <div className='discount'> 13 990 ₽/мес. </div>
          <div className='newPrice'>  11 190 ₽/мес. <div className='discN'>-20%</div></div>



          <button style={{ background: '#21bdee' }}>КУПИТЬ</button>
        </div>
        <div className='mb'>1 024 Гб <div className='line'></div></div>
        <div className='listTarif'>
          Совместная работа  <img src={MySvG2} width="35px" height="15px" alt='img'/>
        </div>
        <div className='listTarifDesc'>Чат</div>
        <div className='listTarifDesc'>Видеозвонки HD</div>
        <div className='listTarifDesc'>Календарь</div>
        <div className='listTarifDesc'>Соцсеть компании</div>
        <div className='listTarifDesc'>Новости</div>
        <div className='listTarifDesc'>База знаний</div>
        <div className='listTarif'>
          Задачи и Проекты <img src={MySvG3} width="35px" height="15px" alt='img'/>
        </div>
        <div className='listTarif'>
          CRM <img src={MySvG3} width="35px" height="15px" alt='img'/>
        </div>
        <div className='listTarif'>
          Диск<img src={MySvG3} width="35px" height="15px" alt='img' />
        </div>
        <div className='listTarif'>
          Контакт-центр <img src={MySvG3} width="35px" height="15px" alt='img'/>
        </div>
        <div className='listTarif'>
          Сайты<img src={MySvG3} width="35px" height="15px" alt='img'/>
        </div>
        <div className='listTarif'> Интернет-магазин<img src={MySvG2} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'> Маркетинг <img src={MySvG2} width="35px" height="15px" alt='img'/></div>
        <div className='listTarif'> Документы Онлайн<img src={MySvG2} width="35px" height="15px" alt='img'/></div>
        <div className='listTarif'>  Сквозная аналитика<img src={MySvG2} width="35px" height="15px" alt='img'/></div>
        <div className='listTarif'> Автоматизация<img src={MySvG3} width="35px" height="15px" alt='img'/></div>
        <div className='listTarif'> HR<img src={MySvG3} width="35px" height="15px" alt='img' /></div>
        <div className='listTarif'  style={{top:'95px'}}> Поддержка<img src={MySvG2} width="35px" height="15px" alt='img'/></div>
        <div className='listTarif'  style={{top:'95px'}}> Администрирование<img src={MySvG2} width="35px" height="15px" alt='img'/></div>
       




     


      </div>

    </div>

  )
}

export default BoxProfessional
import React from "react";
import boxTitle from "../../../../assets/images/boxVersion/box-corp.png";
import compImg from "../../../../assets/images/boxVersion/compImg.png";
import icon1 from "../../../../assets/images/boxVersion/icon1.svg";
import icon2 from "../../../../assets/images/boxVersion/icon2.svg";
import icon3 from "../../../../assets/images/boxVersion/icon3.svg";
import icon4 from "../../../../assets/images/boxVersion/icon4.svg";
import img1 from "../../../../assets/images/boxVersion/1-vc.png";
import img2 from "../../../../assets/images/boxVersion/2-HR.png";
import img3 from "../../../../assets/images/boxVersion/3-pm.png";
import img4 from "../../../../assets/images/boxVersion/4-CRM.png";
import img5 from "../../../../assets/images/boxVersion/5-ap.png";
import img6 from "../../../../assets/images/boxVersion/6-CRMim.png";
import box01 from "../../../../assets/images/boxVersion/box_001.jpg";
import box02 from "../../../../assets/images/boxVersion/box02.jpg";
import box03 from "../../../../assets/images/boxVersion/box03.png";
import box04 from "../../../../assets/images/boxVersion/box04.jpg";
import box05 from "../../../../assets/images/boxVersion/box05.png";
import box06 from "../../../../assets/images/boxVersion/box06.jpg";
import box07 from "../../../../assets/images/boxVersion/box07.png";
import box08 from "../../../../assets/images/boxVersion/box08.png";
import box09 from "../../../../assets/images/boxVersion/box09.jpg";
import box10 from "../../../../assets/images/boxVersion/box-corp.png";

import "./style.scss";

const Bitrix24BoxVersion = ({ selectedOption }) => {
  return (
    <div>
      {selectedOption === "ru" && (
        <div className="B24Cont center">
          <div className="categoryBox blockVersion">
            <div className="categoryDesc">
              <h3 className="catTitle blockVersion rem3">Коробочная версия</h3>
              <p className="catDesc blockVersion ">
                Это корпоративный Битрикс24 на вашем сервере с вашими
                индивидуальными настройками, доработками и брендом.
              </p>
              <button className="downloadDemo">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
            <div
              className="boxTitleImg"
              style={{ backgroundImage: `url(${boxTitle})` }}
            ></div>
          </div>
          <div className="Block">
            <div className="blocktext">
              <h3 className="catTitle rem3">Расширяет возможности</h3>
              <div className="descBlock">
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon1})` }}
                  ></div>
                  <p>
                    Устанавливается на сервер компании или своего провайдера
                  </p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon2})` }}
                  ></div>
                  <p>Индивидуальные настройки бизнес-логики и интерфейса</p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon3})` }}
                  ></div>
                  <p>Больше возможностей и интеграций, чем в облачной версии</p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon4})` }}
                  ></div>
                  <p>При переходе с облачного Битрикс24 данные сохраняются</p>
                </div>
              </div>
            </div>

            <div
              className="compImg "
              style={{ backgroundImage: `url(${compImg})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Внутренние коммуникации</h3>
              <p className="catDesc">
                1C-Битрикс24 (коробочная версия Битрикс24) — это цифровое
                рабочее пространство для вашей компании. Ставьте и контролируйте
                задачи, отправляйте файлы и голосовые сообщения, общайтесь в
                чате, обсуждайте планы в профильных группах и принимайте
                взвешенные решения.
                <br />
                <br />
                <br />
                Будьте всегда на связи и в курсе событий: используйте для работы
                десктопное и мобильное приложения.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">HR-менеджмент</h3>
              <p className="catDesc">
                1C-Битрикс24 помогает бизнесу организовать лёгкий вход нового
                сотрудника в коллектив, быстрое знакомство со структурой
                компании, оповещение всей команды о появлении нового коллеги.
                <br />
                <br />
                <br />
                1C-Битрикс24 просит нового человека сразу заполнить Профиль,
                разместить фотографию, рассказать о себе. Новый сотрудник
                вступает в Группы по роду его занятий и по своим интересам,
                находит единомышленников.{" "}
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Проектный менеджмент</h3>
              <p className="catDesc">
                Координируйте усилия команды с помощью проектных инструментов
                1C-Битрикс24. Определяйте роли и права доступа каждого.
                Планируйте этапы, храните все документы на Диске, ведите
                календарь, общайтесь в чате Проекта.
                <br />
                <br />
                <br />
                Проекты, созданные в 1C-Битрикс24, обязательно будут завершены в
                срок и с лучшим результатом.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                CRM и управление клиентской базой
              </h3>
              <p className="catDesc">
                В CRM вы ведёте своих клиентов по всем этапам жизненного цикла,
                изучаете их поведение, создаёте сегменты по заданным критериям,
                вовремя даёте всю необходимую для них информацию.
                <br />
                <br />
                <br />
                Звоните, пишите клиенту в один клик прямо из карточки CRM,
                угадывайте его желания и делайте выгодные предложения.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Автоматизация продаж</h3>
              <p className="catDesc">
                Автоматизируйте продажи с помощью роботов и бизнес-процессов.
                Делегируйте важную, трудоёмкую и рутинную работу с клиентом
                1C-Битрикс24.
                <br />
                <br />У ваших менеджеров автоматически появятся новые задачи и
                вовремя придут напоминания. А клиенты получат все сообщения о
                статусе их заказа, дадут обратную связь.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                CRM-инструменты маркетолога
              </h3>
              <p className="catDesc">
                CRM-маркетинг даёт возможность рассказывать разным клиентам об
                интересных предложениях, акциях к праздникам и сезонных
                распродажах по-разному.
                <br />
                <br />В распоряжении маркетологов конструктор рекламных
                email-рассылок, генератор повторных продаж, массовых
                sms-оповещений и автоматических звонков на телефоны клиентов,
                инструментарий для создания лендингов.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Настройка сервиса — под вашим контролем
              </h3>
              <p className="catDesc">
                Вы самостоятельно размещаете 1C-Битрикс24 на серверах компании
                или в датацентре и следите за скоростью и надежностью его
                работы. Для вашего удобства компания 1С-Битрикс предоставляет
                готовые образы веб-окружения для наиболее популярных сред
                виртуализации.
                <br />
                <br />
                При переходе с облачной версии в «коробку» все данные
                сохраняются.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box01})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box02})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Открытый исходный код</h3>
              <p className="catDesc">
                Своими силами или с привлечением интеграторов можно дорабатывать
                интерфейс и функциональность 1С-Битрикс24 под индивидуальные
                потребности и бизнес-логику вашей компании без ограничений.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Веб-кластер</h3>
              <p className="catDesc">
                Коробочная версия болеее подходит для крупных организаций,
                проектов с большим объемом данных и большим количеством
                пользователей за счет возможности использования модуля
                «Веб-кластер». Это комбинация технологических решений, которые
                позволяют распределить один портал на несколько серверов, решая
                тем самым несколько задач: обеспечение высокой доступности
                портала, его масштабирование в условиях возрастающей нагрузки,
                балансирование нагрузки, трафика, данных между несколькими
                серверами.
                <br />
                <br />
                Веб-кластер, наряду с другими возможностями 1С-Битрикс24 для
                крупных заказчиков, доступен в редакции Энтерпрайз.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box03})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box04})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Виртуальная машина</h3>
              <p className="catDesc">
                Используйте бесплатный и настроенный для оптимальной работы с
                продуктами 1С-Битрикс наш виртуальный сервер. Продукт включает в
                себя операционную систему, веб-сервер, базу данных, firewall,
                почтовый сервер, а также большое число настроек, от которых
                зависит надежность, производительность и безопасность
                веб-проекта.
                <br />
                <br />
                На Виртуальной машине возможно запустить как новый, так и
                перенести на нее уже готовый проект.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Кастомизация</h3>
              <p className="catDesc">
                Битрикс24 позаботился о том, чтобы пользователь имел возможность
                менять дизайн интерфейса согласно общего стиля своей компании
                или предписаниям разработанного брендбука без каких-либо
                ограничений.
                <br />
                <br />
                Выгода такой кастомизации — вы покупаете обычный типовой
                1C-Битрикс24 и сами создаёте имидж вашей компании, делая
                1C-Битрикс24 уже совершенно индивидуальным.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box05})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box06})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Когда офисы в разных городах
              </h3>
              <p className="catDesc">
                Если у вас есть филиалы, которым необходимо иметь свой
                внутренний сервис и при этом координировать работу со всей
                компанией, используйте многодепартаментность
                <br />
                <br />
                Многодепартаментность, наряду с другими возможностями
                1С-Битрикс24 для крупных заказчиков доступна в редакции
                Энтерпрайз.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Маркетплейс</h3>
              <p className="catDesc">
                Наши партнёры разработали более 2 000 готовых решений на
                платформе 1С-Битрикс: от небольших компонентов и модулей до
                готовых интернет-магазинов и информационных систем.
                <br />
                <br />
                Каталог Маркетплейс предназначен только для коробочной версии
                Битрикс24 и предлагает готовые решения как для бизнеса, так и
                для государственных и бюджетных организаций.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box07})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box08})` }}
            ></div>
            <div className="categoryDesc">
              <p className="catDesc">
                На сегодняшний момент мы предлагаем три редакции 1С-Битрикс24:
                <br />
                <b>Интернет-магазин + CRM </b>— все необходимые инструменты для
                организации работы отдела до 12 человек и автоматизации продаж;
                <br />
                <b>Корпоративный портал</b> — это корпоративный Битрикс24 на
                вашем сервере с индивидуальными настройками; <br />
                <b>Энтерпрайз </b>— редакция для крупных компаний от 500
                сотрудников, с многодепартаментностью, возможностями
                масштабирования и отказоуйстойчивости, с максимальным уровнем
                поддержки от 1С-Битрикс.
              </p>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Рекомендуем попробовать</h3>
              <p className="catDesc">
                Прежде, чем покупать «коробку» Битрикс24, протестируйте её
                возможности в своей компании. После 30 дней испытания вы сможете
                купить лицензию и продолжить работу.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box09})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box10})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Коробочная версия Битрикс24
              </h3>
              <ul className="integrationList">
                <li>Размещение на вашем сервере</li>
                <li> Дополнительный функционал</li>
                <li>Возможность доработки</li>
              </ul>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  СКАЧАТЬ ДЕМО
                </a>
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "en" && (
        <div className="B24Cont center">
          <div className="categoryBox blockVersion">
            <div className="categoryDesc">
              <h3 className="catTitle blockVersion rem3">On-premise version</h3>
              <p className="catDesc blockVersion ">
                This is a corporate Bitrix24 on your server with your individual
                settings, improvements and brand.{" "}
              </p>
              <button className="downloadDemo">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
            <div
              className="boxTitleImg"
              style={{ backgroundImage: `url(${boxTitle})` }}
            ></div>
          </div>
          <div className="Block">
            <div className="blocktext">
              <h3 className="catTitle rem3">Enhances capabilities</h3>
              <div className="descBlock">
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon1})` }}
                  ></div>
                  <p>Installed on a company or provider's server </p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon2})` }}
                  ></div>
                  <p>Customized business logic and interface settings</p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon3})` }}
                  ></div>
                  <p>More features and integrations than the cloud version</p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon4})` }}
                  ></div>
                  <p>Data is saved when migrating from the cloud Bitrix24</p>
                </div>
              </div>
            </div>

            <div
              className="compImg "
              style={{ backgroundImage: `url(${compImg})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Internal communications</h3>
              <p className="catDesc">
                1C-Bitrix24 (Bitrix24 on-premise version) is a digital workspace
                for your company. Set and control tasks, send files and voice
                messages, chat, discuss plans in profile groups, and make
                informed decisions.
                <br />
                <br />
                <br />
                Stay connected and up-to-date: use the desktop and mobile apps
                to work.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">HR management</h3>
              <p className="catDesc">
                1C-Bitrix24 simplifies new employees' onboarding process, helps
                them quickly get acquainted with the company structure, and
                notifies the entire team of the arrival of a new colleague.
                <br />
                <br />
                <br />
                1C-Bitrix24 asks a new person to fill out a profile, post a
                photo, and tell about themselves. The new employee joins groups
                based on occupation and interests and finds like-minded people.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Project Management</h3>
              <p className="catDesc">
                Coordinate team efforts using 1C-Bitrix24 project tools. Define
                everyone's roles and access rights. Plan milestones, store all
                documents on Disk, keep a calendar, communicate in Project chat.
                <br />
                <br />
                <br />
                Projects created in 1C-Bitrix24 are sure to be completed on time
                and with the best result.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                CRM and client database management
              </h3>
              <p className="catDesc">
                In CRM you guide your customers through all the life cycle
                stages, study their behavior, create segments according to
                predefined criteria and give them all the information they need
                in a timely manner. <br />
                <br />
                <br />
                Call and email your customers in a click, right from the CRM
                card, guess their desires and make them profitable offers.{" "}
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Sales automation</h3>
              <p className="catDesc">
                Automate sales with robots and business processes. Delegate
                important, time-consuming and routine customer work to
                1C-Bitrix24.
                <br />
                <br />
                Your managers will automatically have new tasks and reminders on
                time. And customers will receive all messages about the status
                of their order, giving feedback.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                CRM tools for the marketer
              </h3>
              <p className="catDesc">
                CRM-marketing makes it possible to tell different customers
                about interesting offers, promotions for holidays and seasonal
                sales in different ways.
                <br />
                <br />
                Marketers have access to an email message builder, a recurring
                sales generator, mass SMS notifications, and automatic customer
                phone call systems, as well as tools for designing landing
                pages.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Setting up the service is under your control.
              </h3>
              <p className="catDesc">
                You deploy 1C-Bitrix24 on your own servers or in a data center
                and monitor the speed and reliability of its operation. For your
                convenience, 1C-Bitrix offers ready-to-use images of the web
                environment for the most popular virtualization environments.{" "}
                <br />
                <br />
                All data is preserved when you migrate from the cloud version to
                the on-premise.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box01})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box02})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">ОOpen source code</h3>
              <p className="catDesc">
                You can modify the interface and functionality of 1C-Bitrix24
                internally or through integrators to meet your company's
                individual needs and business logic without limitation.{" "}
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Web Cluster</h3>
              <p className="catDesc">
                The on -premise version is more suitable for large
                organizations, projects with large volumes of data and a large
                number of users due to the possibility to use the Web Cluster
                module. This is a combination of technological solutions that
                allows you to distribute one portal on multiple servers, thereby
                solving several problems: ensuring high availability of the
                portal, its scaling in conditions of increasing load, load
                balancing, traffic, and data between multiple servers
                <br />
                <br />
                Web cluster, along with other features of 1C-Bitrix24 for large
                clients, is available in the Enterprise edition.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box03})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box04})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Virtual machine</h3>
              <p className="catDesc">
                Use our virtual server which is free of charge and configured to
                work optimally with 1C-Bitrix products. The product includes an
                operating system, web server, database, firewall, mail server,
                and a large number of settings that affect the reliability,
                performance, and security of your web project.
                <br />
                <br />
                You can run a new Virtual Machine as well as transfer an
                existing project to it.{" "}
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Customization</h3>
              <p className="catDesc">
                Bitrix24 allows its users to customize the interface design
                according to the general style of their company or to the
                prescriptions of a developed brandbook without any restrictions.
                <br />
                <br />
                The advantage of such customization is that you can buy a
                standard 1C-Bitrix24 interface and make your own company image,
                making 1C-Bitrix24 completely individual.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box05})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box06})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                When offices are in different cities
              </h3>
              <p className="catDesc">
                If you have branches that need to have their own internal
                service and at the same time coordinate with the entire company,
                use multi-departmentality
                <br />
                <br />
                Multi-departmentality, along with other features of 1C-Bitrix24
                for large customers is available in the Enterprise edition.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Marketplace</h3>
              <p className="catDesc">
                Our partners have developed over 2 000 ready-to-use solutions on
                the 1C-Bitrix platform: from small components and modules to
                complete online stores and information systems.
                <br />
                <br />
                The Marketplace catalog is designed only for the box version of
                Bitrix24 and offers ready solutions for businesses as well as
                government and state organizations.
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box07})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box08})` }}
            ></div>
            <div className="categoryDesc">
              <p className="catDesc">
                To date we offer three editions of 1C-Bitrix24:
                <br />
                <b>Online store + CRM </b> - a corporate Bitrix24 on your server
                with individual settings
                <br />
                <b>Corporate Portal</b> — это корпоративный Битрикс24 на вашем
                сервере с индивидуальными настройками; <br />
                <b>Enterprise </b> - edition for large companies of 500
                employees, with multi-department, scalability and fail-safety
                capabilities, with the highest level of support from 1C-Bitrix.
              </p>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">We recommend to try it</h3>
              <p className="catDesc">
                Before you buy an on-premise Bitrix24, test its capabilities in
                your company. After 30 days of testing you will be able to buy a
                license and continue to work.
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box09})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box10})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Bitrix24 on-premise version
              </h3>
              <ul className="integrationList">
                <li>Deployment on your server</li>
                <li> Additional functionality</li>
                <li>Options for customization</li>
              </ul>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                  DOWNLOAD DEMO{" "}
                </a>
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "am" && (
        <div className="B24Cont center">
          <div className="categoryBox blockVersion">
            <div className="categoryDesc">
              <h3 className="catTitle blockVersion rem3">Տուփային տարբերակ</h3>
              <p className="catDesc blockVersion ">
                Սա կորպորատիվ Bitrix24 է ձեր սերվերի վրա, ձեր անհատական
                ​​կարգավորումներով, բարելավումներով և բրենդով:
              </p>
              <button className="downloadDemo">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
            <div
              className="boxTitleImg"
              style={{ backgroundImage: `url(${boxTitle})` }}
            ></div>
          </div>
          <div className="Block">
            <div className="blocktext">
              <h3 className="catTitle rem3">
                Ընդլայնում է հնարավորությունները
              </h3>
              <div className="descBlock">
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon1})` }}
                  ></div>
                  <p>Տեղադրված է ընկերության կամ նրա մատակարարի սերվերի վրա </p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon2})` }}
                  ></div>
                  <p>
                    Բիզնեսի տրամաբանության և ինտերֆեյսի անհատական
                    ​​կարգավորումներ
                  </p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon3})` }}
                  ></div>
                  <p>
                    Ավելի շատ հնարավորություններ և ինտեգրումներ, քան ամպային
                    տարբերակում
                  </p>
                </div>
                <div className="block50">
                  <div
                    className="BlockIcon"
                    style={{ backgroundImage: `url(${icon4})` }}
                  ></div>
                  <p>
                    Ամպամային Bitrix24-ից տուփայինի անցնելիս տվյալները
                    պահպանվում են
                  </p>
                </div>
              </div>
            </div>

            <div
              className="compImg "
              style={{ backgroundImage: `url(${compImg})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Ներքին հաղորդակցություններ
              </h3>
              <p className="catDesc">
                1C-Bitrix24 (Bitrix24 տուփային տարբերակ) թվային աշխատանքային
                միջավայր է ձեր ընկերության համար: Սահմանեք առաջադրանքներ և
                վերահսկեք , ուղարկեք ֆայլեր և ձայնային հաղորդագրություններ,
                զրուցեք, քննարկումներ ծավալեք պրոֆիլային խմբերում և կայացրեք
                կշռադատված որոշումներ:
                <br />
                <br />
                <br />
                Միշտ եղեք հասանելի և տեղեկացված. օգտագործեք ձեր աշխատանքում
                համակարգի desktop և mobile տարբերակները:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">HR կառավարում</h3>
              <p className="catDesc">
                1C-Bitrix24-ն օգնում է բիզնեսին հեշացնել նոր աշխատակիցների
                ինտեգրման պրոցեսը, օգնում է արագ ծանոթանալ ընկերության
                կառուցվածքին և ծանուցել ամբողջ թիմին նոր գործընկերոջ առկայության
                մասին:
                <br />
                <br />
                <br />
                1C-Bitrix24-ը նոր օգտատրիոջը խնդրում է անմիջապես լրացնել
                պրոֆիլը, տեղադրել լուսանկար, պատմել իր մասին: Նոր աշխատակիցը
                միանում է Խմբերին՝ ըստ իր նախասիրությունների և
                հետաքրքրությունների, գտնում է համախոհներ։
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Ծրագրերի կառավարում</h3>
              <p className="catDesc">
                կառավարման գործիքների օգնությամբ: Սահմանեք դերեր և մուտքի
                իրավունքներ բոլորի համար: Պլանավորեք կարևոր իրադարձությունները,
                պահպանեք բոլոր փաստաթղթերը սկավառակի վրա, վարեք ձեր օրացույցը,
                շփվեք Ծրագրի չաթում:
                <br />
                <br />
                <br />
                1C-Bitrix24-ում ստեղծված նախագծերը վստահորեն կավարտվեն ժամանակին
                և լավագույն արդյունքով:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img4})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                CRM և հաճախորդների բազայի կառավարում
              </h3>
              <p className="catDesc">
                CRM-ում դուք առաջնորդում եք ձեր հաճախորդներին վաճառքի ցիկլի
                բոլոր փուլերում, ուսումնասիրում նրանց վարքագիծը, ստեղծում
                սեգմենտներ ըստ սահմանված չափանիշների և ժամանակին տրամադրում
                նրանց անհրաժեշտ բոլոր տեղեկությունները:
                <br />
                <br />
                <br />
                Զանգահարեք, գրեք հաճախորդին մեկ սեղմումով անմիջապես CRM քարտից,
                կանխատեսեք նրանց ցանկությունները և կատարեք շահավետ առաջարկներ։
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Վաճառքի ավտոմատացում</h3>
              <p className="catDesc">
                Ավտոմատացրեք վաճառքը ռոբոտների և բիզնես գործընթացների միջոցով:
                Փոխանցեք կարևոր, ժամանակատար և կրկնվող աշխատանքը հաճախորդի հետ
                1C-Bitrix24-ին:
                <br />
                <br />
                Ձեր ղեկավարներն ավտոմատ կերպով կստանան նոր առաջադրանքներ և
                հիշեցումներ: Իսկ հաճախորդները կստանան ամբողջ անհրաժեշտ
                տեղեկատվությունը իրենց պատվերի կարգավիճակի մասին և հետադարձ կապ
                կտան:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img5})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${img6})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Մարքեթինգային CRM գործիքներ
              </h3>
              <p className="catDesc">
                CRM մարքեթինգը հնարավորություն է տալիս տեղեկացնել հաճախորդներին
                հետաքրքիր առաջարկների, տոնական ակցիաների և սեզոնային վաճառքների
                մասին:
                <br />
                <br />
                Մարքեթոլոգների տրամադրության տակ գովազդային email builder-ներն
                են, կրկնվող վաճառքի գեներատորը, զանգվածային SMS ծանուցուման և
                ավտոմատ զանգերի և landing էջեր ստեղծելու գործիքները:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>

          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Համակարգի կարգավորումը ձեր վերահսկողության տակ է
              </h3>
              <p className="catDesc">
                Դուք սեփական ուժերով կարող եք տեղադրել 1C-Bitrix24-ը ընկերության
                սերվերների վրա կամ տվյալների կենտրոնում և վերահսկել դրա
                արագագործությունը և հուսալիությունը: Ձեր հարմարության համար
                1C-Bitrix-ը տրամադրում է պատրաստի վեբ լուծումներ ամենահայտնի
                վիրտուալացման միջավայրերի համար:
                <br />
                <br />
                Ամպային տարբերակից տուփայինին անցնելիս բոլոր տվյալները
                պահպանվում են։
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box01})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box02})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Բաց կոդ</h3>
              <p className="catDesc">
                Ինքնուրույն կամ ինտեգրատորների ներգրավմամբ դուք կարող եք փոփոխել
                1C-Bitrix24-ի ինտերֆեյսը և ֆունկցիոնալությունը՝ առանց
                սահմանափակումների համապատասխանելու ձեր ընկերության անհատական
                ​​կարիքներին և բիզնես տրամաբանությանը:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Վեբ կլաստեր</h3>
              <p className="catDesc">
                Տուփային տարբերակը ավելի հարմար է խոշոր կազմակերպությունների,
                մեծ քանակությամբ տվյալների և մեծ թվով օգտատերերի նախագծերի
                համար՝ շնորհիվ Web Cluster մոդուլի օգտագործման հնարավորության։
                Սա տեխնոլոգիական լուծումների համակցություն է, որը թույլ է տալիս
                մեկ պորտալը տեղադրել մի քանի սերվերների վրա՝ դրանով իսկ լուծելով
                մի քանի խնդիր՝պորտալի բարձր հասանելիություն և ընդլայնման
                ապահովում՝ աճող ծանրաբեռնվածության պարագայում, traffic - ի,
                տվյալների հավասարակշռում մի քանի սերվերների միջև:
                <br />
                <br />
                Վեբ կլաստերը, խոշոր հաճախորդների համար նախատեսված 1C-Bitrix24
                այլ հնարավորությունների հետ միասին, հասանելի է Enterprise
                տարբերակում:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box03})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box04})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Վիրտուալ մեքենա</h3>
              <p className="catDesc">
                Օգտագործեք մեր անվճար վիրտուալ սերվերը, որը կազմաձևված է
                1C-Bitrix արտադրանքների հետ օպտիմալ աշխատանքի համար: Ապրանքը
                ներառում է օպերացիոն համակարգ, վեբ սերվեր, տվյալների բազա,
                firewall, փոստային սերվեր, ինչպես նաև մեծ թվով կարգավորումներ,
                որոնք ազդում են վեբ նախագծի հուսալիության, կատարողականի և
                անվտանգության վրա:
                <br />
                <br />
                Վիրտուալ մեքենայի վրա հնարավոր է՝ ինչպես գործարկել նոր նախագիծ,
                այնպես էլ տեղափոխել արդեն պատրաստի նախագիծը:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ{" "}
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Անհատականացում</h3>
              <p className="catDesc">
                Bitrix24-ը ստեղծել է հնարավորություն, որպեսզի օգտատերը
                հնարավորություն ունենա առանց սահմանափակումների փոխել ինտերֆեյսի
                դիզայնը՝ իր ընկերության ընդհանուր ոճին կամ մշակված beand book-ին
                համապատասխան։
                <br />
                <br />
                Նման հարմարեցման առավելությունն այն է, որ դուք գնում եք
                սովորական 1C-Bitrix24 և ինքներդ եք ստեղծում ձեր ընկերության
                իմիջը՝ դարձնելով 1C-Bitrix24-ը լիովին անհատական:
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box05})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box06})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Երբ գրասենյակները տարբեր քաղաքներում են
              </h3>
              <p className="catDesc">
                Եթե ​​ունեք մասնաճյուղեր, որոնք պետք է ունենան իրենց ներքին
                համակարգը և միևնույն ժամանակ համակարգեն աշխատանքը ամբողջ
                ընկերության հետ, օգտագործեք multi-departmentality-ի
                հնարավորությունը
                <br />
                <br />
                Multi-departmentality-ն՝ խոշոր հաճախորդների համար 1C-Bitrix24-ի
                այլ հնարավորությունների հետ մեկտեղ, հասանելի է Enterprise
                տարբերակում:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Marketplace</h3>
              <p className="catDesc">
                Մեր գործընկերները 1C-Bitrix հարթակում մշակել են ավելի քան 2000
                պատրաստի լուծումներ՝ փոքր բաղադրիչներից և մոդուլներից մինչև
                պատրաստի առցանց խանութներ և տեղեկատվական համակարգեր:
                <br />
                <br />
                Marketplace կատալոգը նախատեսված է միայն Bitrix24-ի տուփային
                տարբերակի համար և առաջարկում է պատրաստի լուծումներ ինչպես
                բիզնեսի, այնպես էլ պետական ​​և բյուջետային կազմակերպությունների
                համար։
              </p>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box07})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box08})` }}
            ></div>
            <div className="categoryDesc">
              <p className="catDesc">
                Այս պահին մենք առաջարկում ենք 1C-Bitrix24-ի երեք
                հրատարակություն. <br />
                <b>Առցանց խանութ + CRM</b> - բոլոր անհրաժեշտ գործիքները մինչև 12
                հոգանոց բաժնի աշխատանքը կազմակերպելու և վաճառքի ավտոմատացման
                համար; <br />
                <b>Կորպորատիվ պորտալ </b> - կորպորատիվ Bitrix24 ձեր սերվերի վրա՝
                անհատական ​​կարգավորումներով.
                <br />
                <b>Enterprise </b> - թողարկում ավելի քան 500 աշխատակից ունեցող
                խոշոր ընկերությունների համար, բազմաթիվ բաժիններով,
                մասշտաբայնության հնարավորությամբ և 1C-Bitrix-ի աջակցության
                առավելագույն մակարդակով:
              </p>
            </div>
          </div>
          <div className="categoryBox">
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">Խորհուրդ է տրվում փորձել</h3>
              <p className="catDesc">
                Bitrix24-ի տուփային տարբերակ գնելուց առաջ փորձարկեք դրա
                հնարավորությունները ձեր ընկերությունում։ 30 օր փորձարկումից հետո
                դուք կկարողանաք լիցենզիա գնել և շարունակել աշխատել:
              </p>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box09})` }}
            ></div>
          </div>
          <div className="categoryBox">
            <div
              className="categoryImg cover"
              style={{ backgroundImage: `url(${box10})` }}
            ></div>
            <div className="categoryDesc">
              <h3 className="catTitle rem3 fw100">
                Bitrix24-ի տուփային տարբերակը
              </h3>
              <ul className="integrationList">
                <li>Հոսթինգ ձեր սերվերի վրա</li>
                <li> Լրացուցիչ ֆունկցիոնալություն</li>
                <li>Բարելավման հնարավորություն</li>
              </ul>
              <button className="downloadDemo blue">
                <a href="https://www.bitrix24.ru/prices/try_biz.php">
                ՆԵՐԲԵՌՆԵԼ ԴԵՄՈ
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bitrix24BoxVersion;

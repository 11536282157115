import React from "react";
import "./style.scss";
import {  NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";

const ServiceBox = ({
  title,
  description,
  image,
  handleShowMore,
  selectedOption,
  infoMore,
  path,
  duration
}) => {
  const showMore = () => {
    handleShowMore();
  };

  return (
    <div className="service-box"
   data-aos="fade-up"
     data-aos-duration={duration}>
        <Helmet>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      <img src={image} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>
      
      <div className="infoMoreAll">
        <p className="infoP">{infoMore}</p>
        {handleShowMore && (
          <NavLink to={path} onClick={showMore}>
            {selectedOption === "en" && "more"}
            {selectedOption === "ru" && "более"}
            {selectedOption === "am" && "ավելին"}
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ServiceBox;

import "./App.scss";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import Home from "./Components/home";
import Services from "./Components/services";
import About from "./Components/about";
import Team from "./Components/team";
import Contacts from "./Components/contacts";
import Footer from "./Components/footer";
import Hamburger from "hamburger-react";
import { Fade } from "react-awesome-reveal";
import img1 from "./assets/images/flags/flagUSA.png";
import img2 from "./assets/images/flags/FlagRussia.png";
import img3 from "./assets/images/flags/FlagArmenia.png";
import logo from "./assets/images/logoOnly.png";
import Aos from "aos";
import "aos/dist/aos.css";
import ERPContainer from "./Components/services/ERP";
import BitrixContainer from "./Components/services/bitrix24";
import BIContainer from "./Components/services/BI";
import WMSContainer from "./Components/services/wms";
import WEBContainer from "./Components/services/web";
import { Helmet } from "react-helmet";
import PageNotFound from "./Components/pageNotFound";
// import loading from "./assets/images/loadingIMG.png";
function App() {
  const refHome = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [selectedOption, setSelectedOption] = useState("am");
  const [isVisible, setIsVisible] = useState(false);
  const urlWithSelectedOption = `https://www.codewave.am/${selectedOption}`;

  const handleLanguageChange = (event) => {
    setSelectedOption(event.target.value);
    setShowLanguage(!showLanguage);
    setShowMenu(false);
  };
  const handleCloseMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleShowLanguages = () => {
    setShowLanguage(!showLanguage);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      handleClick();
    };
  }, [selectedOption]);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const languageOptions = [
    {
      id: 1,
      text: "English",
      flag: img1,
      value: "en",
    },
    {
      id: 2,
      text: "Русский",
      flag: img2,
      value: "ru",
    },
    {
      id: 3,
      text: "Հայերեն",
      flag: img3,
      value: "am",
    },
  ];
  useEffect(() => {
    Aos.init({
      duration: 1500,
      once: true,
    });
  }, []);
  useEffect(() => {
    const storedTitle = localStorage.getItem('pageTitle');
    if (storedTitle) {
      document.title = storedTitle;
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('pageTitle', 'Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում ☎️ 091 09 09 07');
  }, []);
  return (
    <div className="App">
      
        <>
          <Helmet>
            <title>
              Բիզնեսի ավտոմատացում, CRM-,BI-, ERP-,WMS-համակարգերի ծրագրավորում
              ☎️ 091 09 09 07
            </title>
            <meta property="og:url" content={urlWithSelectedOption} />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <div
            className="headerComponent"
            data-aos="fade-down"
            data-aos-duration="1300"
          >
            <header
              className={showMenu ? "header openmenuheader" : "header "}
              style={showMenu ? { height: "100vh" } : { height: "70px" }}
            >
              <div className="logo" ref={refHome}>
                <NavLink to={`/`} onClick={handleClick}>
                  <img src={logo} alt="logo" className="headerLogoImg" />
                </NavLink>
                <div className="navMobile">
                  <Hamburger toggled={showMenu} toggle={setShowMenu} />
                </div>
              </div>
              <nav className={showMenu ? "show" : "none"}>
                <div className="big">
                  <ul>
                    <li>
                      <NavLink
                        to={`/`}
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Home"}
                        {selectedOption === "ru" && "Главная"}
                        {selectedOption === "am" && "Գլխավոր"}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/about`}
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "About Us"}{" "}
                        {selectedOption === "ru" && "О нас"}{" "}
                        {selectedOption === "am" && "Մեր Մասին"}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/services`}
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Services"}{" "}
                        {selectedOption === "ru" && "Услуги"}{" "}
                        {selectedOption === "am" && "Ծառայություններ"}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/team`}
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Team"}{" "}
                        {selectedOption === "ru" && "Команда"}{" "}
                        {selectedOption === "am" && "Մեր Թիմը"}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/contacts`}
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Contacts"}{" "}
                        {selectedOption === "ru" && "Контакты"}{" "}
                        {selectedOption === "am" && "Կոնտակտներ"}
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="navMobile">
                  <ul>
                    <li onClick={handleCloseMenu}>
                      <NavLink to="/" className="white" onClick={handleClick}>
                        {selectedOption === "en" && "Home"}{" "}
                        {selectedOption === "ru" && "Главная"}{" "}
                        {selectedOption === "am" && "Գլխավոր"}
                      </NavLink>
                    </li>
                    <li onClick={handleCloseMenu}>
                      <NavLink
                        to={`/about`}
                        className="white"
                        activeclassname="active"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "About Us"}{" "}
                        {selectedOption === "ru" && "О нас"}{" "}
                        {selectedOption === "am" && "Մեր Մասին"}
                      </NavLink>
                    </li>
                    <li onClick={handleCloseMenu}>
                      <NavLink
                        to={`/services`}
                        className="white"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Services"}{" "}
                        {selectedOption === "ru" && "Услуги"}{" "}
                        {selectedOption === "am" && "Ծառայություններ"}
                      </NavLink>
                    </li>
                    <li onClick={handleCloseMenu}>
                      <NavLink
                        to={`/team`}
                        className="white"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Team"}{" "}
                        {selectedOption === "ru" && "Команда"}{" "}
                        {selectedOption === "am" && "Մեր Թիմը"}
                      </NavLink>
                    </li>
                    <li onClick={handleCloseMenu}>
                      <NavLink
                        to={`/contacts`}
                        className="white"
                        onClick={handleClick}
                      >
                        {selectedOption === "en" && "Contacts"}{" "}
                        {selectedOption === "ru" && "Контакты"}{" "}
                        {selectedOption === "am" && "Կոնտակտներ"}
                      </NavLink>
                    </li>
                  </ul>
                </div>

                {}

                <div
                  className={showMenu ? "languagesBox" : "  languagesBoxNone"}
                >
                  {showLanguage ? (
                    <div className="languagesBoxSelected">
                      <Fade direction="right" duration={1000}>
                        <button
                          className="languagesBack"
                          onClick={handleLanguageChange}
                          value={languageOptions[0].value}
                          style={{
                            backgroundImage: `url(${img1})`,
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                            margin: "5px",
                            padding: "0",
                            width: "25px",
                            height: " 25px",
                          }}
                        ></button>
                      </Fade>
                      <Fade direction="right" duration={1500}>
                        <button
                          className={"languagesBack"}
                          onClick={handleLanguageChange}
                          value={languageOptions[1].value}
                          style={{
                            backgroundImage: `url(${img2})`,
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                            margin: "5px",
                            padding: "0",
                            width: "25px",
                            height: " 25px",
                          }}
                        ></button>
                      </Fade>
                      <Fade direction="right" duration={2000}>
                        <button
                          className={"languagesBack"}
                          onClick={handleLanguageChange}
                          value={languageOptions[2].value}
                          style={{
                            backgroundImage: `url(${img3})`,
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                            margin: "5px",
                            padding: "0",
                            width: "25px",
                            height: " 25px",
                          }}
                        ></button>
                      </Fade>
                    </div>
                  ) : (
                    <button
                      className={"languagesBack"}
                      onClick={handleShowLanguages}
                      onChange={handleClick}
                      value={languageOptions.value}
                      style={{
                        backgroundImage:
                          selectedOption === "en"
                            ? `url(${languageOptions[0].flag})`
                            : selectedOption === "ru"
                            ? `url(${languageOptions[1].flag})`
                            : selectedOption === "am" &&
                              `url(${languageOptions[2].flag})`,
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                        margin: "5px",
                        padding: "0",
                        width: "25px",
                        height: " 25px",
                      }}
                    ></button>
                  )}
                </div>
              </nav>
            </header>
          </div>

          <div className={`scroll-top-button ${isVisible ? "show" : "hide"}`}>
            <FaArrowCircleUp onClick={handleClick} />
          </div>

          <Routes>
            <Route
              path="/"
              element={<Home selectedOption={selectedOption} />}
            />
            <Route
              path="/about"
              element={<About selectedOption={selectedOption} />}
            />
            <Route
              path="/services"
              element={<Services selectedOption={selectedOption} />}
            />
            <Route
              path="/services/erp"
              element={<ERPContainer selectedOption={selectedOption} />}
            />
            <Route
              path="/services/bitrix24"
              element={<BitrixContainer selectedOption={selectedOption} />}
            />
            <Route
              path="/services/web"
              element={<WEBContainer selectedOption={selectedOption} />}
            />
            <Route
              path="/services/bi"
              element={<BIContainer selectedOption={selectedOption} />}
            />
            <Route
              path="/services/wms"
              element={<WMSContainer selectedOption={selectedOption} />}
            />
            <Route
              path="/team"
              element={<Team selectedOption={selectedOption} />}
            />
            <Route
              path="/contacts"
              element={<Contacts selectedOption={selectedOption} />}
            />
            {/* <Route path="*" element={<div className="errorPage"> <video loop autoPlay muted className="videoHome">
          <source src={video} type="video/mp4" />
        </video>
        <h1 className="homeTitle">Error Page</h1></div>} /> */}

            <Route path="/404" element={<PageNotFound  selectedOption={selectedOption}/>} />
          	<Route path="*" element={<Navigate to="/404"  selectedOption={selectedOption}/>} />
          </Routes>

          <Footer
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="5000"
            handleScrollHome={handleClick}
            selectedOption={selectedOption}
            handleClick={handleClick}
          />
        </>
    
    </div>
  );
}
export default App;
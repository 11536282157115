import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Bitrix24Container from "./bitrix24Cont";
import {Helmet} from "react-helmet";

const BitrixContainer = ({ selectedOption, handleClose }) => {
  const refModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTopModal =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTopModal > 0);
  };

  const scrollToTopModal = () => {
    refModal.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTopModal();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="biCont" ref={refModal}>
      <div className="top70"></div>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Bitrix24Container
        selectedOption={selectedOption}
        handleClose={handleClose}
      />
    </div>
  );
};

export default BitrixContainer;
